import { useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, Grid, Stack, Switch } from "@mui/material";
import Exclamation from "../assets/icons/Red_Exclaimation_mark.svg";
import classes from "./FinishedReports/FinishedReports.module.css";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import VectorIcon from "../assets/icons/Vector.svg";
import { FormProvider, useForm } from "react-hook-form";
import BackArrowIcon from "./../assets/icons/back_arrow-icon.svg";
import CloseIcon from "../assets/icons/close.svg";
import UserCreatedIcon from "../assets/icons/User_Created.svg";
import CustomAlertTest from "../common/components/CustomComponents/CustomAlert";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import * as Yup from "yup";
import CustomSelect from "../common/components/CustomComponents/CustomSelect";
import ConfirmDelete from "../common/components/CustomComponents/ConfirmDelete";
import { useRequest } from "../services/agent";
import { RequestApi } from "../common/Constant";
import { yupResolver } from "@hookform/resolvers/yup";
import { getValue } from "@testing-library/user-event/dist/utils";
import { Role } from "../common/models/roles";
import { Country } from "../services/countries";
import { useAppDispatch } from "../common/hooks/hooks";
import { hideLoader, showLoader } from "../common/store/loaderDataSlice";
import { CustomConfirmationDialogProps } from "../common/components/CustomComponents/ConfirmationDialog";
import { showConfirmation } from "../common/store/confirmationBoxDataSlice";
import { useMsal } from "@azure/msal-react";
import CustomMultiSelectNew from "../common/components/CustomComponents/CustomMultiSelectNew";

interface IUser {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
  role: number;
  fAMCode?: number[];
  country?: number[];
  roleId?: number;
}

export interface IFamCode {
  id: number;
  valueInt: number;
  text: string;
  countryId: number;
}
export default function CreateNewUser() {
  const userManagementList = useLoaderData() as any;
  const [editData, setEditData] = useState<IUser>();
  const [isGlobalAdminSelected, setIsGlobalAdminSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const { userId } = useParams();
  const location = useLocation();
  const request = useRequest();
  const dispatchCancelPopup = useAppDispatch();
  const navigate = useNavigate();
  const [activeUser, setActiveUser] = useState(true);
  const [isUserRoleChanged, setIsUserRoleChanged] = useState(false);
  const [isUserStatusChanged, setIsUserStatusChanged] = useState(false);
  const [preUserStatus, setPreUserStatus] = useState();
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedFamcodes, setSelectedFamcodes] = useState([]);
  const editUser = useMemo(
    () => location.pathname.includes("edit-user"),
    [location]
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const getUserFromSession = useMemo(() => {
    const decodedData = atob(sessionStorage.getItem("ud") || "");
    const data = decodedData ? JSON.parse(decodedData) : "";
    return data;
  }, []);

  const userRole = useMemo(
    () => getUserFromSession?.role?.value,
    [getUserFromSession]
  );
  const LoggedInUserId = useMemo(
    () => getUserFromSession?.id,
    [getUserFromSession]
  );

  const rolesList = useMemo(() => {
    let roles = userManagementList ? userManagementList.rolesList : [];
    if (userManagementList && userRole === Role.LocalAdmin) {
      roles = roles.filter((role: any) => role.text !== Role.GlobalAdmin);
    }
    return roles;
  }, [userManagementList, userRole]);

  function handleCountryChange(selectedCountries: any) {
    const countryIds = selectedCountries.map((country: any) => country.id);
    var updatedFamCodes = selectedFamcodes.filter((famcode: any) =>
      countryIds.includes(famcode.countryId)
    );
    methods.setValue(
      "fAMCode",
      updatedFamCodes.map((famcode: any) => famcode.id)
    );
    methods.setValue("country", countryIds);

    setSelectedCountry(selectedCountries);
    setSelectedFamcodes(updatedFamCodes);
  }

  function handleFamCodesChange(selectedFamcodes: any) {
    methods.setValue(
      "fAMCode",
      selectedFamcodes.map((famcode: any) => famcode.id)
    );
    setSelectedFamcodes(selectedFamcodes);
  }
  const countriesList = useMemo(() => {
    let countries = userManagementList ? userManagementList.countriesList : [];
    const userCountries: string[] = getUserFromSession?.country.map(
      (data: any) => data.value
    );
    if (userManagementList && userRole === Role.LocalAdmin) {
      countries = countries.filter((country: Country) =>
        userCountries.includes(country.countryName)
      );
    }
    return countries;
  }, [getUserFromSession, userManagementList, userRole]);

  const changeActiveUser = () => {
    setActiveUser((prev) => !prev);
    if (preUserStatus !== !activeUser && LoggedInUserId == userId && editUser) {
      setIsUserStatusChanged(true);
    } else {
      setIsUserStatusChanged(false);
    }
  };

  const createUserfields1 = [
    {
      name: "firstName",
      label: "First Name",
    },
    {
      name: "lastName",
      label: "Last Name",
    },
    {
      name: "role",
      label: "Role",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
    },
  ];

  const createUserfields2 = [
    {
      name: "country",
      label: "Country",
    },
    {
      name: "fAMCode",
      label: "FAM Code",
    },
  ];

  const navigateToUserManagement = () => {
    navigate("/user-management");
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("This field is required"),
    phoneNumber: Yup.string()
      .nullable()
      .transform((o, c) => (o === "" ? null : c))
      .min(6, "Minimum 6 digits are required"),
    role: Yup.number().required("This field is required"),
    fAMCode: isGlobalAdminSelected
      ? Yup.array()
      : Yup.array()
          .min(1, "This field is required")
          .required("This field is required"),
    country: isGlobalAdminSelected
      ? Yup.array()
      : Yup.array()
          .min(1, "This field is required")
          .required("This field is required"),
  });
  const methods = useForm<IUser>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    // defaultValues: {
    //   fAMCode: [] as number[],
    // },
  });
  const watchRole = methods.watch("role");

  useEffect(() => {
    if (watchRole == 1) {
      setIsGlobalAdminSelected(true);
    } else {
      setIsGlobalAdminSelected(false);
    }
  }, [watchRole]);

  const loadData = () => {
    request({ url: RequestApi.GET_USER_BY_ID + `${userId}`, method: "post" })
      .then((res) => {
        // console.log(res, 'response');
        methods.setValue("firstName", res.data.firstName);
        methods.setValue("lastName", res.data.lastName);
        methods.setValue("email", res.data.email);
        methods.setValue(
          "phoneNumber",
          res.data.phoneNumber ? res.data.phoneNumber : ""
        );
        methods.setValue("role", res.data.roleId);
        methods.setValue("fAMCode", res.data.famCode);
        methods.setValue("country", res.data.country);
        var selectedCountries = countriesList.filter((country: any) =>
          res?.data?.country?.includes(country.id)
        );

        var selectedFamcodes = userManagementList?.famCodesList?.filter(
          (famcode: IFamCode) => res?.data?.famCode.includes(famcode.id)
        );

        setSelectedCountry(selectedCountries);
        setSelectedFamcodes(selectedFamcodes);
        setActiveUser(res.data.isActive);
        setEditData(res.data);
        setPreUserStatus(res.data.isActive);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (userId) {
      loadData();
    }
  }, [userId]);

  function setFirstLetterToUppercase(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
  async function submitData(data: any) {
    let body = {
      id: editUser ? userId : 0,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber ? data.phoneNumber : "",
      email: data.email,
      fAMCode: isGlobalAdminSelected ? [] : data.fAMCode,
      country: isGlobalAdminSelected ? [] : data.country,
      roleId: data.role,
      isActive: activeUser,
    };

    request({
      url: editUser ? RequestApi.UPDATE_USER : RequestApi.ADD_USER,
      method: "post",
      data: body,
    })
      .then((resp) => {
        if (
          userRole === Role.GlobalAdmin &&
          LoggedInUserId == userId &&
          editUser &&
          data.role !== 1
        ) {
          setIsUserRoleChanged(true);
        }
        setOpen(true);
      })
      .catch((err) => {
        if (err.response.data.error === true) {
          Object.keys(validationSchema.fields).forEach((key) => {
            const camelCaseKey = setFirstLetterToUppercase(key);
            if (
              err.response.data.result[camelCaseKey] !== null &&
              err.response.data.result[camelCaseKey] !== undefined
            ) {
              if (
                key === "firstName" ||
                key === "lastName" ||
                key === "email" ||
                key === "phoneNumber" ||
                key === "role" ||
                key === "fAMCode" ||
                key === "country"
              ) {
                methods.setError(key, {
                  type: "server",
                  message: err.response.data.result[camelCaseKey],
                });
              }
            }
          });
          let responseArr = err.response.data;
          for (let key in responseArr.result) {
            if (key === "RoleId" && Array.isArray(responseArr.result[key])) {
              setAlert("error", responseArr.result[key][0], true);
            }
          }
        } else {
          setAlert("error", "Request Failed", true);
        }
      });
  }

  methods.watch("country");

  const selectedCountriesId = methods.getValues("country");

  const famCodeOfSelectedCountries = useMemo(
    () =>
      userManagementList?.famCodesList?.filter((famcode: IFamCode) =>
        selectedCountriesId?.includes(famcode.countryId)
      ),
    [selectedCountriesId, userManagementList]
  );

  function onCloseCancelSave() {
    dispatchCancelPopup(hideLoader());
  }

  function onConfirmCancelSave() {
    dispatchCancelPopup(hideLoader());
    navigateToUserManagement();
  }

  function handleCancelClickOpen(event: any) {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content:
        "If you proceed, you will lose all your entered data, unsaved changes may be lost.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: onConfirmCancelSave,
    };
    dispatchCancelPopup(showConfirmation(customConfirmationDialogProps));
    dispatchCancelPopup(showLoader());
  }

  return (
    <>
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
        hideTime={5000}
      />
      <Box
        sx={{
          boxShadow: 0,
          paddingBlock: "10px 12px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
        px={2}
        mb={1}
      >
        <div className={classes["page-title-container"]}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              src={BackArrowIcon}
              height="20"
              alt="Back"
              style={{ cursor: "pointer" }}
              loading="lazy"
              decoding="async"
              onClick={navigateToUserManagement}
            />
            <div className={classes["page-title"]}>
              {editUser ? "Edit " : "Create New "}
              User
            </div>
          </div>
        </div>
      </Box>
      <Box
        style={{
          position: "relative",
          overflow: "auto",
          height: "calc(100vh - 184px)",
          paddingRight: ".5rem",
          borderRadius: "8px",
          background: "#fff",
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submitData)}>
            <Grid
              container
              spacing={2}
              p={1}
            >
              {createUserfields1.map(({ name, label }) => {
                if (name === "role") {
                  return (
                    <CustomSelect
                      name={name}
                      label={label}
                      setValue={methods.setValue}
                      isRequired
                      options={rolesList}
                    />
                  );
                } else {
                  return (
                    <CustomInput
                      isRequired={name !== "phoneNumber"}
                      name={name}
                      numberOnly={name === "phoneNumber"}
                      label={label}
                      variant="outlined"
                      disabled={name === "email" && editUser}
                      fullWidth
                    />
                  );
                }
              })}
            </Grid>
            <Grid
              container
              spacing={2}
              p={1}
            >
              {createUserfields2.map(({ name, label }) => {
                if (name === "fAMCode") {
                  return (
                    <CustomMultiSelectNew
                      name={name}
                      label={label}
                      md={4}
                      options={famCodeOfSelectedCountries}
                      disabled={isGlobalAdminSelected ? true : false}
                      icon={VectorIcon}
                      handleChange={handleFamCodesChange}
                      placeholder={
                        isGlobalAdminSelected
                          ? "All FAMCodes"
                          : "Select FAMCodes"
                      }
                      selectedValues={selectedFamcodes}
                      displayValue="text"
                    />
                  );
                } else {
                  return (
                    <CustomMultiSelectNew
                      name={name}
                      label={label}
                      md={4}
                      options={countriesList}
                      disabled={isGlobalAdminSelected ? true : false}
                      icon={VectorIcon}
                      handleChange={handleCountryChange}
                      placeholder={
                        isGlobalAdminSelected
                          ? "All Countries"
                          : "Select country"
                      }
                      selectedValues={selectedCountry}
                      displayValue="countryName"
                    />
                  );
                }
              })}
              {editUser && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  xl={4}
                  direction="row"
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"end"}
                >
                  <Stack
                    display={"flex"}
                    flexDirection={"row"}
                  >
                    <p>Inactive user</p>
                    <Switch
                      onChange={changeActiveUser}
                      checked={activeUser}
                      sx={{
                        "&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked": {
                          color: "#0078a1",
                        },
                      }}
                      size="small"
                    />
                    <p>Active user</p>
                  </Stack>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              xl={12}
              lg={12}
              direction="row"
              justifyContent="flex-end"
              sx={{ padding: "2rem .5rem !important" }}
            >
              <Button
                sx={{ textTransform: "none" }}
                className="cancel-button custom-buttons"
                disableRipple
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginRight: "15px",
                }}
                variant="outlined"
                onClick={handleCancelClickOpen}
              >
                Cancel
              </Button>
              <Button
                style={{ fontSize: "18px", fontWeight: "400" }}
                type="submit"
                className="custom-buttons"
                sx={{ textTransform: "none" }}
                variant="contained"
                disableRipple
                disabled={
                  editUser &&
                  userId !== undefined &&
                  ((LoggedInUserId == userId &&
                    userRole !== Role.GlobalAdmin) ||
                    (editData?.roleId == 1 && userRole !== Role.GlobalAdmin))
                }
                size="medium"
              >
                {editUser ? "Update " : "Create "}
                User
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <UserCreationSuccessfulDailog
        open={open}
        setOpen={setOpen}
        edit={editUser}
        editedLoggedInUser={LoggedInUserId.toString() === userId?.toString()}
        isUserRoleChanged={isUserRoleChanged}
        isUserStatusChanged={isUserStatusChanged}
      />
    </>
  );
}

interface UserCreationSuccessfulDailogProps {
  open: boolean;
  edit: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editedLoggedInUser?: boolean;
  isUserRoleChanged?: boolean;
  isUserStatusChanged?: boolean;
}

function UserCreationSuccessfulDailog(
  props: UserCreationSuccessfulDailogProps
) {
  const {
    open,
    setOpen,
    edit,
    editedLoggedInUser,
    isUserRoleChanged,
    isUserStatusChanged,
  } = props;
  const navigate = useNavigate();
  const msal = useMsal();
  const updateLogOutMsg =
    "User updated successfully! \nLogging out of the application \nto see changes.";
  let popUpMessage = "";
  if (isUserRoleChanged) {
    popUpMessage = updateLogOutMsg;
  } else if (isUserStatusChanged) {
    popUpMessage = updateLogOutMsg;
  } else if (edit) {
    popUpMessage = "User updated successfully!";
  } else {
    popUpMessage = "User created successfully!";
  }
  const navigateToUserManagement = () => {
    if (isUserRoleChanged) {
      logOut();
    } else if (isUserStatusChanged) {
      logOut();
    } else {
      navigate("/user-management");
    }
  };

  const logOut = () => {
    try {
      navigate("/");
      sessionStorage.clear();
      msal.instance.logoutRedirect();
    } catch (e) {
      console.log("error:" + e);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const Close = () => {
    setOpen(false);
    if (isUserRoleChanged) {
      logOut();
    } else if (isUserStatusChanged) {
      logOut();
    } else {
      navigateToUserManagement();
    }
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "400px",
            borderRadius: "20px",
          },
        }}
        onClose={handleClose}
        open={open}
      >
        <div style={{ textAlign: "right", cursor: "pointer" }}>
          <img
            onClick={Close}
            src={CloseIcon}
            height="35"
            alt="Back"
          ></img>
        </div>
        <div
          className="iconimg"
          style={{ paddingTop: "1rem" }}
        >
          <img
            src={UserCreatedIcon}
            height="35"
            alt="Back"
            loading="lazy"
            decoding="async"
          ></img>
        </div>

        <div style={{ padding: "10px 20px 20px 20px", textAlign: "center" }}>
          <ConfirmDelete
            message={popUpMessage}
            textCss="bold-message-report"
            CloseDeleteDailog={Close}
            CloseRecommendationDailog={navigateToUserManagement}
            btnText="Okay"
            btnCss="cancel-button custom-buttons"
            hideCancel={false}
            variantCSS="outlined"
          ></ConfirmDelete>
        </div>
      </Dialog>
    </>
  );
}
