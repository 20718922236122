import { Dialog, Grid, IconButton, SxProps, Theme } from "@mui/material";
import closeIcon from "../../../assets/icons/close.svg";

interface CustomRibbonProps {
  md: number;
  style: SxProps<Theme>;
  messagesInHtmlArray: any[];
  icon?: any;
  paraColor: string;
  onClose?: () => void;
  iconHeight?: number;
  newCloseIcon?: any;
}

const CustomMessageDialog = ({
  md,
  style,
  messagesInHtmlArray,
  icon,
  iconHeight,
  onClose,
  paraColor,
  newCloseIcon,
}: CustomRibbonProps) => {
  return (
    <>
      {messagesInHtmlArray != null && messagesInHtmlArray.length > 0 && (
        <Grid
          md={md}
          lg={md}
          sx={style}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                margin: "20px 28px 20px 28px",
              }}
            >
              <img
                src={icon}
                height={"auto"}
                alt={icon}
                loading="lazy"
                decoding="async"
              ></img>
            </div>
            <div
              style={{
                margin: 0,
                color: paraColor,
                paddingTop: "12px",
                paddingBottom: "12px",
              }}
            >
              {messagesInHtmlArray.map((message) => (
                <p>{message}</p>
              ))}
            </div>
            {onClose && (
              <img
                src={newCloseIcon ? newCloseIcon : closeIcon}
                height={newCloseIcon ? "auto" : "35px"}
                width={newCloseIcon ? "auto" : "35px"}
                alt={icon}
                loading="lazy"
                decoding="async"
                onClick={onClose}
                style={{
                  position: "absolute",
                  top: newCloseIcon ? "10px" : "4px",
                  right: newCloseIcon ? "10px" : "4px",
                  cursor: "pointer",
                }}
              ></img>
            )}
          </div>
        </Grid>
      )}
    </>
  );
};

export default CustomMessageDialog;
