import * as Yup from "yup";
import { Box, Button, Dialog, FormLabel, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import CustomTable from "../common/components/CustomComponents/CustomTable";
import { useNavigate } from "react-router";
import search_icon from "../assets/icons/search_icon.svg";
import classesAddCustomer from "./AddCustomer.module.css";
import { RequestApi } from "../common/Constant";
import { useRequest } from "../services/agent";
import { PageConstants } from "../common/PageConstants";
import CloseIcon from "../assets/icons/close.svg";
import addCustomerIcon from "../assets/icons/add_Customer.svg";
import { UserRoleConstants } from "../common/UserRoleConstants";
import CustomAlert from "../common/components/CustomComponents/CustomAlert";
import CustomSelect from "../common/components/CustomComponents/CustomSelect";
import { TableColumn } from "./AddCustomer";
import { TableActionButtons } from "../common/interface/TableColumn";
import DropdownModel from "../common/interface/DropdownModel";
import { IAirAuditData } from "./OngoingProject";
interface IUserData {
  userName: string;
  email?: string;
  phoneNumber?: string;
  role?: string;
  famCode?: string;
  country?: string;
  active?: boolean;
  lastLoginOn?: string;
}
interface AssignEnergySpecialistProps {
  open: boolean;
  onClose: () => any;
  ongoingProjectId: number;
  data: IAirAuditData;
}

export type tableColumns =
  | "famCode"
  | "userName"
  | "country"
  | "isActive"
  | "phoneNumber"
  | "lastLoginOn";

function AssignEnergySpecialist(props: AssignEnergySpecialistProps) {
  const { open, onClose, ongoingProjectId, data } = props;
  const [getData, setGetData] = useState(false);
  const navigation = useNavigate();
  const [notInitialRender, setNotInitialRender] = useState(false);
  const request = useRequest();
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [famCode, setFAMCode] = useState<number>(0);
  const [famCodesList, setFAMCodeList] = useState<Array<DropdownModel>>([]);
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const [userList, setUserList] = useState<Array<IUserData>>([]);
  const validationSchema = Yup.object().shape({
    userName: Yup.string().required("This field is required"),
  });

  useEffect(() => {
    const makeFAMCodesRequest = async () => {
      try {
        const response = await request({
          url: RequestApi.GET_FAM_CODES_LIST,
          method: "get",
        });
        setFAMCodeList(response.data);
        console.warn(famCodesList);
        console.log("API response:", response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    makeFAMCodesRequest(); // Call the API function
  }, []);

  const methods = useForm<IUserData>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    reValidateMode: "onBlur",
  });

  const columns: TableColumn[] = [
    {
      id: "famCode" as tableColumns,
      label: "FAM Code",
      align: "left",
      minWidth: 150,
      filter: false,
      sorting: false,
    },
    {
      id: "userName" as tableColumns,
      label: "User Name",
      align: "left",
      minWidth: 200,
      filter: false,
      sorting: false,
    },
    {
      id: "country" as tableColumns,
      label: " Country",
      align: "left",
      minWidth: 150,
      filter: false,
      sorting: false,
    },
    {
      id: "phoneNumber" as tableColumns,
      label: "Contact Details",
      align: "left",
      filter: false,
      sorting: false,
    },
    {
      id: "lastLoginOn" as tableColumns,
      label: "Last Logon Date",
      align: "left",
      filter: false,
      sorting: false,
    },
  ];
  function handleClick(
    auditIdVal: number,
    auditNumber: string,
    event: any,
    row: any
  ) {
    handleAssignEnergySpecialist(row.id);
  }

  const handleAssignEnergySpecialist = async (
    id?: number,
    isAssignToMe: boolean = false
  ) => {
    let payload = {
      energySpecialistId: id,
      ongoingProjectId: ongoingProjectId,
      isAssignToMe: isAssignToMe,
    };

    request({
      url: RequestApi.ADD_ENERGY_SPECIALIST,
      method: "post",
      data: payload,
    })
      .then((resp) => {
        setAlert("success", "Responsible Person Added Successfully", true);
        methods.reset();
        close();
      })
      .catch((err) => {
        setAlert("error", "Request Failed", true);
      });
  };
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
  }

  const close = () => {
    onClose();
    methods.reset();
    setPage(0);
    setFAMCode(0);
    setTotalRecords(0);
    setUserList([]);
    setGetData(false);
    setNotInitialRender(false);
  };

  function loadData() {
    setNotInitialRender(true);
    let body = {
      page: page,
      pageSize: 20,
      userName: methods.getValues("userName")
        ? methods.getValues("userName")
        : "",
      famCode: famCode ? famCode : null,
      order: "asc",
    };

    request({
      url: RequestApi.GET_NON_FSE_USERS,
      method: "post",
      data: body,
    })
      .then((resp) => {
        setUserList(resp.data.data);
        setTotalRecords(resp.data.totalRecord);
        setGetData(false);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_RESPONSE") {
          console.log(err);
          setAlert("error", "Request Failed", true);
        } else {
          console.log(err);
          setAlert("error", "Request Failed", true);
        }
      });
  }
  function loadSearchData() {
    page === 0 ? loadData() : !notInitialRender ? loadData() : setPage(0);
  }

  const handleChange = (event: any) => {
    setFAMCode(event);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const actionButtons: TableActionButtons[] = [
    {
      name: "",
      handleClick: handleClick,
      permission: true,
      icon: addCustomerIcon,
      textInsideButton: <span style={{ marginLeft: "5px" }}>Assign</span>,
    },
  ];

  useEffect(() => {
    if (getData) loadData();
  }, [getData, page]);

  useEffect(() => {
    if (open && data) {
      const famCodeId = data?.famCodeId ? data?.famCodeId : 0;
      setFAMCode(famCodeId);
      methods.setValue("famCode", famCodeId.toString());
    }
  }, [data, open]);

  return (
    <React.Fragment>
      <CustomAlert
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
      />
      <Dialog
        sx={{
          right: "0",
          "&.MuiModal-root>.MuiDialog-container>.MuiPaper-root": {
            marginTop: "0",
            marginInline: "0",
            height: "calc(98% - 32px)",
            width: "1083px",
            maxHeight: "unset",
            maxWidth: "unset",
            borderRadius: "1.25rem",
            overflow: "hidden",
          },
          "&.MuiModal-root>.MuiDialog-container": {
            justifyContent: "center",
          },
        }}
        open={open}
      >
        <Box
          sx={{
            boxShadow: 0,
            borderBlock: 0,
            "&.MuiBox-root": {
              border: "none",
              backgroundColor: "#fff",
              paddingLeft: "21px",
              paddingRight: "29.27px",
              borderRadius: "8px",
              paddingTop: "1rem",
            },
          }}
        >
          <Grid
            item
            md={12}
            sx={{ marginBottom: "13px", position: "relative" }}
            className={classesAddCustomer["page-title"]}
          >
            Assign Responsible Person
            <div className={classesAddCustomer["title-container-right"]}>
              <img
                style={{ cursor: "pointer", float: "right" }}
                onClick={close}
                src={CloseIcon}
                height="35px"
                alt="Back"
                loading="lazy"
                decoding="async"
              />
            </div>
          </Grid>
          <Grid
            container
            style={{
              marginBottom: "13px",
            }}
          >
            <Grid
              md={"auto"}
              style={{ borderRight: "1px solid #9F9F9F", paddingRight: "1rem" }}
            >
              <p style={{ fontWeight: 600, color: "#000000" }}>User Name</p>
              <p style={{ marginTop: "16px" }}>
                {data?.responsible ? data.responsible : "N/A"}
              </p>
            </Grid>
            <Grid md={0.28}></Grid>
            <Grid
              md={2}
              style={{ borderRight: "1px solid #9F9F9F" }}
            >
              <p style={{ fontWeight: 600, color: "#000000" }}>Country</p>
              <p style={{ marginTop: "16px" }}>
                {data?.country ? data.country : "N/A"}
              </p>
            </Grid>
            <Grid md={0.28}></Grid>
            <Grid md={2}>
              <p style={{ fontWeight: 600, color: "#000000" }}>FAM Code</p>
              <p style={{ marginTop: "16px" }}>
                {data?.famCode ? data.famCode : "N/A"}
              </p>
            </Grid>
          </Grid>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(loadSearchData)}>
              <Grid
                container
                spacing={2}
              >
                <CustomInput
                  isRequired={true}
                  name={"userName"}
                  label={"Name"}
                  variant="outlined"
                  fullWidth
                />
                <CustomSelect
                  name={"famCode"}
                  label={"FAM Code"}
                  setValue={methods.setValue}
                  options={famCodesList}
                  isRequired={false}
                  md={4}
                  customHandleChange={handleChange}
                  disabled
                />

                <Grid
                  item
                  sx={{ marginTop: "20px" }}
                  md={2}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    startIcon={
                      <img
                        src={search_icon}
                        alt="search-icon"
                        style={{
                          width: "16px",
                          height: "16px",
                        }}
                        loading="lazy"
                        decoding="async"
                      />
                    }
                    sx={{
                      padding: "10px 12px !important",
                      color: "#FFF",
                      fontSize: 18,
                      fontWeight: 400,
                      height: "35px !important",
                      lineHeight: "20px",
                      textTransform: "none",
                      boxShadow: "none !important",
                      gap: "6px !important",
                    }}
                    disableRipple
                  >
                    Search
                  </Button>
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginTop: "20px" }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      padding: "10px 12px !important",
                      color: "#0099CC",
                      fontSize: 18,
                      fontWeight: 400,
                      height: "35px !important",
                      lineHeight: "20px",
                      textTransform: "none",
                      borderColor: "#0099CC",
                    }}
                    fullWidth
                    onClick={() => {
                      handleAssignEnergySpecialist(0, true);
                    }}
                    disableRipple
                  >
                    Assign to me
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
        <Box
          sx={{
            border: "none",
            marginTop: "12px",
            paddingInline: "1.4rem",
            boxShadow: "unset",
          }}
        >
          <CustomTable
            data={userList}
            isInsideTab={true}
            columns={columns}
            actionColumnWidth={"300px"}
            setGetData={setGetData}
            showPagination={totalRecords > 20}
            stickyActionColumn={false}
            totalRecords={totalRecords}
            showFilters={false}
            actionButtons={actionButtons}
            pageName={PageConstants.AssignEnergySpecialist}
            fromAddResponsiblePersonModal
            notInitialRender={notInitialRender}
            loadDataFunction={loadData}
            page={page}
            setPage={setPage}
          />
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

export default AssignEnergySpecialist;
