import React, { Fragment, useRef, useState } from "react";
import classes from "./DragDrop.module.css";
import UploadFileIcon from "./../../../assets/icons/upload_file.svg";
import ExcelFileIcon from "./../../../assets/icons/excel.svg";
import PDFFileIcon from "./../../../assets/icons/pdf_icon.svg";
import WordFileIcon from "./../../../assets/icons/word_doc.svg";
import PPTFileIcon from "./../../../assets/icons/ppt_icon.svg";
import CloseIcon from "./../../../assets/icons/close.svg";
import { Button, Grid, Link, Tooltip } from "@mui/material";
import CustomAlertTest from "./CustomAlert";

interface Props {
  data: any;
  dispatch: React.Dispatch<any>;
  fileTypes: string;
  message: string;
  maxFileCount: number;
  availableFileCount: number;
  savedFiles?: any;
}

const DragDrop: React.FC<Props> = ({
  data,
  dispatch,
  fileTypes,
  availableFileCount,
  message,
  maxFileCount,
  savedFiles,
}) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  // const [totalFileSize, setTotalFileSize] = useState(0);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth + 1 });
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth - 1 });
    if (data.dropDepth > 0) return;
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    let files = [...e.dataTransfer.files];
    addFiles(files);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    inputRef.current?.click();
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    handleCloseDialog();
  }

  function getfileSizeInBytes(fileSize: string) {
    var fileData = fileSize.split(" ");

    if (fileData[1] === "bytes") return +fileData[0];
    else if (fileData[1] === "KB") return +fileData[0] * 1024;
    else if (fileData[1] === "MB") return +fileData[0] * 1024 * 1024;
    else if (fileData[1] === "GB") return +fileData[0] * 1024 * 1024 * 1024;
    else return 0;
  }

  function addFiles(fileList: any) {
    var totalFileSize = 0;
    debugger;
    for (let index = 0; index < savedFiles.length; index++) {
      const element = savedFiles[index];
      totalFileSize = totalFileSize + getfileSizeInBytes(element.fileSize);
    }

    for (let index = 0; index < data.fileList.length; index++) {
      const element = data.fileList[index];
      totalFileSize = totalFileSize + element.size;
    }

    for (let index = 0; index < fileList.length; index++) {
      const element = fileList[index];
      totalFileSize = totalFileSize + element.size;
    }

    console.log("Total File Size : " + totalFileSize);

    let files: (File | null)[] = new Array<File>();

    let length = fileList ? fileList.length : 0;
    //length += data.fileList ? data.fileList.length : 0

    let fileCount = data.fileList ? data.fileList.length : 0;
    fileCount += length;

    if (fileCount + availableFileCount > maxFileCount) {
      setAlert("error", "Maximum 5 files are allowed", true);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      return;
    }
    console.log(fileList, "filelist");
    const allowedFileTypes = [
      "ppt",
      "pptx",
      "pdf",
      "xls",
      "xlsx",
      "doc",
      "docx",
    ];

    function isEightDotThreeFormat(fileName: string): boolean {
      const regexShortName =
        /^[A-Z0-9!#$%&'()-@^_`{}~]{0,6}~[0-9]\.[A-Z]{3,4}$/;

      const isEightDotThreeFormat = regexShortName.test(fileName);
      console.log(isEightDotThreeFormat, "isEightDotThreeFormat");
      return isEightDotThreeFormat;
    }

    for (let index = 0; index < length; index++) {
      if (fileList) {
        const file = fileList[index];
        const fileSize = file.size / 1024;
        const fileArr = file.name.split(".");

        let fileExtension = fileArr[fileArr.length - 1];

        if (savedFiles.length > 0) {
          var duplicate = savedFiles.find((item: any) => {
            return item?.attachedFileName === file.name;
          });
          if (duplicate) {
            //setAlert('error', 'File already exist', true);
            continue;
          }
        }
        console.log(file.name);
        if (file.name.length > 500) {
          setAlert(
            "error",
            "File name should be less than 500 characters",
            true
          );

          continue;
        }

        if (isEightDotThreeFormat(file.name)) {
          setAlert(
            "error",
            "The file/folder name is too long. Please shorten it and try again",
            true
          );

          continue;
        }
        debugger;
        if (totalFileSize / (1024 * 1024) > 100) {
          setAlert(
            "error",
            "The total file size should not exceed 100MB",
            true
          );
          continue;
        }
        if (!allowedFileTypes.includes(fileExtension)) {
          setAlert(
            "error",
            "Please upload Word, Excel, PPT Or PDF files only",
            true
          );
          continue;
        }
        files[index] = fileList[index];
      }
    }
    if (files && files.length > 0) {
      const existingFiles = data.fileList.map((f: any) => f.name);
      files = files.filter((f: any) => !existingFiles.includes(f.name));

      dispatch({ type: "ADD_FILE_TO_LIST", files });
      dispatch({ type: "SET_DROP_DEPTH", dropDepth: 0 });
      dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    const fileList = event.target.files;
    addFiles(fileList);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const removeFile = (fileName: string) => {
    let files: File[] = data.fileList;
    if (files && files.length > 0) {
      dispatch({ type: "REMOVED_FILE_TO_LIST", fileName });
    }
  };
  function formatFileSize(fileSize: number) {
    if (fileSize < 1024) {
      return fileSize + " bytes";
    } else if (fileSize < 1024 * 1024) {
      return (fileSize / 1024).toFixed(2) + " KB";
    } else if (fileSize < 1024 * 1024 * 1024) {
      return (fileSize / (1024 * 1024)).toFixed(2) + " MB";
    } else {
      return (fileSize / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    }
  }

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Fragment>
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
      />
      {/* <div className={classes["message"]}>
        <div className={classes["max-file-size"]}>Maximum file size 20MB </div>
      </div> */}
      <div
        className={classes["drag-drop-container"]}
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        onClick={(e) => handleClick(e)}
      >
        <div className="display-data-center">
          <img
            src={UploadFileIcon}
            height="50"
            width="40"
            alt="Upload Files"
            loading="lazy"
            decoding="async"
          ></img>
        </div>
        <div className="display-data-center">
          <p>
            Drag and drop files here Or{" "}
            {/* <Button
              disableRipple
              href="#text-buttons"
              sx={{ textTransform: "none" }}
            >
              Choose File
            </Button> */}
            <Link
              href="#"
              sx={{ color: "var(--primary-color)" }}
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              Choose File
            </Link>
          </p>
        </div>
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
          multiple
          accept={fileTypes}
        />
      </div>
      <div className={classes["message"]}>
        <div>
          <span>{message}</span>
        </div>
        {/*<div className={classes["max-file-size"]}>Maximum file size 20MB </div>*/}
      </div>

      {data.fileList.map((f: any) => {
        console.log("f", f);

        let icon = null;

        icon = ExcelFileIcon;

        if (f.name.includes(".pdf")) {
          icon = PDFFileIcon;
        }
        if (f.name.includes(".doc") || f.name.includes(".docx")) {
          icon = WordFileIcon;
        }
        if (f.name.includes(".ppt") || f.name.includes(".pptx")) {
          icon = PPTFileIcon;
        }

        return (
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            direction="row"
          >
            <div className={classes["dropped-files"]}>
              <div className="display-data-left">
                <div className={classes["img-container"]}>
                  <img
                    src={icon}
                    height="30"
                    width="30"
                    alt="Excel"
                    loading="lazy"
                    decoding="async"
                  ></img>
                </div>

                <div style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                  <Tooltip
                    title={f.name}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10],
                            },
                          },
                        ],
                      },
                      tooltip: {
                        sx: {
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontFamily: "Source Sans Pro",
                        },
                      },
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {f.name}
                    </div>
                  </Tooltip>
                  <div>{formatFileSize(f.size)}</div>
                </div>

                {/* <CloseIcon /> */}
              </div>
              <div className="display-data-right">
                <img
                  src={CloseIcon}
                  className={classes["remove-file"]}
                  height="30"
                  width="30"
                  alt="Close"
                  onClick={(e) => removeFile(f.name)}
                  loading="lazy"
                  decoding="async"
                ></img>
              </div>
            </div>
          </Grid>
        );
      })}

      {/* <ol className={classes["dropped-files"]}>
        <div>
          {data.fileList.map((f: any) => {
            console.log("f", f);
            return (
              <li className="display-data-left" key={f.name}>
                <img
                  src={ExcelFileIcon}
                  height="30"
                  width="30"
                  alt="Excel"
                ></img>
                {f.name}
              </li>
            );
          })}
        </div>
      </ol> */}
    </Fragment>
  );
};

export default DragDrop;
