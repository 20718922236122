import { useEffect, useState } from "react";
import { UserRoleConstants } from "../common/UserRoleConstants";

const Dashboard = () => {
  const [reportUrl, setReportUrl] = useState<string>("");

  const getUserRoleId = () => {
    const decodedData = atob(sessionStorage.getItem("ud") || "");
    const data = decodedData ? JSON.parse(decodedData) : "";
    return data.role?.key;
  };

  useEffect(() => {
    if (getUserRoleId() === UserRoleConstants.GLOBAL_ADMIN) {
      setReportUrl(
        process.env.REACT_APP_POWER_BI_REPORT_GLOBAL_ADMIN
          ? process.env.REACT_APP_POWER_BI_REPORT_GLOBAL_ADMIN
          : ""
      );
    } else if (
      getUserRoleId() === UserRoleConstants.LOCAL_ADMIN ||
      getUserRoleId() === UserRoleConstants.LOCAL_USER
    ) {
      setReportUrl(
        process.env.REACT_APP_POWER_BI_REPORT_LOCAL_ADMIN_AND_LOCAL_USER
          ? process.env.REACT_APP_POWER_BI_REPORT_LOCAL_ADMIN_AND_LOCAL_USER
          : ""
      );
    }
  }, []);

  //setReportUrl(process.env.REACT_APP_POWER_BI_REPORT_GLOBAL_ADMIN + "");

  return (
    <div>
      <iframe
        style={{
          height: "calc(100vh - 130px)",
          width: "100%",
          borderRadius: "8px",
        }}
        allowFullScreen={true}
        frameBorder={0}
        src={reportUrl}
      ></iframe>
    </div>
  );
};

export default Dashboard;
