export enum RoutingConstants {
  NewProject = "new-project",
  FinishedReports = "finished-projects",
  GeneratedLeads = "generated-leads",
  GeneratedOpportunities = "generated-opportunities",
  CustomerCreate = "customer-create",
  UploadFinishedReport = "upload-finished-project",
  UpdateFinishedReport = "update-finished-project",
  UserManagement = "user-management",
  NewUser = "new-user",
  EditUser = "edit-user",
  LinkProjectUpload = "link-project-upload",
  LinkProjectUpdate = "link-project-update",
  UploadOngoingProject = "upload-ongoing-project",
  UpdateOngoingProject = "update-ongoing-project",
  AddProjectData = "add-project-data",
  Information = "information",
  OngoingProject = "ongoing-project",
  UnAuthorize = "unauthorized",
}
