import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ReleaseNote {
  show: boolean;
  Data:any;
}

const initialState: ReleaseNote = {
  show: false,
  Data:[]
};
const releaseNoteDataSlice = createSlice({
    name: "releaseNote",
    initialState: initialState,
    reducers: {
      openReleaseNote: (state) => {
        state.show = true;
      },
      closeReleaseNote: (state) => {
        state.show = false;
      },
      setData: (state, action: PayloadAction<any>) => {
       
        state.Data = action.payload;
      }
    },
  });
  

export const { openReleaseNote, closeReleaseNote,setData } = releaseNoteDataSlice.actions;

export default releaseNoteDataSlice;