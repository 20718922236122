import { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  Badge,
} from "@mui/material";
import {
  DatePickerMode,
  TableActionButtons,
  TableColumn,
} from "../../interface/TableColumn";

import AscSortIcon from "../../../assets/icons/Ascending_icon.svg";
import DescSortIcon from "../../../assets/icons/Descending_icon.svg";
import NoSortIcon from "../../../assets/icons/NoSort.svg";
import ellipseGreen from "../../../assets/icons/Ellipse_green.svg";
import ellipseDarkGreen from "../../../assets/icons/Ellipse_dark_green.svg";
import ellipseRed from "../../../assets/icons/Ellipse_red.svg";
import ellipseOrange from "../../../assets/icons/Ellipse_orange.svg";
import ellipseBlue from "../../../assets/icons/Ellipse_blue.svg";
import ellipseYellow from "../../../assets/icons/Ellipse_yellow.svg";
import { useSearchParams } from "react-router-dom";
import classes from "./CustomTable.module.css";
import * as React from "react";
import { KeyboardEvent } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CustomRangeColumn from "./CustomRangeColumn";
import RecommendationsListDialog from "./RecommendationsListDialog";
import SendReportToSmartLink from "../../../features/SendReportToSmartLink";
import dayjs from "dayjs";
import { Role } from "../../models/roles";
import AddRecommendationsdailog from "./AddRecommendationsdailog";
import { PageConstants } from "../../PageConstants";
import noDataFound from "../../../assets/images/NoDataFound.png";
import search_customer_graphic from "../../../assets/images/search_customer_graphic.svg";
import { UserRoleConstants } from "../../UserRoleConstants";
import { OngoingAuditStatus } from "../../AuditTypeConstants";
import { ProjectSourceConstants } from "../../ProjectSourceConstants";

interface TableData {
  columns: TableColumn[];
  data: any;
  setGetData: React.Dispatch<React.SetStateAction<boolean>>;
  filterObject?: any;
  page?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  sortColumn?: string;
  setSortColumn?: React.Dispatch<React.SetStateAction<string>>;
  sortColumnOrder?: string;
  setSortColumnOrder?: React.Dispatch<React.SetStateAction<string>>;
  achievedSales?: boolean;
  filterChange?: any;
  actionColumnNotNeeded?: boolean;
  generatedLeadsPage?: boolean;
  userManagementPage?: boolean;
  finishedReportsPage?: boolean;
  actionButtons?: TableActionButtons[];
  isInsideTab?: boolean;
  stickyActionColumn?: boolean;
  clearAll?: () => void;
  actionColumnWidth?: string;
  loadDataFunction?: () => void;
  totalRecords?: number;
  typeOfAudit?: string;
  pageName?: string;
  showPagination?: boolean;
  showFilters?: boolean;
  pageSpecificData?: any;
  fromAddCustomerModal?: boolean;
  fromAddResponsiblePersonModal?: boolean;
  notInitialRender?: boolean;
  ribbonMessage?: boolean;
}

const StatusElement = ({ value }: { value: string }) => {
  let imgIcon;
  let color;

  switch (value?.toLocaleLowerCase()) {
    case "lost":
      imgIcon = ellipseRed;
      color = "#C8102E";
      break;
    case "won":
      imgIcon = ellipseDarkGreen;
      color = "#236014";
      break;
    case "open":
      imgIcon = ellipseBlue;
      color = "#8DC8E8";
      break;
    case "stopped":
      imgIcon = ellipseOrange;
      color = "#F26522";
      break;
    case "converted":
      imgIcon = ellipseDarkGreen;
      color = "#236014";
      break;
    case "accepted":
      imgIcon = ellipseGreen;
      color = "#43B02A";
      break;
    case "qualified":
      imgIcon = ellipseYellow;
      color = "#FFCD00";
      break;
    case "declined":
      imgIcon = ellipseRed;
      color = "#C8102E";
      break;
    case "critical":
      imgIcon = ellipseRed;
      color = "#C8102E";
      break;
    case "medium":
      imgIcon = ellipseOrange;
      color = "#F26522";
      break;
    case "low":
      imgIcon = ellipseGreen;
      color = "#43B02A";
      break;
    case "deleted":
      imgIcon = ellipseOrange;
      color = "#F26522";
      break;

    default:
      imgIcon = ellipseYellow;
      color = "#FFCD00";
  }

  return (
    <div className={classes["status-element"]}>
      <img
        src={imgIcon}
        alt="status"
        loading="lazy"
        decoding="async"
      />
      <Typography
        textTransform="capitalize"
        sx={{ color: color }}
        fontSize="inherit"
      >
        {value}
      </Typography>
    </div>
  );
};

const ongoingProjectStatus = (customer: string, responsible: string) => {
  if (!customer && !responsible) {
    return OngoingAuditStatus.Project_Data_Added;
  } else if ((customer && !responsible) || (!customer && responsible)) {
    return OngoingAuditStatus.Information_Needed;
  } else {
    return OngoingAuditStatus.IN_Progress;
  }
};

const ActiveElement = ({ value }: { value: boolean }) => {
  return (
    <div className={classes["status-element"]}>
      <img
        src={value ? ellipseGreen : ellipseRed}
        alt="status"
        loading="lazy"
        decoding="async"
      />
      <Typography
        textTransform="capitalize"
        sx={{ color: value ? "#43B02A" : "#C8102E" }}
        fontSize="inherit"
      >
        {value ? "Active" : "Inactive"}
      </Typography>
    </div>
  );
};

const CustomTable = ({
  columns,
  data,
  page,
  setPage,
  filterChange,
  achievedSales,
  filterObject,
  actionColumnNotNeeded,
  generatedLeadsPage,
  clearAll,
  finishedReportsPage,
  actionButtons,
  userManagementPage,
  setGetData,
  isInsideTab,
  stickyActionColumn,
  actionColumnWidth,
  loadDataFunction,
  totalRecords,
  sortColumn,
  setSortColumn,
  sortColumnOrder,
  setSortColumnOrder,
  typeOfAudit,
  pageName,
  showPagination,
  showFilters,
  pageSpecificData,
  fromAddCustomerModal,
  fromAddResponsiblePersonModal,
  notInitialRender,
  ribbonMessage,
}: TableData) => {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  if (showPagination === undefined) {
    showPagination = true;
  }

  if (showFilters === undefined) {
    showFilters = true;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    if (setPage !== undefined) {
      setPage(newPage);
    }
    setGetData(true);
  };

  // React.useEffect(() => {
  //   console.log('customTable');
  //   loadDataFunction(page, sortColumn, sortColumnOrder);
  // }, [page]);

  const getSortIcon = () => {
    if (sortColumnOrder === "asc") {
      return AscSortIcon;
    } else if (sortColumnOrder === "desc") {
      return DescSortIcon;
    } else {
      return NoSortIcon;
    }
  };
  const getUserId = React.useMemo(() => {
    const decodedData = atob(sessionStorage.getItem("ud") || "");
    const data = decodedData ? JSON.parse(decodedData) : "";
    return data.id;
  }, []);

  const getUserRole = React.useMemo(() => {
    const decodedData = atob(sessionStorage.getItem("ud") || "");
    const data = decodedData ? JSON.parse(decodedData) : "";
    return data?.role?.value;
  }, []);

  const [sortingIcon, setSortingIcon] = useState<string>(NoSortIcon);
  let [searchParams, setSearchParams] = useSearchParams();
  const handleSortIcon = (columnName: string) => {
    if (setSortColumn !== undefined) {
      setSortColumn(columnName);
    }
    let sort = "";
    if (columnName === sortColumn) {
      if (sortingIcon === NoSortIcon) {
        setSortingIcon(AscSortIcon);
        sort = "asc";
      } else if (sortingIcon === AscSortIcon) {
        setSortingIcon(DescSortIcon);
        sort = "desc";
      } else {
        setSortingIcon(NoSortIcon);
        sort = "";
      }
    } else {
      setSortingIcon(AscSortIcon);
      sort = "asc";
    }
    if (setSortColumnOrder !== undefined && setPage !== undefined) {
      setSortColumnOrder(sort);
      setPage(0);
    }
    setGetData(true);
    // loadDataFunction(0, columnName, sort);
  };

  const handleClearClick = () => {
    if (setPage !== undefined) {
      setPage(0);
    }
    setGetData(true);
    // loadDataFunction();
  };

  const [open, setOpen] = React.useState(false);
  const [auditId, setAuditId] = React.useState(0);
  const [sendToSmartLinkOpen, setSendToSmartLinkOpen] = useState(false);
  const [recommendationDialogOpen, setRecommendationDialogOpen] =
    useState(false);
  const [auditNumber, setAuditNumber] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");

  const handleClickOpen = (event: any) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleAddRecommendationClose = () => {
    // setSelectedRecommendation(undefined);
    setRecommendationDialogOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickEvent = (
    actiontext: string,
    row: any,
    event: any,
    handleClick: any
  ) => {
    if (actiontext === "RecommendationList") {
      handleClickOpen(event);
      setAuditId(row.id);
      setCustomerName(row.customerName);
      setAuditNumber(row.auditId);
    } else if (actiontext === "SendToSmartLink") {
      event.stopPropagation();
      setSendToSmartLinkOpen(true);
    }
  };

  const handleClearAll = () => {
    if (clearAll !== undefined) {
      clearAll();
    }
    setSortingIcon(NoSortIcon);
  };

  const customPageColumnRender = (
    row: any,
    columnName: string | number,
    column: TableColumn
  ) => {
    const value = row[columnName];
    switch (pageName) {
      case PageConstants.OngoingProject:
        if (columnName == "customerName" && column.htmlElement) {
          const customerId = row["customerId"];
          return customerId || getUserRole == Role.FieldServiceEngineer
            ? column.htmlElement(value, row)
            : column.htmlElement(undefined, row);
        } else if (columnName == "responsible" && column?.htmlElement) {
          return value
            ? column.htmlElement(value, row)
            : column.htmlElement(undefined, row);
        }
    }
  };

  return (
    <>
      <Box
        display={showPagination ? "flex" : "none"}
        justifyContent={"end"}
        alignItems={"center"}
        style={{ border: "none" }}
      >
        {pageSpecificData?.leadStausUpdatedOn && (
          <span
            style={{
              fontWeight: 400,
              fontSize: "0.875rem",
              lineHeight: 1.43,
            }}
          >
            Lead status updated on : {pageSpecificData.leadStausUpdatedOn}
          </span>
        )}
        {pageSpecificData?.opportunityStausUpdatedOn && (
          <span
            style={{
              fontWeight: 400,
              fontSize: "0.875rem",
              lineHeight: 1.43,
            }}
          >
            Opportunity status updated on :{" "}
            {pageSpecificData.opportunityStausUpdatedOn}
          </span>
        )}
        {!fromAddResponsiblePersonModal && (
          <Tooltip
            title="Clear all filters"
            placement="left"
          >
            <Button
              sx={{
                lineHeight: "unset",
                padding: "unset",
                color: "#0099CC",
                marginLeft: "8px",
              }}
              onClick={handleClearAll}
            >
              Clear All
            </Button>
          </Tooltip>
        )}
        <TablePagination
          rowsPerPageOptions={[]}
          sx={{
            "& .MuiIconButton-root": {
              backgroundColor: "#f0f0f0",
              borderRadius: "5px  !important",
              padding: "2px  !important",
              margin: "2px  !important",
              height: "24px",
              width: "24px",
            },
            "& .MuiIconButton-root .Mui-disabled": {
              backgroundColor: "transparent",
            },
            "& .MuiTablePagination-toolbar": {
              minHeight: "30px",
              paddingRight: "10px",
            },
          }}
          component="div"
          count={totalRecords !== undefined ? totalRecords : 0}
          rowsPerPage={rowsPerPage}
          showFirstButton
          showLastButton
          page={page !== undefined ? page : 0}
          onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Box
        sx={{
          "& .MuiBox-root": {
            padding: "0px !important",
          },
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            sx={[
              data.length === 0 && {
                "& .MuiTable-root": {
                  height: "100% !important",
                },
              },
            ]}
            className={`${
              ribbonMessage
                ? classes["table-container-inside-tab-for-ribbonContent"]
                : fromAddCustomerModal
                ? classes["table-container-customer-modal"]
                : fromAddResponsiblePersonModal
                ? classes["table-container-responsible-modal"]
                : isInsideTab
                ? pageName !== PageConstants.UserManagement
                  ? classes["table-container-inside-tab"]
                  : classes["table-container-inside-tab-for-userManagement"]
                : classes["table-container"]
            }`}
          >
            {/*height will be ignored, don't worry. */}
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ height: "1px" }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      className={`${classes["table-header"]} 
                      ${classes["tabel-cell-border"]} ${
                        column.label === "Name"
                          ? classes["stickyHeader-left"]
                          : ""
                      } ${
                        column.label === "Density"
                          ? classes["stickyHeader-right"]
                          : ""
                      }`}
                      key={column.id}
                      align={column.align}
                      style={
                        (!column.label.includes("Date") && {
                          maxWidth: column.minWidth,
                          minWidth: column.minWidth,
                        }) || { minWidth: column.minWidth }
                      }
                    >
                      <div className={classes["table-header-with-sorting"]}>
                        <p
                          className={
                            column.sorting === true
                              ? "tableheader-with-sort"
                              : ""
                          }
                        >
                          <span className={classes["table-title"]}>
                            {column.label}
                          </span>
                          {column.sorting === true && (
                            <span className="tablethimg">
                              <img
                                alt=""
                                src={
                                  sortColumn === column.id
                                    ? sortingIcon
                                    : NoSortIcon
                                }
                                height="20"
                                onClick={(event) =>
                                  handleSortIcon(column.id.toString())
                                }
                                loading="lazy"
                                decoding="async"
                              ></img>
                            </span>
                          )}
                        </p>

                        {column.filter &&
                          (column.filterType === "datepicker" ? (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                views={
                                  column.datePickerMode ===
                                  DatePickerMode.CompleteDate
                                    ? ["year", "month", "day"]
                                    : ["year", "month"]
                                }
                                format={
                                  column.datePickerMode ===
                                  DatePickerMode.CompleteDate
                                    ? "DD/MM/YYYY"
                                    : "MM/YYYY"
                                }
                                value={
                                  filterObject[`${column.id}`]
                                    ? dayjs(filterObject[`${column.id}`])
                                    : null
                                }
                                slotProps={{
                                  // pass props `actions={['clear']}` to the actionBar slot
                                  actionBar: { actions: ["clear"] },
                                }}
                                sx={{
                                  width: "100%",
                                  fontSize: ".875rem",
                                  bgcolor: "#fff",
                                }}
                                minDate={
                                  column.datePickerLimit?.minDate
                                    ? column.datePickerLimit?.minDate
                                    : dayjs("2023-01-01")
                                }
                                onChange={(event) => {
                                  setGetData(true);
                                  if (setPage !== undefined) setPage(0);
                                  filterChange(column.id, event);
                                }}
                              ></DatePicker>
                            </LocalizationProvider>
                          ) : column.filterType === "dropdown" ? (
                            <Select
                              sx={{
                                "& .MuiSelect-select": {
                                  backgroundColor: "#fff",
                                },
                                fontSize: ".875rem",
                              }}
                              onChange={(event) => {
                                setGetData(true);
                                if (setPage !== undefined) setPage(0);
                                filterChange(column.id, event);
                              }}
                              style={{ width: "100%" }}
                              value={Number(filterObject[`${column.id}`])}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                            >
                              <MenuItem
                                disabled
                                value={0}
                                sx={{ fontSize: "14px !important" }}
                              >
                                Select
                              </MenuItem>
                              {column.dropdownData?.map((options) => {
                                const columnName = column.id;

                                return (
                                  <MenuItem
                                    value={options.valueInt}
                                    sx={{ fontSize: "14px !important" }}
                                  >
                                    {(finishedReportsPage ||
                                      generatedLeadsPage ||
                                      achievedSales) &&
                                    (columnName === "rangeResult" ||
                                      columnName === "status" ||
                                      columnName === "leadStatus") ? (
                                      <StatusElement value={options.text} />
                                    ) : (
                                      options.text
                                    )}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          ) : (
                            <TextField
                              value={filterObject[`${column.id}`]}
                              onChange={(event: React.ChangeEvent) =>
                                filterChange(column.id, event)
                              }
                              onKeyDown={(
                                event: KeyboardEvent<HTMLInputElement>
                              ) => {
                                if (
                                  event.key === "Tab" ||
                                  event.key === "Enter"
                                ) {
                                  handleClearClick();
                                } else {
                                  return;
                                }
                              }}
                              sx={{
                                backgroundColor: "#FFF",
                                fontSize: ".875rem",
                              }}
                              // InputProps={{
                              //   endAdornment: (
                              //     <IconButton
                              //       onClick={handleClearClick}
                              //       size="small"
                              //     >
                              //       <ClearIcon />
                              //     </IconButton>
                              //   ),
                              // }}
                            ></TextField>
                          ))}
                      </div>
                    </TableCell>
                  ))}
                  {!actionColumnNotNeeded && (
                    <TableCell
                      className={` ${classes["table-header"]} ${
                        stickyActionColumn ? classes["stickyHeader-right"] : ""
                      }`}
                      style={{
                        maxWidth: actionColumnWidth
                          ? actionColumnWidth
                          : "100px",
                      }}
                    >
                      <span
                        className={`${classes["table-title"]} 
                      ${
                        achievedSales && classes["achieved-sales-action-title"]
                      }`}
                      >
                        Action
                      </span>
                      <div
                        className={showFilters ? classes["action-padding"] : ""}
                      ></div>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length === 0 && (
                  <>
                    {pageName !== undefined &&
                    (pageName === PageConstants.LinkProject ||
                      pageName === PageConstants.AddCustomer ||
                      pageName === PageConstants.AssignEnergySpecialist) ? (
                      <TableRow>
                        <TableCell
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                          colSpan={9}
                        >
                          <div
                            style={{ height: "100%" }}
                            className="flex-column-center"
                          >
                            <img
                              src={
                                notInitialRender
                                  ? noDataFound
                                  : search_customer_graphic
                              }
                              alt="no-data-found"
                              width="170px"
                              height="170px"
                              loading="lazy"
                              decoding="async"
                            />
                            <p style={{ fontSize: "1.5em", fontWeight: "700" }}>
                              {!notInitialRender &&
                                (pageName ===
                                PageConstants.AssignEnergySpecialist
                                  ? "Please search responsible person to view data"
                                  : "Please search customer to view data")}
                            </p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <div className="no-data-found" />
                        <TableCell
                          style={{
                            width: "100%",
                          }}
                          // colSpan={columns.length}
                        />
                      </TableRow>
                    )}
                  </>
                )}
                {data.length > 0 &&
                  data.map((row: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns?.map((column) => {
                          const columnName = column.id;
                          const value = row[columnName];
                          if (pageName === PageConstants.OngoingProject) {
                            if (
                              columnName == "customerName" ||
                              columnName == "responsible"
                            ) {
                              return (
                                <TableCell
                                  className={classes["tabel-cell-border"]}
                                >
                                  {customPageColumnRender(
                                    row,
                                    columnName,
                                    column
                                  )}
                                </TableCell>
                              );
                            }
                          }
                          if (column.id === "rangeResult") {
                            return (
                              <TableCell
                                className={classes["tabel-cell-border"]}
                              >
                                <CustomRangeColumn
                                  CriticalResult={row["criticalResult"]}
                                  mediumResult={row["mediumResult"]}
                                  lowResult={row["lowResult"]}
                                ></CustomRangeColumn>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell className={classes["tabel-cell-border"]}>
                              {columnName === "isActive" ? (
                                <span
                                  style={{ width: column.minWidth }}
                                  className={`${classes["cell-container"]}`}
                                >
                                  <ActiveElement value={value} />
                                </span>
                              ) : (
                                <Tooltip
                                  title={value}
                                  leaveTouchDelay={0}
                                  enterTouchDelay={0}
                                  leaveDelay={0}
                                  placement="right"
                                  slotProps={{
                                    popper: {
                                      modifiers: [
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [0, -25],
                                          },
                                        },
                                      ],
                                    },
                                  }}
                                >
                                  <span
                                    style={{ width: column.minWidth }}
                                    className={`${classes["cell-container"]}`}
                                  >
                                    {(generatedLeadsPage || achievedSales) &&
                                    (columnName === "status" ||
                                      columnName === "leadStatus") ? (
                                      <StatusElement value={value} />
                                    ) : columnName === "isActive" ? (
                                      <ActiveElement value={value} />
                                    ) : column.format &&
                                      typeof value === "number" ? (
                                      column.format(value)
                                    ) : (
                                      value
                                    )}
                                  </span>
                                </Tooltip>
                              )}
                            </TableCell>
                          );
                        })}
                        {!actionColumnNotNeeded && (
                          <TableCell
                            sx={{
                              textAlign:
                                pageName === PageConstants.LinkProject ||
                                pageName === PageConstants.OngoingProject
                                  ? "left"
                                  : "center",
                            }}
                            key={row.id}
                            className={` ${classes["tabel-cell-border"]}  ${
                              stickyActionColumn ? classes["sticky-right"] : ""
                            }`}
                          >
                            {actionButtons?.map((action, index) => {
                              return (
                                <Button
                                  key={index}
                                  disableRipple
                                  disabled={
                                    (action.name === "Delete Project" &&
                                      (row.leadId != null ||
                                        row.projectSource ===
                                          ProjectSourceConstants.LinkProject)) ||
                                    (pageName ===
                                      PageConstants.FinishedReports &&
                                      action.permission !== null &&
                                      !action.permission) ||
                                    (pageName ===
                                      PageConstants.UserManagement &&
                                      action.permission !== null &&
                                      !action.permission) ||
                                    (pageName ===
                                      PageConstants.UserManagement &&
                                      ((getUserId == row.id &&
                                        getUserRole !== Role.GlobalAdmin) ||
                                        (row.role === Role.GlobalAdmin &&
                                          getUserRole !== Role.GlobalAdmin)))
                                  }
                                  sx={{
                                    color: "var(--primary-color)",
                                    paddingLeft: "0",
                                    paddingRight: "0",
                                    minWidth: "30px",
                                    cursor: "pointer",
                                    "&.MuiButtonBase-root:disabled": {
                                      pointerEvents: "all",
                                      cursor: "not-allowed",
                                    },
                                    textTransform: "none",
                                  }}
                                  variant="text"
                                  onClick={(event) =>
                                    action.text === "RecommendationList" ||
                                    action.text === "SendToSmartLink"
                                      ? handleClickEvent(
                                          action.text === undefined
                                            ? ""
                                            : action.text,
                                          row,
                                          event,
                                          action.handleClick
                                        )
                                      : action.text === "editProducts"
                                      ? action.handleClick(
                                          row.auditRecommendationId,
                                          row.actualSavings,
                                          row.auditId,
                                          row.auditNumber
                                        )
                                      : action.handleClick(
                                          row.id,
                                          row.auditId,
                                          event,
                                          row,
                                          typeOfAudit
                                        )
                                  }
                                >
                                  <Tooltip
                                    title={action.name}
                                    placement="top"
                                  >
                                    {action.text === "editProducts" ? (
                                      <Badge
                                        badgeContent={
                                          row.opportunityRecommendationsCount
                                        }
                                        sx={{
                                          "& .MuiBadge-badge": {
                                            bgcolor: "#0099cc",
                                            color: "white",
                                            transform: "translate(30%, -40%)",
                                          },
                                        }}
                                      >
                                        <img
                                          src={action.icon}
                                          height="28"
                                          alt={action.text}
                                          loading="lazy"
                                          decoding="async"
                                        />
                                      </Badge>
                                    ) : (
                                      <img
                                        src={action.icon}
                                        height={
                                          pageName ===
                                            PageConstants.LinkProject ||
                                          pageName ===
                                            PageConstants.AddCustomer ||
                                          pageName ===
                                            PageConstants.AssignEnergySpecialist
                                            ? "auto"
                                            : "23"
                                        }
                                        alt={action.text}
                                        loading="lazy"
                                        decoding="async"
                                      />
                                    )}
                                  </Tooltip>
                                  {pageName === PageConstants.LinkProject ||
                                  pageName === PageConstants.AddCustomer ||
                                  pageName == PageConstants.OngoingProject ||
                                  pageName ===
                                    PageConstants.AssignEnergySpecialist
                                    ? action.textInsideButton
                                    : ""}
                                  {/* {action.text && action.text} */}
                                </Button>
                              );
                            })}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <SendReportToSmartLink
        open={sendToSmartLinkOpen}
        setOpen={setSendToSmartLinkOpen}
      />
      <RecommendationsListDialog
        open={open}
        onClose={handleClose}
        auditId={auditId}
        customerName={customerName}
        auditNumber={auditNumber}
      />
    </>
  );
};

export default CustomTable;
