import { configureStore } from "@reduxjs/toolkit";
import auditRecommendationsDataSlice from "./addRecommendationsDataSlice";
import confirmationBoxDataSlice from "./confirmationBoxDataSlice";
import loaderDataSlice from "./loaderDataSlice";
import fetchingLoaderDataSlice from "./fetchingLoaderDataSlice";

// import dataSlice from "./country-slice/data-slice";
import uploadFinishedReportsDataSlice from "./uploadFinishedReportsDataSlice";
import releaseNoteDataSlice from "./releaseNoteNotification";

const store = configureStore({
  reducer: {
    uploadFinishedReportsReducer: uploadFinishedReportsDataSlice.reducer,
    auditRecommendationsReducer: auditRecommendationsDataSlice.reducer,
    loaderReducer: loaderDataSlice.reducer,
    confirmationBoxReducer: confirmationBoxDataSlice.reducer,
    fetchingLoaderReducer:fetchingLoaderDataSlice.reducer,
    releaseNoteReducer:releaseNoteDataSlice.reducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
