import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import CustomTable from "../../common/components/CustomComponents/CustomTable";
import {
  DatePickerMode,
  TableColumn,
} from "../../common/interface/TableColumn";
import classes from "./GeneratedLeads.module.css";
import DropdownModel from "../../common/interface/DropdownModel";

import {
  RecommendationDropdownModel,
  RecommendationsSelectList,
} from "../AddRecommendations";
import { RequestApi } from "../../common/Constant";
import { useRequest } from "../../services/agent";
import dayjs from "dayjs";

import { ShowAuditTypesOnTabConstants } from "../../common/AuditTypeConstants";
import { tab_Style } from "../../common/TabStyleConstants";

import AirNetLeads from "./AirNetLeads";
import EnergyRecoveryLeads from "./EnergyRecovery";

function a11yProps(index: number) {
  return {
    id: `audit-tab-${index}`,
    "aria-controls": `audit-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`audit-tabpanel-${index}`}
      aria-labelledby={`audit-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: 0, borderRadius: "8px", background: "#fff" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface IAirAuditData {
  id: number;
  customerName: string;
  auditId: number;
  status: "sold" | "opportunity";
  leadId: number;
  typeOfInvestment: string;
  typeOfRecommendation: string;
  approximateInvestment: number | "NA";
  minimumEstimatedSavings: number | "Brazil";
  followUpDate: string;
  leadCreatedDate: string;
  leadResponsiblePerson: string;
}

interface IVibrationAuditData extends IAirAuditData {
  machineSerialNumber: number;
  model: string;
  brand: string;
}

export const dropDownData: DropdownModel[] = [
  {
    text: "Accepted",
    value: "Accepted",
    valueInt: 1,
  },
  {
    text: "Qualified",
    value: "Qualified",
    valueInt: 2,
  },
  {
    text: "Converted",
    value: "Converted",
    valueInt: 3,
  },
  {
    text: "Declined",
    value: "Declined",
    valueInt: 4,
  },
  {
    text: "Open",
    value: "Open",
    valueInt: 5,
  },
  {
    text: "Deleted",
    value: "Deleted",
    valueInt: 6,
  },
];

const GeneratedLeads = () => {
  const [value, setValue] = useState(0);
  const request = useRequest();
  const [selectList, setSelectList] = useState<RecommendationsSelectList>();
  const [pageSpecificData, setPageSpecificData] = useState<{
    leadStausUpdatedOn: "string";
  }>();

  //air audit states
  const [airAuditPage, setAirAuditPage] = useState(0);
  const [airAuditData, setAirAuditData] = useState<Array<IAirAuditData>>([]);
  const [airAuditTotalRecords, setAirAuditTotalRecords] = useState<number>(0);
  const [airAuditFilterLeadId, setAirAuditFilterLeadId] = useState<
    number | null
  >(null);
  const [airAuditFilterAuditId, setAirAuditAuditId] = useState<string>("");
  const [airAuditFilterTypeOfInvestment, setAirAuditFilterTypeOfInvestment] =
    useState<string>("");
  const [
    airAuditFilterTypeOfRecommendation,
    setAirAuditFilterTypeOfRecommendation,
  ] = useState<string>("");
  const [airAuditFilterStatus, setAirAuditFilterStatus] = useState<number>(0);
  const [airAuditFilterCustomerName, setAirAuditFilterCustomerName] =
    useState<string>("");
  const [airAuditFilterUploadReportBy, setAirAuditFilterUploadReportBy] =
    useState<string>("");
  const [airAuditFilterApproxInvestment, setAirAuditFilterApproxInvestment] =
    useState<string>("");
  const [
    airAuditFilterMaxEstimatedSavings,
    setAirAuditFilterMaxEstimatedSavings,
  ] = useState<string>("");
  const [
    airAuditFilterApproximateInvestment,
    setAirAuditFilterApproximateInvestment,
  ] = useState<number | undefined>();
  const [
    airAuditFilterMinimumEstimatedSavings,
    setAirAuditFilterMinimumEstimatedSavings,
  ] = useState<number | undefined>();
  const [airAuditFilterFollowUpDate, setAirAuditFilterFollowUpDate] =
    useState<string>("");
  const [airFilterLocalDate, setAirFilterLocalDate] = useState<string>("");
  const [airAuditSortingColumn, setAirAuditSortingColumn] =
    useState<string>("");
  const [airAuditSortingOrder, setAirAuditSortingOrder] = useState<string>("");
  const [airAuditFilterProjectSource, setAirAuditFilterProjectSource] =
    useState<string>("");
  const [airAuditFilterLeadCreateDate, setAirAuditFilterLeadCreateDate] =
    useState("");
  const [airAuditFilterLeadResponsible, setAirAuditFilterLeadResponsible] =
    useState("");
  const [
    airAuditFilterLocalLeadCreateDate,
    setAirAuditFilterLocalLeadCreateDate,
  ] = useState<string>("");

  const [getAirData, setGetAirData] = useState(false);

  const airAuditfilterObject: any = useMemo(
    () => ({
      auditNumber: airAuditFilterAuditId,
      leadId: airAuditFilterLeadId ? airAuditFilterLeadId : "",
      customerName: airAuditFilterCustomerName,
      leadStatus: airAuditFilterStatus,
      typeOfInvestment: airAuditFilterTypeOfInvestment,
      typeOfRecommendation: airAuditFilterTypeOfRecommendation,
      approximateInvestment: airAuditFilterApproxInvestment,
      minimumEstimatedSavings: airAuditFilterMaxEstimatedSavings,
      uploadReportBy: airAuditFilterUploadReportBy,
      followUpDate: dayjs(airFilterLocalDate).isValid()
        ? dayjs(airFilterLocalDate)
        : "",
      projectSource: airAuditFilterProjectSource,
      leadCreatedDate: dayjs(airAuditFilterLocalLeadCreateDate).isValid()
        ? dayjs(airAuditFilterLocalLeadCreateDate)
        : "",
      leadResponsiblePerson: airAuditFilterLeadResponsible,
    }),

    [
      airAuditFilterApproxInvestment,
      airAuditFilterAuditId,
      airAuditFilterCustomerName,
      airAuditFilterUploadReportBy,
      airAuditFilterTypeOfInvestment,
      airFilterLocalDate,
      airAuditFilterLeadId,
      airAuditFilterMaxEstimatedSavings,
      airAuditFilterStatus,
      airAuditFilterTypeOfRecommendation,
      airAuditFilterProjectSource,
      airAuditFilterLocalLeadCreateDate,
      airAuditFilterLeadResponsible,
    ]
  );
  // air audit variables end

  //airnet state start

  const [airNetAuditFilterLeadId, setAirNetAuditFilterLeadId] = useState<
    number | null
  >(null);
  const [airNetAuditFilterAuditId, setAirNetAuditAuditId] =
    useState<string>("");

  const [airNetAuditFilterStatus, setAirNetAuditFilterStatus] =
    useState<number>(0);
  const [airNetAuditFilterCustomerName, setAirNetAuditFilterCustomerName] =
    useState<string>("");
  const [airNetAuditFilterUploadReportBy, setAirNetAuditFilterUploadReportBy] =
    useState<string>("");

  const [airNetAuditSortingColumn, setAirNetAuditSortingColumn] =
    useState<string>("");
  const [airNetAuditSortingOrder, setAirNetAuditSortingOrder] =
    useState<string>("");
  const [airNetAuditPage, setAirNetAuditPage] = useState(0);

  const [airNetAuditFilterLeadCreateDate, setAirNetAuditFilterLeadCreateDate] =
    useState("");
  const [
    airNetAuditFilterLeadResponsible,
    setAirNetAuditFilterLeadResponsible,
  ] = useState("");
  const [
    airNetAuditFilterLocalLeadCreateDate,
    setAirNetAuditFilterLocalLeadCreateDate,
  ] = useState<string>("");

  const airNetAuditFilters = {
    airNetAuditFilterLeadId,
    setAirNetAuditFilterLeadId,
    airNetAuditFilterAuditId,
    setAirNetAuditAuditId,
    airNetAuditFilterStatus,
    setAirNetAuditFilterStatus,
    airNetAuditFilterCustomerName,
    setAirNetAuditFilterCustomerName,
    airNetAuditFilterUploadReportBy,
    setAirNetAuditFilterUploadReportBy,
    airNetAuditPage,
    setAirNetAuditPage,
    airNetAuditFilterLeadCreateDate,
    setAirNetAuditFilterLeadCreateDate,
    airNetAuditFilterLeadResponsible,
    setAirNetAuditFilterLeadResponsible,
    airNetAuditFilterLocalLeadCreateDate,
    setAirNetAuditFilterLocalLeadCreateDate,
  };

  const airNetSorting = {
    airNetAuditSortingColumn,
    setAirNetAuditSortingColumn,
    airNetAuditSortingOrder,
    setAirNetAuditSortingOrder,
  };

  //airnet state end

  //Energy Recovery start

  const [energyRecoveryAuditFilterLeadId, setEnergyRecoveryAuditFilterLeadId] =
    useState<number | null>(null);
  const [energyRecoveryAuditFilterAuditId, setEnergyRecoveryAuditAuditId] =
    useState<string>("");

  const [energyRecoveryAuditFilterStatus, setEnergyRecoveryAuditFilterStatus] =
    useState<number>(0);
  const [
    energyRecoveryAuditFilterCustomerName,
    setEnergyRecoveryAuditFilterCustomerName,
  ] = useState<string>("");
  const [
    energyRecoveryAuditFilterUploadReportBy,
    setEnergyRecoveryAuditFilterUploadReportBy,
  ] = useState<string>("");

  const [
    energyRecoveryAuditSortingColumn,
    setEnergyRecoveryAuditSortingColumn,
  ] = useState<string>("");
  const [energyRecoveryAuditSortingOrder, setEnergyRecoveryAuditSortingOrder] =
    useState<string>("");
  const [energyRecoveryAuditPage, setEnergyRecoveryAuditPage] = useState(0);
  const [
    energyRecoveryAuditFilterLeadCreateDate,
    setEnergyRecoveryAuditFilterLeadCreateDate,
  ] = useState("");
  const [
    energyRecoveryAuditFilterLeadResponsible,
    setEnergyRecoveryAuditFilterLeadResponsible,
  ] = useState("");
  const [
    energyRecoveryAuditFilterLocalLeadCreateDate,
    setEnergyRecoveryAuditFilterLocalLeadCreateDate,
  ] = useState<string>("");

  const energyRecoveryAuditFilters = {
    energyRecoveryAuditFilterLeadId,
    setEnergyRecoveryAuditFilterLeadId,
    energyRecoveryAuditFilterAuditId,
    setEnergyRecoveryAuditAuditId,
    energyRecoveryAuditFilterStatus,
    setEnergyRecoveryAuditFilterStatus,
    energyRecoveryAuditFilterCustomerName,
    setEnergyRecoveryAuditFilterCustomerName,
    energyRecoveryAuditFilterUploadReportBy,
    setEnergyRecoveryAuditFilterUploadReportBy,
    energyRecoveryAuditPage,
    setEnergyRecoveryAuditPage,
    energyRecoveryAuditFilterLeadCreateDate,
    setEnergyRecoveryAuditFilterLeadCreateDate,
    energyRecoveryAuditFilterLeadResponsible,
    setEnergyRecoveryAuditFilterLeadResponsible,
    energyRecoveryAuditFilterLocalLeadCreateDate,
    setEnergyRecoveryAuditFilterLocalLeadCreateDate,
  };

  const energyRecoverySorting = {
    energyRecoveryAuditSortingColumn,
    setEnergyRecoveryAuditSortingColumn,
    energyRecoveryAuditSortingOrder,
    setEnergyRecoveryAuditSortingOrder,
  };

  //Energy Recovery state end

  //vibration audit starts
  const [vibrationAuditPage, setVibrationAuditPage] = useState(0);
  const [vibrationAuditData, setVibrationAuditData] = useState<
    Array<IVibrationAuditData>
  >([]);
  const [vibrationAuditTotalRecords, setvibrationAuditTotalRecords] =
    useState<number>(0);
  const [vibrationAuditFilterLeadId, setVibrationAuditFilterLeadId] = useState<
    number | null
  >(null);
  const [vibrationAuditFilterAuditId, setVibrationAuditAuditId] =
    useState<string>("");
  const [
    vibrationAuditFilterTypeOfInvestment,
    setVibrationAuditFilterTypeOfInvestment,
  ] = useState<string>("");
  const [
    vibrationAuditFilterTypeOfRecommendation,
    setVibrationAuditFilterTypeOfRecommendation,
  ] = useState<string>("");
  const [vibrationAuditFilterStatus, setVibrationAuditFilterStatus] =
    useState<number>(0);
  const [
    vibrationAuditFilterApproximateInvestment,
    setVibrationAuditFilterApproximateInvestment,
  ] = useState<number | undefined>();
  const [
    vibrationAuditFilterMinimumEstimatedSavings,
    setVibrationAuditFilterMinimumEstimatedSavings,
  ] = useState<number | undefined>();

  const [
    vibrationAuditFilterApproxInvestment,
    setVibrationAuditFilterApproxInvestment,
  ] = useState<string>("");
  const [
    vibrationAuditFilterMaxEstimatedSavings,
    setVibrationAuditFilterMaxEstimatedSavings,
  ] = useState<string>("");

  const [
    vibrationAuditFilterMachineSerialNumber,
    setVibrationAuditFilterMachineSerialNumber,
  ] = useState<string>("");
  const [vibrationAuditFilterModel, setVibrationAuditFilterModel] =
    useState<string>("");
  const [vibrationAuditFilterBrand, setVibrationAuditFilterBrand] =
    useState<string>("");
  const [
    vibrationAuditFilterCustomerName,
    setVibrationAuditFilterCustomerName,
  ] = useState<string>("");
  const [
    vibrationAuditFilterUploadReportBy,
    setVibrationAuditFilterUploadReportBy,
  ] = useState<string>("");

  const [
    vibrationAuditFilterFollowUpDate,
    setVibrationAuditFilterFollowUpDate,
  ] = useState<string>("");
  const [vibrationFilterLocalDate, setVibrationFilterLocalDate] =
    useState<string>("");
  const [vibrationAuditSortingColumn, setVibrationAuditSortingColumn] =
    useState<string>("");
  const [vibrationAuditSortingOrder, setVibrationAuditSortingOrder] =
    useState<string>("");
  const [getVibrationData, setGetVibrationData] = useState(false);
  const [
    vibrationAuditFilterLeadCreateDate,
    setVibrationAuditFilterLeadCreateDate,
  ] = useState("");
  const [
    vibrationAuditFilterLeadResponsible,
    setVibrationAuditFilterLeadResponsible,
  ] = useState("");
  const [
    vibrationAuditFilterLocalLeadCreateDate,
    setVibrationAuditFilterLocalLeadCreateDate,
  ] = useState<string>("");

  const vibrationAuditfilterObject: any = useMemo(
    () => ({
      auditNumber: vibrationAuditFilterAuditId,
      leadId: vibrationAuditFilterLeadId ? vibrationAuditFilterLeadId : "",
      leadStatus: vibrationAuditFilterStatus,
      customerName: vibrationAuditFilterCustomerName,
      uploadReportBy: vibrationAuditFilterUploadReportBy,
      typeOfInvestment: vibrationAuditFilterTypeOfInvestment,
      typeOfRecommendation: vibrationAuditFilterTypeOfRecommendation,
      approximateInvestment: vibrationAuditFilterApproxInvestment,
      minimumEstimatedSavings: vibrationAuditFilterMaxEstimatedSavings,
      model: vibrationAuditFilterModel,
      brand: vibrationAuditFilterBrand,
      machineSerialNumber: vibrationAuditFilterMachineSerialNumber,
      followUpDate: dayjs(vibrationFilterLocalDate).isValid()
        ? dayjs(vibrationFilterLocalDate)
        : "",
      leadCreatedDate: dayjs(vibrationAuditFilterLocalLeadCreateDate).isValid()
        ? dayjs(vibrationAuditFilterLocalLeadCreateDate)
        : "",
      leadResponsiblePerson: vibrationAuditFilterLeadResponsible,
    }),

    [
      vibrationAuditFilterAuditId,
      vibrationAuditFilterLeadId,
      vibrationAuditFilterStatus,
      vibrationAuditFilterCustomerName,
      vibrationAuditFilterUploadReportBy,
      vibrationAuditFilterTypeOfInvestment,
      vibrationAuditFilterTypeOfRecommendation,
      vibrationAuditFilterApproxInvestment,
      vibrationAuditFilterMaxEstimatedSavings,
      vibrationAuditFilterModel,
      vibrationAuditFilterBrand,
      vibrationAuditFilterMachineSerialNumber,
      vibrationFilterLocalDate,
      vibrationAuditFilterLeadResponsible,
      vibrationAuditFilterLocalLeadCreateDate,
    ]
  );

  const [investmentId, setInvestmentId] = useState<number | undefined>();
  const [recommendationId, setRecommendationId] = useState<
    number | undefined
  >();

  function getSelectedRecommendations() {
    const selectedRecommendations = selectList?.typeOfRecommendation.filter(
      (recommendation) => recommendation.investmentId === investmentId
    );

    return selectedRecommendations;
  }

  const columns: TableColumn[] = [
    {
      id: "auditNumber",
      label: "Project ID",
      align: "left",
      minWidth: 160,
      filter: true,
      filterType: "text",
      sorting: true,
    },

    {
      id: "leadStatus",
      label: "Status",
      align: "left",
      minWidth: 140,
      filter: true,
      filterType: "dropdown",
      dropdownData: dropDownData,
    },
    {
      id: "leadId",
      label: "Lead ID",
      minWidth: 120,
      align: "left",
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "customerName",
      label: "Customer Name",
      minWidth: 300,
      align: "left",
      filter: true,
      sorting: true,
    },
    {
      id: "typeOfInvestment",
      label: "Category of Product",
      minWidth: 180,
      align: "left",
      filter: true,
      filterType: "dropdown",
      dropdownData: selectList
        ? (selectList.typeOfInvestment as DropdownModel[])
        : ([] as DropdownModel[]),
    },
    {
      id: "typeOfRecommendation",
      label: "Type of Product",
      minWidth: 195,
      align: "left",
      filter: true,
      filterType: "dropdown",
      dropdownData: selectList
        ? (getSelectedRecommendations() as RecommendationDropdownModel[])
        : ([] as RecommendationDropdownModel[]),
    },
    {
      id: "approximateInvestment",
      label: "Approx. Investment",
      minWidth: 170,
      align: "left",
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "minimumEstimatedSavings",
      label: "Minimum Estimated Savings (kWh/annum)",
      minWidth: 170,
      align: "left",
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "machineSerialNumber",
      label: "Serial Number",
      minWidth: 140,
      align: "left",
      filter: true,
      filterType: "text",
    },
    {
      id: "model",
      label: "Machine Model",
      minWidth: 100,
      align: "left",
      filter: true,
      filterType: "text",
    },
    {
      id: "brand",
      label: "Brand",
      minWidth: 130,
      align: "left",
      filter: true,
      filterType: "text",
    },
    {
      id: "uploadReportBy",
      label: "Uploaded Project by",
      minWidth: 120,
      align: "left",
      filter: true,
      filterType: "text",
      sorting: true,
    },

    {
      id: "leadCreatedDate",
      label: "Lead Created Date",
      minWidth: 120,
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
      filter: true,
      filterType: "datepicker",
      sorting: true,
      datePickerMode: DatePickerMode.MonthYear,
      datePickerLimit: { minDate: dayjs("2014-01-01") },
    },
    {
      id: "leadResponsiblePerson",
      label: "C4C Owner",
      minWidth: 140,
      align: "left",
      format: (value: number) => value.toFixed(2),
      filter: true,
      sorting: true,
    },

    //  {
    //    id: "followUpDate",
    //    label: "Follow up date",
    //    minWidth: 155,
    //    align: "left",
    //    filter: true,
    //    filterType: "datepicker",
    //  },
  ];

  useEffect(() => {
    request(RequestApi.GET_RECOMMENDATIONS_SELECT_LIST)
      .then((response) => setSelectList(response.data))
      .catch((error) => console.error(error));
  }, []);

  // const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  const handleAirFilterChange = (id: any, event: any) => {
    //const numericValue = event.target.value === '' ? undefined : Number(event.target.value);
    // debugger;
    switch (id) {
      case "typeOfInvestment":
        setAirAuditFilterTypeOfInvestment(event.target.value);
        setInvestmentId(event.target.value);
        break;
      case "typeOfRecommendation":
        setAirAuditFilterTypeOfRecommendation(event.target.value);
        setRecommendationId(event.target.value);
        break;
      case "auditNumber":
        setAirAuditAuditId(event.target.value);
        break;
      case "leadId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setAirAuditFilterLeadId(null);
        } else {
          setAirAuditFilterLeadId(event.target.value);
        }
        break;
      case "customerName":
        setAirAuditFilterCustomerName(event.target.value);
        break;
      case "approximateInvestment":
        setAirAuditFilterApproxInvestment(event.target.value);
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setAirAuditFilterApproxInvestment("");
          setAirAuditFilterApproximateInvestment(undefined);
        } else {
          setAirAuditFilterApproxInvestment(event.target.value);
          setAirAuditFilterApproximateInvestment(event.target.value);
        }
        break;
      case "minimumEstimatedSavings":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setAirAuditFilterMinimumEstimatedSavings(undefined);
          setAirAuditFilterMaxEstimatedSavings("");
        } else {
          setAirAuditFilterMinimumEstimatedSavings(event.target.value);
          setAirAuditFilterMaxEstimatedSavings(event.target.value);
        }
        break;
      case "leadStatus":
        setAirAuditFilterStatus(event.target.value);
        break;
      case "uploadReportBy":
        setAirAuditFilterUploadReportBy(event.target.value);
        break;
      case "followUpDate":
        if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
          setAirAuditFilterFollowUpDate(dayjs(event.$d).format("DD/MM/YYYY"));
          setAirFilterLocalDate(event.$d);
        } else {
          setAirAuditFilterFollowUpDate("");
          setAirFilterLocalDate("");
        }
        break;
      case "projectSource":
        setAirAuditFilterProjectSource(event.target.value);
        break;
      case "leadCreatedDate":
        if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
          setAirAuditFilterLeadCreateDate(dayjs(event.$d).format("DD/MM/YYYY"));
          setAirAuditFilterLocalLeadCreateDate(event.$d);
        } else {
          setAirAuditFilterLeadCreateDate("");
          setAirAuditFilterLocalLeadCreateDate("");
        }
        break;
      case "leadResponsiblePerson":
        setAirAuditFilterLeadResponsible(event.target.value);
        break;
    }
  };
  const clearAllAirAuditSorting = useCallback(() => {
    setAirAuditPage(0);
    setAirAuditFilterStatus(0);
    setAirAuditFilterLocalLeadCreateDate("");
    setAirAuditFilterLeadResponsible("");
    setAirAuditFilterLocalLeadCreateDate("");
    setAirAuditFilterLeadCreateDate("");
    setAirAuditSortingColumn("");
    setAirAuditSortingOrder("");
    setAirAuditAuditId("");
    setAirAuditFilterLeadId(null);
    setAirAuditFilterTypeOfInvestment("");
    setAirAuditFilterTypeOfRecommendation("");
    setAirAuditFilterCustomerName("");
    setAirAuditFilterUploadReportBy("");
    setInvestmentId(0);
    setRecommendationId(0);
    setAirAuditFilterApproxInvestment("");
    setAirAuditFilterMaxEstimatedSavings("");
    setAirAuditFilterApproximateInvestment(undefined);
    setAirAuditFilterMinimumEstimatedSavings(undefined);
    setAirFilterLocalDate("");
    setAirAuditFilterFollowUpDate("");
    setAirAuditFilterProjectSource("");
    setAirAuditFilterLeadId(null);
    setGetAirData(true);
  }, []);

  const handleVibrationFilterChange = (id: any, event: any) => {
    switch (id) {
      case "typeOfInvestment":
        setVibrationAuditFilterTypeOfInvestment(event.target.value);
        setInvestmentId(event.target.value);
        break;
      case "typeOfRecommendation":
        setVibrationAuditFilterTypeOfRecommendation(event.target.value);
        setRecommendationId(event.target.value);
        break;
      case "auditNumber":
        setVibrationAuditAuditId(event.target.value);
        break;
      case "leadId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setVibrationAuditFilterLeadId(null);
        } else {
          setVibrationAuditFilterLeadId(event.target.value);
        }
        break;
      case "customerName":
        setVibrationAuditFilterCustomerName(event.target.value);
        break;
      case "approximateInvestment":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setVibrationAuditFilterApproxInvestment("");
          setVibrationAuditFilterApproximateInvestment(undefined);
        } else {
          setVibrationAuditFilterApproxInvestment(event.target.value);
          setVibrationAuditFilterApproximateInvestment(event.target.value);
        }
        break;
      case "minimumEstimatedSavings":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setVibrationAuditFilterMaxEstimatedSavings("");
          setVibrationAuditFilterMinimumEstimatedSavings(undefined);
        } else {
          setVibrationAuditFilterMinimumEstimatedSavings(event.target.value);
          setVibrationAuditFilterMaxEstimatedSavings(event.target.value);
        }
        break;
      case "machineSerialNumber":
        setVibrationAuditFilterMachineSerialNumber(event.target.value);
        break;
      case "model":
        setVibrationAuditFilterModel(event.target.value);
        break;
      case "brand":
        setVibrationAuditFilterBrand(event.target.value);
        break;
      case "leadStatus":
        setVibrationAuditFilterStatus(event.target.value);
        break;
      case "uploadReportBy":
        setVibrationAuditFilterUploadReportBy(event.target.value);
        break;
      case "followUpDate":
        if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
          setVibrationAuditFilterFollowUpDate(
            dayjs(event.$d).format("DD/MM/YYYY")
          );
          setVibrationAuditFilterLocalLeadCreateDate(event.$d);
        } else {
          setVibrationAuditFilterFollowUpDate("");
          setVibrationAuditFilterLocalLeadCreateDate("");
        }
        break;
      case "leadCreatedDate":
        if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
          setVibrationAuditFilterLeadCreateDate(
            dayjs(event.$d).format("DD/MM/YYYY")
          );
          setVibrationFilterLocalDate(event.$d);
        } else {
          setVibrationAuditFilterLeadCreateDate("");
          setVibrationFilterLocalDate("");
        }
        break;
      case "leadResponsiblePerson":
        console.log(event.target.value);
        setVibrationAuditFilterLeadResponsible(event.target.value);
        break;
    }
  };

  const clearAllVibrationSorting = useCallback(() => {
    setVibrationAuditPage(0);
    setVibrationAuditFilterStatus(0);
    setVibrationAuditFilterLocalLeadCreateDate("");
    setVibrationAuditFilterLeadResponsible("");
    setVibrationAuditFilterLeadCreateDate("");
    setVibrationAuditSortingColumn("");
    setVibrationAuditSortingOrder("");
    setVibrationAuditAuditId("");
    setVibrationAuditFilterTypeOfInvestment("");
    setVibrationAuditFilterTypeOfRecommendation("");
    setVibrationAuditFilterCustomerName("");
    setVibrationAuditFilterUploadReportBy("");
    setInvestmentId(0);
    setRecommendationId(0);
    setVibrationAuditFilterApproxInvestment("");
    setVibrationAuditFilterMaxEstimatedSavings("");
    setVibrationAuditFilterApproximateInvestment(undefined);
    setVibrationAuditFilterMinimumEstimatedSavings(undefined);
    setVibrationAuditFilterMachineSerialNumber("");
    setVibrationAuditFilterModel("");
    setVibrationAuditFilterBrand("");
    setVibrationFilterLocalDate("");
    setVibrationAuditFilterFollowUpDate("");
    setGetVibrationData(true);
    setVibrationAuditFilterLeadId(null);
  }, []);
  console.log(vibrationAuditFilterLeadResponsible);
  const loadVibrationAuditData = useCallback((): void => {
    if (value === 1) {
      let body = {
        page: vibrationAuditPage,
        pageSize: 20,
        sort: vibrationAuditSortingColumn,
        order: vibrationAuditSortingOrder,
        auditId: vibrationAuditFilterAuditId ? vibrationAuditFilterAuditId : "",
        leadStatus: vibrationAuditFilterStatus
          ? dropDownData.filter(
              (data) => data.valueInt === vibrationAuditFilterStatus
            )[0].value
          : "",
        leadId: vibrationAuditFilterLeadId ? vibrationAuditFilterLeadId : 0,
        customerName: vibrationAuditFilterCustomerName
          ? vibrationAuditFilterCustomerName
          : "",
        uploadReportBy: vibrationAuditFilterUploadReportBy
          ? vibrationAuditFilterUploadReportBy
          : "",
        investmentId: Number(vibrationAuditFilterTypeOfInvestment)
          ? Number(vibrationAuditFilterTypeOfInvestment)
          : 0,
        recommendationId: Number(vibrationAuditFilterTypeOfRecommendation)
          ? Number(vibrationAuditFilterTypeOfRecommendation)
          : 0,
        aproxInvestment: vibrationAuditFilterApproximateInvestment
          ? vibrationAuditFilterApproximateInvestment
          : null,
        minimumEstimatedSaving: vibrationAuditFilterMinimumEstimatedSavings
          ? vibrationAuditFilterMinimumEstimatedSavings
          : null,
        machineSerialNumber: vibrationAuditFilterMachineSerialNumber
          ? vibrationAuditFilterMachineSerialNumber
          : "",
        brand: vibrationAuditFilterBrand ? vibrationAuditFilterBrand : "",
        model: vibrationAuditFilterModel ? vibrationAuditFilterModel : "",
        followUpDate: vibrationAuditFilterFollowUpDate
          ? vibrationAuditFilterFollowUpDate === "clear"
            ? ""
            : vibrationAuditFilterFollowUpDate
          : "",
        leadCreatedDate: vibrationAuditFilterLeadCreateDate
          ? vibrationAuditFilterLeadCreateDate === "clear"
            ? ""
            : vibrationAuditFilterLeadCreateDate
          : "",
        leadResponsiblePerson: vibrationAuditFilterLeadResponsible
          ? vibrationAuditFilterLeadResponsible
          : "",
      };

      request({
        url: RequestApi.GET_VIBRATION_GENERATED_LEADS,
        method: "post",
        data: body,
      })
        .then((resp) => {
          setVibrationAuditData(resp.data.data);
          setPageSpecificData({
            leadStausUpdatedOn: resp.data.leadStausUpdatedOn,
          });
          setvibrationAuditTotalRecords(resp.data.totalRecord);
        })
        .catch((err) => {
          console.log(err);
          setAlert("error", "Request Failed", true);
        });
    }
  }, [
    value,
    vibrationAuditPage,
    vibrationAuditSortingColumn,
    vibrationAuditSortingOrder,
    vibrationAuditFilterAuditId,
    vibrationAuditFilterStatus,
    vibrationAuditFilterLeadId,
    vibrationAuditFilterCustomerName,
    vibrationAuditFilterUploadReportBy,
    vibrationAuditFilterTypeOfInvestment,
    vibrationAuditFilterTypeOfRecommendation,
    vibrationAuditFilterApproximateInvestment,
    vibrationAuditFilterMinimumEstimatedSavings,
    vibrationAuditFilterMachineSerialNumber,
    vibrationAuditFilterBrand,
    vibrationAuditFilterModel,
    vibrationAuditFilterFollowUpDate,
    vibrationAuditFilterLeadResponsible,
    vibrationAuditFilterLeadCreateDate,
    request,
  ]);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    // handleCloseDialog();
  }

  const loadAirAuditData = useCallback((): void => {
    if (value === 0) {
      let body = {
        page: airAuditPage,
        pageSize: 20,
        sort: airAuditSortingColumn,
        order: airAuditSortingOrder,
        auditId: airAuditFilterAuditId ? airAuditFilterAuditId : "",
        leadStatus: airAuditFilterStatus
          ? dropDownData.filter(
              (data) => data.valueInt === airAuditFilterStatus
            )[0].value
          : "",
        leadId: airAuditFilterLeadId ? airAuditFilterLeadId : 0,
        customerName: airAuditFilterCustomerName
          ? airAuditFilterCustomerName
          : "",
        investmentId: Number(airAuditFilterTypeOfInvestment)
          ? Number(airAuditFilterTypeOfInvestment)
          : 0,
        recommendationId: Number(airAuditFilterTypeOfRecommendation)
          ? Number(airAuditFilterTypeOfRecommendation)
          : 0,
        aproxInvestment: airAuditFilterApproximateInvestment
          ? airAuditFilterApproximateInvestment
          : null,
        minimumEstimatedSaving: airAuditFilterMinimumEstimatedSavings
          ? airAuditFilterMinimumEstimatedSavings
          : null,
        uploadReportBy: airAuditFilterUploadReportBy
          ? airAuditFilterUploadReportBy
          : "",
        followUpDate: airAuditFilterFollowUpDate
          ? airAuditFilterFollowUpDate === "clear"
            ? ""
            : airAuditFilterFollowUpDate
          : "",
        projectSource: airAuditFilterProjectSource
          ? airAuditFilterProjectSource
          : "",
        leadCreatedDate: airAuditFilterLeadCreateDate
          ? airAuditFilterLeadCreateDate === "clear"
            ? ""
            : airAuditFilterLeadCreateDate
          : "",
        leadResponsiblePerson: airAuditFilterLeadResponsible
          ? airAuditFilterLeadResponsible
          : "",
      };

      request({
        url: RequestApi.GET_AIR_AUDIT_GENERATED_LEADS,
        method: "post",
        data: body,
      })
        .then((resp) => {
          setAirAuditData(resp.data.data);

          setPageSpecificData({
            leadStausUpdatedOn: resp.data.leadStausUpdatedOn,
          });
          setAirAuditTotalRecords(resp.data.totalRecord);
        })
        .catch((err) => {
          if (err.code === "ERR_BAD_RESPONSE") {
            console.log(err);
            setAlert("error", "Request Failed", true);
          } else {
            console.log(err);
            setAlert("error", "Request Failed", true);
          }
        });
    }
  }, [
    value,
    airAuditPage,
    airAuditSortingColumn,
    airAuditSortingOrder,
    airAuditFilterAuditId,
    airAuditFilterStatus,
    airAuditFilterLeadId,
    airAuditFilterCustomerName,
    airAuditFilterUploadReportBy,
    airAuditFilterTypeOfInvestment,
    airAuditFilterTypeOfRecommendation,
    airAuditFilterApproximateInvestment,
    airAuditFilterMinimumEstimatedSavings,
    airAuditFilterFollowUpDate,
    airAuditFilterProjectSource,
    airAuditFilterLeadCreateDate,
    airAuditFilterLeadResponsible,
    request,
  ]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
      setGetAirData(true);
    } else if (newValue === 1) {
      setGetVibrationData(true);
    }
  };

  useEffect(() => {
    setGetAirData(true);
  }, []);

  useEffect(() => {
    if (value === 0) {
      if (getAirData) {
        loadAirAuditData();
      }
    } else if (value === 1) {
      if (getVibrationData) {
        loadVibrationAuditData();
      }
    }

    return () => {
      setGetAirData(false);
      setGetVibrationData(false);
    };
  }, [
    value,
    loadAirAuditData,
    getAirData,
    getVibrationData,
    loadVibrationAuditData,
  ]);

  const airAuditColumns: TableColumn[] = [
    ...columns.filter(
      (column) =>
        column.id !== "machineSerialNumber" &&
        column.id !== "model" &&
        column.id !== "brand"
    ),
  ];

  const projectSource: TableColumn = {
    id: "projectSource",
    label: "Source",
    align: "left",
    minWidth: 150,
    filter: true,
    filterType: "text",
    sorting: true,
  };

  airAuditColumns.splice(1, 0, projectSource);

  return (
    <>
      <Tabs
        sx={tab_Style}
        aria-label="audit tab"
        value={value}
        onChange={handleTabChange}
      >
        <Tab
          sx={{ textTransform: "none" }}
          label={ShowAuditTypesOnTabConstants.Air_Scan}
          {...a11yProps(0)}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label={ShowAuditTypesOnTabConstants.Vibration_Audit}
          {...a11yProps(1)}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label={ShowAuditTypesOnTabConstants.Airnet}
          {...a11yProps(2)}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label={ShowAuditTypesOnTabConstants.Enery_Recovery}
          {...a11yProps(3)}
        />
      </Tabs>
      <Grid
        md={12}
        style={{ height: "13px", backgroundColor: "#F3F3F3" }}
      ></Grid>

      <CustomTabPanel value={value} index={0}>
        <CustomTable
          isInsideTab={true}
          data={airAuditData}
          actionColumnNotNeeded={true}
          generatedLeadsPage={true}
          filterChange={handleAirFilterChange}
          columns={airAuditColumns}
          setPage={setAirAuditPage}
          clearAll={clearAllAirAuditSorting}
          page={airAuditPage}
          loadDataFunction={loadAirAuditData}
          totalRecords={airAuditTotalRecords}
          filterObject={airAuditfilterObject}
          sortColumn={airAuditSortingColumn}
          setSortColumn={setAirAuditSortingColumn}
          sortColumnOrder={airAuditSortingOrder}
          setSortColumnOrder={setAirAuditSortingOrder}
          setGetData={setGetAirData}
          pageSpecificData={pageSpecificData}
        />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <CustomTable
          isInsideTab={true}
          data={vibrationAuditData}
          actionColumnNotNeeded={true}
          generatedLeadsPage={true}
          setPage={setVibrationAuditPage}
          page={vibrationAuditPage}
          filterChange={handleVibrationFilterChange}
          columns={columns}
          loadDataFunction={loadVibrationAuditData}
          totalRecords={vibrationAuditTotalRecords}
          filterObject={vibrationAuditfilterObject}
          clearAll={clearAllVibrationSorting}
          sortColumn={vibrationAuditSortingColumn}
          setSortColumn={setVibrationAuditSortingColumn}
          sortColumnOrder={vibrationAuditSortingOrder}
          setSortColumnOrder={setVibrationAuditSortingOrder}
          setGetData={setGetVibrationData}
          pageSpecificData={pageSpecificData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <AirNetLeads
          airNetAuditFilters={airNetAuditFilters}
          airNetSorting={airNetSorting}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <EnergyRecoveryLeads
          energyRecoveryAuditFilters={energyRecoveryAuditFilters}
          energyRecoverySorting={energyRecoverySorting}
        />
      </CustomTabPanel>
    </>
  );
};

export default GeneratedLeads;
