import { useCallback, useEffect, useMemo, useState } from "react";
import CustomTable from "../../common/components/CustomComponents/CustomTable";
import { useSearchParams } from "react-router-dom";
import {
  DatePickerMode,
  TableColumn,
} from "../../common/interface/TableColumn";
import {
  ActualSavings,
  actualSavingsDropDownData,
  getIsTotalQuotedValue,
  IOpportunityAirAuditData,
  salesPhaseDropDownData,
  statusDropDownData,
} from "./GeneratedOpportunities";
import { RequestApi } from "../../common/Constant";
import { useRequest } from "../../services/agent";
import dayjs from "dayjs";

interface IOpportunityAirNetAuditData extends IOpportunityAirAuditData {}

export default function AirNetOpportunities({
  airNetAuditFilters,
  airNetSorting,
}: any) {
  const [pageSpecificData, setPageSpecificData] = useState<{
    opportunityStausUpdatedOn: "string";
  }>();

  const [airNetAuditTotalRecords, setAirNetAuditTotalRecords] =
    useState<number>(0);
  const [airNetAuditData, setAirNetAuditData] = useState<
    Array<IOpportunityAirNetAuditData>
  >([]);
  const [getAirNetData, setGetAirNetData] = useState(false);
  const [salesAirColumns, setSalesAirColumns] = useState<TableColumn[]>([]);

  const {
    airNetAuditFilterStatus,
    setAirNetAuditFilterStatus,
    airNetAuditFilterLeadId,
    setAirNetAuditFilterLeadId,
    airNetAuditFilterOppurtunityId,
    setAirNetAuditFilterOppurtunityId,
    airNetAuditFilterAuditId,
    setAirNetAuditAuditId,
    airNetAuditFilterCustomerName,
    setAirNetAuditFilterCustomerName,
    airNetAuditFilterSalesPhase,
    setAirNetAuditFilterSalesPhase,
    airNetAuditActualSavingValueInt,
    setAirNetAuditActualSavingValueInt,
    airNetAuditPage,
    setAirNetAuditPage,
    airNetAuditFilterOpportunityCreateDate,
    setAirNetAuditFilterOpportunityCreateDate,
    airNetAuditFilterOpportunityResponsible,
    setAirNetAuditFilterOpportunityResponsible,
    airNetAuditFilterLocalOpportunityCreateDate,
    setAirNetAuditFilterLocalOpportunityCreateDate,
  } = airNetAuditFilters;

  const {
    airNetAuditSortingColumn,
    setAirNetAuditSortingColumn,
    airNetAuditSortingOrder,
    setAirNetAuditSortingOrder,
  } = airNetSorting;

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    handleCloseDialog();
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const request = useRequest();

  const airNetAuditfilterObject: any = useMemo(
    () => ({
      auditNumber: airNetAuditFilterAuditId,
      leadId: airNetAuditFilterLeadId ? airNetAuditFilterLeadId : "",
      opportunityId: airNetAuditFilterOppurtunityId
        ? airNetAuditFilterOppurtunityId
        : "",
      status: airNetAuditFilterStatus,
      actualSavings: airNetAuditActualSavingValueInt,
      customerName: airNetAuditFilterCustomerName,
      salesPhase: airNetAuditFilterSalesPhase,
      opportunityCreatedDate: dayjs(
        airNetAuditFilterLocalOpportunityCreateDate
      ).isValid()
        ? dayjs(airNetAuditFilterLocalOpportunityCreateDate)
        : "",
      opportunityResponsiblePerson: airNetAuditFilterOpportunityResponsible,
    }),
    [
      airNetAuditFilterAuditId,
      airNetAuditFilterLeadId,
      airNetAuditFilterOppurtunityId,
      airNetAuditFilterStatus,
      airNetAuditFilterCustomerName,
      airNetAuditFilterSalesPhase,
      airNetAuditActualSavingValueInt,
      airNetAuditFilterOpportunityResponsible,
      airNetAuditFilterLocalOpportunityCreateDate,
    ]
  );

  const handleAirNetFilterChange = (id: any, event: any) => {
    switch (id) {
      case "auditNumber":
        setAirNetAuditAuditId(event.target.value);
        break;
      case "status":
        setAirNetAuditFilterStatus(event.target.value);
        break;
      case "actualSavings":
        setAirNetAuditActualSavingValueInt(event.target.value);
        break;
      case "leadId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setAirNetAuditFilterLeadId(null);
        } else {
          setAirNetAuditFilterLeadId(event.target.value);
        }
        break;
      case "opportunityId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setAirNetAuditFilterOppurtunityId(null);
        } else {
          setAirNetAuditFilterOppurtunityId(event.target.value);
        }
        break;
      case "customerName":
        setAirNetAuditFilterCustomerName(event.target.value);
        break;
      case "salesPhase":
        setAirNetAuditFilterSalesPhase(event.target.value);
        break;
      case "opportunityCreatedDate":
        if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
          setAirNetAuditFilterOpportunityCreateDate(
            dayjs(event.$d).format("DD/MM/YYYY")
          );
          setAirNetAuditFilterLocalOpportunityCreateDate(event.$d);
        } else {
          setAirNetAuditFilterOpportunityCreateDate("");
          setAirNetAuditFilterLocalOpportunityCreateDate("");
        }
        break;
      case "opportunityResponsiblePerson":
        setAirNetAuditFilterOpportunityResponsible(event.target.value);
        break;
    }
  };

  const clearAllAirNetAuditSorting = useCallback(() => {
    setAirNetAuditPage(0);
    setAirNetAuditFilterStatus(0);
    setAirNetAuditSortingColumn("");
    setAirNetAuditSortingOrder("");
    setAirNetAuditAuditId("");
    setAirNetAuditFilterLeadId(null);
    setAirNetAuditFilterOppurtunityId(null);
    setAirNetAuditFilterCustomerName("");
    setGetAirNetData(true);
    setAirNetAuditFilterSalesPhase(0);
    setAirNetAuditActualSavingValueInt(1);
    setAirNetAuditFilterOpportunityCreateDate("");
    setAirNetAuditFilterLocalOpportunityCreateDate("");
    setAirNetAuditFilterOpportunityResponsible("");
  }, []);

  const loadAirNetAuditData = useCallback((): void => {
    let body = {
      page: airNetAuditPage,
      pageSize: 20,
      sort: airNetAuditSortingColumn,
      order: airNetAuditSortingOrder,
      auditId: airNetAuditFilterAuditId ? airNetAuditFilterAuditId : "",
      status: airNetAuditFilterStatus
        ? statusDropDownData.filter(
            (data) => data.valueInt === airNetAuditFilterStatus
          )[0].value
        : "",
      LeadId: airNetAuditFilterLeadId ? airNetAuditFilterLeadId : 0,
      OpportunityId: airNetAuditFilterOppurtunityId
        ? airNetAuditFilterOppurtunityId
        : 0,
      customerName: airNetAuditFilterCustomerName
        ? airNetAuditFilterCustomerName
        : "",
      salesPhase: airNetAuditFilterSalesPhase
        ? salesPhaseDropDownData.filter(
            (data) => data.valueInt === airNetAuditFilterSalesPhase
          )[0].value
        : "",
      isTotalQuotedValue: getIsTotalQuotedValue(
        airNetAuditActualSavingValueInt
      ),
      opportunityCreatedDate: airNetAuditFilterOpportunityCreateDate
        ? airNetAuditFilterOpportunityCreateDate === "clear"
          ? ""
          : airNetAuditFilterOpportunityCreateDate
        : "",
      opportunityResponsiblePerson: airNetAuditFilterOpportunityResponsible
        ? airNetAuditFilterOpportunityResponsible
        : "",
    };

    request({
      url: RequestApi.GET_AIR_NET_AUDIT_GENERATED_OPPORTUNITIES,
      method: "post",
      data: body,
    })
      .then((resp) => {
        setAirNetAuditData(resp.data.data);
        setPageSpecificData({
          opportunityStausUpdatedOn: resp.data.opportunityStausUpdatedOn,
        });
        setAirNetAuditTotalRecords(resp.data.totalRecord);
      })
      .catch((err) => {
        console.log(err);
        setAlert("error", "Request Failed", true);
      });
  }, [
    airNetAuditPage,
    airNetAuditSortingColumn,
    airNetAuditSortingOrder,
    airNetAuditFilterAuditId,
    airNetAuditFilterStatus,
    airNetAuditFilterLeadId,
    airNetAuditFilterOppurtunityId,
    airNetAuditActualSavingValueInt,
    airNetAuditFilterCustomerName,
    airNetAuditFilterSalesPhase,
    airNetAuditFilterOpportunityCreateDate,
    airNetAuditFilterOpportunityResponsible,
  ]);

  useEffect(() => {
    let actualSavings: ActualSavings;

    actualSavings =
      airNetAuditActualSavingValueInt == 1
        ? ActualSavings.All
        : airNetAuditActualSavingValueInt == 2
        ? ActualSavings.QuotedValue
        : ActualSavings.ExpectedValue;

    const airColumns: TableColumn[] = [
      {
        id: "opportunityId",
        label: " Opportunity ID",
        align: "left",
        minWidth: 140,
        filterType: "text",
        filter: true,
        sorting: true,
      },
      {
        id: "status",
        label: "Status",
        align: "left",
        minWidth: 140,
        filterType: "dropdown",
        filter: true,
        dropdownData: statusDropDownData,
      },
      {
        id: "salesPhase",
        label: "Sales Phase",
        align: "left",
        minWidth: 180,
        filterType: "dropdown",
        filter: true,
        dropdownData: salesPhaseDropDownData,
      },
      {
        id: "customerName",
        label: "Customer Name",
        align: "left",
        minWidth: 330,
        filter: true,
        filterType: "text",
        sorting: true,
      },

      {
        id: "actualSavings",
        label: actualSavings,
        minWidth: 200,
        align: "left",
        filterType: "dropdown",
        filter: true,
        dropdownData: actualSavingsDropDownData,
      },
      {
        id: "auditNumber",
        label: "Project ID",
        align: "left",
        minWidth: 200,
        filter: true,
        filterType: "text",
        sorting: true,
      },
      {
        id: "leadId",
        label: "Lead ID",
        minWidth: 120,
        align: "left",
        filter: true,
        filterType: "text",
        sorting: true,
      },
      {
        id: "opportunityCreatedDate",
        label: "Opportunity Created Date",
        minWidth: 120,
        align: "left",
        format: (value: number) => value.toLocaleString("en-US"),
        filter: true,
        filterType: "datepicker",
        sorting: true,
        datePickerMode: DatePickerMode.MonthYear,
        datePickerLimit: { minDate: dayjs("2014-01-01") },
      },
      {
        id: "opportunityResponsiblePerson",
        label: "C4C Owner",
        minWidth: 160,
        align: "left",
        format: (value: number) => value.toFixed(2),
        filter: true,
        sorting: true,
      },
    ];

    setSalesAirColumns(airColumns);
  }, [airNetAuditActualSavingValueInt]);

  useEffect(() => {
    loadAirNetAuditData();
  }, []);

  useEffect(() => {
    if (getAirNetData == true) {
      loadAirNetAuditData();
    }
    return () => {
      setGetAirNetData(false);
    };
  }, [loadAirNetAuditData, getAirNetData]);

  return (
    <CustomTable
      isInsideTab={true}
      data={airNetAuditData}
      filterChange={handleAirNetFilterChange}
      columns={salesAirColumns}
      stickyActionColumn={true}
      clearAll={clearAllAirNetAuditSorting}
      achievedSales={true}
      setPage={setAirNetAuditPage}
      page={airNetAuditPage}
      actionColumnWidth={"155px"}
      loadDataFunction={loadAirNetAuditData}
      totalRecords={airNetAuditTotalRecords}
      filterObject={airNetAuditfilterObject}
      sortColumn={airNetAuditSortingColumn}
      setSortColumn={setAirNetAuditSortingColumn}
      sortColumnOrder={airNetAuditSortingOrder}
      setSortColumnOrder={setAirNetAuditSortingOrder}
      setGetData={setGetAirNetData}
      pageSpecificData={pageSpecificData}
      actionColumnNotNeeded={true}
    />
  );
}
