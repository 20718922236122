import {
  Box,
  Tabs,
  Tab,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
  SvgIcon,
} from "@mui/material";
import { tab_Style } from "../common/TabStyleConstants";
import { a11yProps } from "./FinishedReports/FinishedReports";
import VectorIcon from "./../assets/icons/Vector.svg";
import Exclamation from "../assets/icons/Red_Exclaimation_mark.svg";
import closeIcon from "./../assets/icons/close.svg";
import UploadDataIcon from "./../assets/images/Upload_icon.svg";
import classes from "./UploadFinishedReports.module.css";
import { ReactComponent as UploadReportIcon } from "./../assets/icons/upload_report.svg";
import { useEffect, useMemo, useRef, useState, KeyboardEvent } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import DropdownModel from "../common/interface/DropdownModel";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import CustomInputSelect from "../common/components/CustomComponents/CustomInputSelect";
import CustomMultiSelectNew from "../common/components/CustomComponents/CustomMultiSelectNew";
import CustomSelect from "../common/components/CustomComponents/CustomSelect";
import CustomUpload from "../common/components/CustomComponents/CustomUpload";
import { DeleteSuccessDailog, IAuditType } from "./UpdateOngoingProject";
import {
  AuditAirScanTypes,
  OngoingAuditStatus,
  ShowAuditTypesOnTabConstants,
} from "../common/AuditTypeConstants";
import State from "../common/interface/DragDrop";
import { CustomConfirmationDialogProps } from "./LinkProjectUpload";
import { showConfirmation } from "../common/store/confirmationBoxDataSlice";
import { useAppDispatch } from "../common/hooks/hooks";
import { RoutingConstants } from "../common/RoutingConstants";
import { hideLoader, showLoader } from "../common/store/loaderDataSlice";
import CustomAlertTest from "../common/components/CustomComponents/CustomAlert";
import { ProjectSourceConstants } from "../common/ProjectSourceConstants";
import { RequestApi } from "../common/Constant";
import { useRequest } from "../services/agent";
import { IFamCode } from "./CreateNewUser";

export default function FseUploadProjectData() {
  const selectList = useLoaderData() as any;
  const request = useRequest();
  const params = useParams();
  const dispatchCancelPopup = useAppDispatch();
  const { auditId } = params;
  const isEditReportPage = auditId && !isNaN(+auditId) && +auditId > 0;
  const multiselectRef = useRef<any | undefined>();
  const [value, setValue] = useState(0);
  const [savedFiles, setSavedFiles] = useState([]);
  const [isAirQualityOnly, setIsAirQualityOnly] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState<any[]>([]);
  const [typeOfMeasurementsList, setTypeOfMeasurements] = useState<
    DropdownModel[]
  >([]);
  const [editData, setEditData] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const [deletedFileIds, setDeletedFileIds] = useState<number[]>([]);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const validationSchema = Yup.object().shape({
    typesOfMeasurements: Yup.array(),
    auditName: Yup.string()
      .notRequired()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      })
      .max(80, "Maximum 80 Characters are allowed")
      .min(2, "Must include minumum 2 characters")
      .matches(/^[a-z\d\-_\s]+$/i, "Special Characters are not allowed"),

    measurementsWithQuantity: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        id: Yup.number(),
        typeOfMeasurementId: Yup.number(),
        qty: Yup.string()
          .notRequired()
          .matches(/^\d+$/, "Only numbers are allowed"),
      })
    ),
    electricityCost: Yup.number()
      .min(0, "The value must be 0 or more.")
      .notRequired()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      }),
    categoryOfProject: Yup.number().required("This field is required"),
    subCategory: Yup.array().min(1, "This field is required"),
    co2Factor: Yup.number().transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    }),
    uploadedFiles: Yup.array().test(
      "file-required",
      "This field is required",
      function (value) {
        return savedFiles.length > 0 || (value && value.length > 0);
      }
    ),
    projectDescription: Yup.string()
      .max(500, "Maximum 500 Characters are allowed")
      .nullable(),
    famCodeId: Yup.number()
      .min(1, "This field is required")
      .required("This field is required"),
    co2Unit: Yup.number().notRequired(),
    energyUnitId: Yup.number().notRequired(),
    countryId: Yup.number().required("This field is required"),
    customerName: Yup.string().nullable(),
    city: Yup.string().nullable(),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      categoryOfProject: 1,
      typesOfMeasurements: [] as string[],
      subCategory: [] as string[],
      co2Unit: 1,
      energyUnitId: 1,
    },
  });

  const control = methods.control;

  const { fields, append, remove } = useFieldArray({
    name: "measurementsWithQuantity",
    control,
  });

  const uploadReportIcon = (
    <div className={classes["upload-icon"]}>
      <SvgIcon component={UploadReportIcon}></SvgIcon>
    </div>
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
      methods.reset();
    } else if (newValue === 1) {
    }
  };

  const getCO2UnitList = useMemo(
    () => selectList?.cO2UnitList as DropdownModel[],
    [selectList]
  );

  let auditGroupTypes = useMemo(
    () =>
      selectList?.typeOfAuditList
        .filter(
          (auditType: any) =>
            auditType.auditGroup.toLowerCase() ===
            ShowAuditTypesOnTabConstants.Air_Scan.toLocaleLowerCase()
        )
        .map((auditType: any) => ({
          ...auditType,
          auditGroup: ShowAuditTypesOnTabConstants.Air_Scan,
        })) as IAuditType[],
    [selectList]
  );

  let mappedData: DropdownModel[] = auditGroupTypes?.map(
    (item: IAuditType, index) => {
      return { text: item.auditGroup, valueInt: index + 1, value: "" };
    }
  );

  let selectedTypesOfMeasurements = methods.watch(
    "typesOfMeasurements"
  ) as string[];
  let comments = methods.watch("projectDescription") as string;

  const subCategories = auditGroupTypes
    ?.filter(
      (auditType) =>
        auditType.auditGroup.toLowerCase() ===
        ShowAuditTypesOnTabConstants.Air_Scan.toLocaleLowerCase()
    )[0]
    .subGroups.map((subGroup) => ({
      cat: subGroup.valueInt,
      key: subGroup.text,
    }));

  methods.watch("countryId");

  const selectedCountriesId = methods.getValues("countryId");

  const famCodeOfSelectedCountries = useMemo(() => {
    const famcodes = selectList?.famCodesList?.filter(
      (famcode: IFamCode) => selectedCountriesId == famcode.countryId
    );

    if (famcodes?.length === 1) {
      methods.setValue("famCodeId", famcodes[0]?.id);
    }
    return famcodes;
  }, [selectedCountriesId, selectList]);

  const currencyCodeOfSelectedCountries = useMemo(
    () =>
      selectList?.countriesList?.find((country: any) => {
        return selectedCountriesId == country.valueInt;
      }),
    [selectedCountriesId, selectList]
  );

  const customInputSelections = [
    {
      function: (data: any) =>
        methods.setValue("electricityCost", data.target.value),
      label: "Electricity Cost",
      option: [currencyCodeOfSelectedCountries?.currencyCode + "/kWh"],
      name: "electricityCost",
      numberOnly: true,
      unitName: "energyUnitId",
      hide: isAirQualityOnly,
    },
    {
      function: (data: any) => methods.setValue("co2Factor", data.target.value),
      label: "CO2 Factor",
      option: getCO2UnitList,
      name: "co2Factor",
      numberOnly: true,
      unitName: "co2Unit",
      hide: isAirQualityOnly,
    },
  ];
  const customerFields = [
    {
      name: "Account ID",
      data: "c4CId",
    },
    {
      name: "Customer Name",
      data: "customerName",
    },
    {
      name: "Country",
      data: "country",
    },
    {
      name: "Address",
      data: "address",
    },
    {
      name: "Responsible Person",
      data: "responsible",
    },
  ];

  useEffect(() => {
    const newVal = selectedTypesOfMeasurements.length;
    if (newVal === 0) {
      remove();
    } else {
      let oldMeasurementIds: string[] = [];
      fields.filter((filter, i) => {
        if (filter.name === undefined) {
          remove(i);
        } else if (
          selectedTypesOfMeasurements.includes(
            String(filter.typeOfMeasurementId)
          )
        ) {
          oldMeasurementIds.push(String(filter.typeOfMeasurementId));
        } else {
          remove(i);
          let lst = methods.getValues("measurementsWithQuantity");
          let index = lst?.findIndex((itm) => {
            return itm.typeOfMeasurementId === filter.typeOfMeasurementId;
          });

          if (index !== undefined && index !== -1) {
            methods.setValue("measurementsWithQuantity", lst?.splice(index, 1));
          }
        }
      });

      let newMeasurementIds = selectedTypesOfMeasurements.filter(
        (x) => !oldMeasurementIds.includes(x)
      );

      if (newMeasurementIds.length !== 0) {
        for (let i = 0; i < newMeasurementIds.length; i++) {
          const name = getTypeOfMeasurementSelectedText(
            newMeasurementIds[i].toString()
          );
          if (name.length > 0) {
            append({
              name,
              qty: "1",
              typeOfMeasurementId: +newMeasurementIds[i],
            });
          }
        }
      }
    }
  }, [selectedTypesOfMeasurements]);

  const filterTypesOfMeasurements = (typeOfAudits: any[]) => {
    var typeOfMeasurements = selectList?.typeOfMeasurementsList?.filter(
      (itm: any) => {
        return typeOfAudits.some((e) => {
          return itm.auditTypeId === e;
        });
      }
    );

    let selectMeasurementList: DropdownModel[] = typeOfMeasurements?.map(
      (item: any) => {
        return { text: item.name, valueInt: item.id };
      }
    );
    setTypeOfMeasurements(selectMeasurementList);

    const availableSelectedMeasurements = methods.getValues(
      "typesOfMeasurements"
    );
    const measurementsForSelectedAudits = availableSelectedMeasurements?.filter(
      (value: any) => {
        const isAvailable =
          selectMeasurementList.filter(
            (measurement) =>
              measurement.valueInt.toString() === value.toString()
          ).length === 1;
        if (isAvailable) return value;
      }
    );
    methods.setValue("typesOfMeasurements", measurementsForSelectedAudits);
  };

  function handleMultiSelectChange(subCategoryList: any, addSubCategory: any) {
    let result: any[] = [];
    if (addSubCategory.cat === AuditAirScanTypes.Energy_Potential_PreSales) {
      if (
        selectedSubCategory?.length > 0 &&
        selectedSubCategory?.length > subCategoryList?.length
      ) {
        setSelectedSubCategory([]);
      } else {
        result = [addSubCategory.cat];
        setSelectedSubCategory([addSubCategory]);
        setIsAirQualityOnly(false);
      }
    } else {
      if (
        subCategoryList[0]?.cat === AuditAirScanTypes.Energy_Potential_PreSales
      ) {
        result = [addSubCategory.cat];
        addSubCategory.cat === AuditAirScanTypes.Air_Quality
          ? setIsAirQualityOnly(true)
          : setIsAirQualityOnly(false);
        setSelectedSubCategory([addSubCategory]);
      } else {
        subCategoryList.length === 1 &&
        subCategoryList[0]?.cat === AuditAirScanTypes.Air_Quality
          ? setIsAirQualityOnly(true)
          : setIsAirQualityOnly(false);
        result = subCategoryList.map((item: any) => item.cat);
        setSelectedSubCategory([...subCategoryList]);
      }
    }
    methods.setValue("subCategory", result);
    filterTypesOfMeasurements(result);
    methods.trigger("subCategory");
  }

  const getTypeOfMeasurementSelectedText = (id: string): string => {
    let selectedText = typeOfMeasurementsList?.find((e) => e.valueInt === +id);
    if (selectedText) {
      return selectedText.text;
    } else {
      return "";
    }
  };

  const handleTypeOfMeasurementChange = (
    event: SelectChangeEvent<typeof selectedTypesOfMeasurements>
  ) => {
    const {
      target: { value },
    } = event;
    let a = typeof value === "string" ? value.split(",") : value;
    var index = a?.indexOf("0");
    if (index && index >= 0) {
      a?.splice(index, 1);
    }
    methods.setValue("typesOfMeasurements", a);
    methods.trigger("typesOfMeasurements");
  };

  const onSubmit = (data: any) => {
    const obj = {
      id: auditId ? auditId : 0,
      typeOfAuditId: data?.subCategory,
      auditName: data.auditName,
      electricityCost: data.electricityCost,
      currencyId: data?.countryId,
      energyUnitId: data.energyUnitId,
      cO2Factor: data.co2Factor,
      cO2UnitId: data.co2Unit,
      typeOfMeasurementIds: data.typesOfMeasurements,
      reports: data.uploadedFiles,
      typeOfMeasurementDetails: data.measurementsWithQuantity,
      DeletedReportIds: deletedFileIds,
      Customer: {
        customerName: data?.customerName,
        city: data?.city,
      },
      famCodeId: data.famCodeId,
      famCode: famCodeOfSelectedCountries
        .filter((item: any) => item.valueInt == data.famCodeId)[0]
        ?.text.split(" ")[0],
      projectSource: ProjectSourceConstants.OngoingProject,
      projectDescription: methods.getValues("projectDescription"),
    };

    const formData = new FormData();
    for (let i = 0; i < data.uploadedFiles.length; i++) {
      formData.append("reports", data.uploadedFiles[i]);
    }

    let url = RequestApi.ADD_FSE_AUDIT_DATA;
    if (isEditReportPage) {
      url = RequestApi.EDIT_FEE_AUDIT_DATA;
    }

    request({
      url: url,
      method: "post",
      data: { request: obj, Data: formData },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      maxContentLength: 200 * 1024 * 1024,
    })
      .then((resp) => {
        setMessage(
          `Project data ${
            isEditReportPage ? "updated" : "uploaded"
          } successfully!`
        );
        setOpenSuccessDialog(true);
      })
      .catch((err) => {
        setAlert("error", "Request Failed", true);
        console.log(err);
      });
  };

  const goBack = () => {
    navigate("/" + RoutingConstants.OngoingProject);
  };

  const loadAuditDetailsById = (): void => {
    request({
      url: `${RequestApi.GET_FSE_AUDIT_DATA_BY_ID}?id=` + auditId,
      method: "get",
    })
      .then((resp) => {
        setEditData(resp.data);
        methods.setValue("projectDescription", resp.data.projectDescription);
        setSavedFiles(resp.data.reports);
        methods.setValue("customerName", resp.data.customerName);
        methods.setValue("auditName", resp.data.auditName);
        if (resp.data.cO2Factor)
          methods.setValue("co2Factor", resp.data.cO2Factor);
        methods.setValue("co2Unit", resp.data.cO2UnitId);
        methods.setValue("city", resp.data.city);
        methods.setValue("electricityCost", resp.data.electricityCost);
        methods.setValue("energyUnitId", resp.data.energyUnitId);
        const countryId = selectList?.famCodesList?.filter(
          (famcode: IFamCode) => resp.data.famCodeId === famcode.id
        )[0]?.countryId;

        auditGroupTypes.map((item: IAuditType) => {
          var filtered = item.subGroups?.filter((item) => {
            return item.valueInt === resp.data.typeOfAuditId[0];
          });

          if (filtered?.length > 0) {
            let selectedGroup = mappedData?.find(
              (e) => e.text === item.auditGroup
            );
            var filteredValue = item.subGroups?.filter((item1) =>
              resp.data.typeOfAuditId.some(
                (item2: any) => item1.valueInt === item2
              )
            );
            let val = filteredValue?.map((item: any, index) => {
              return { cat: item.valueInt, key: item.text };
            });

            if (
              selectedGroup?.text.toLowerCase() ==
                ShowAuditTypesOnTabConstants.Air_Scan.toLowerCase() &&
              val !== undefined
            ) {
              if (
                val?.length == 1 &&
                val[0]?.cat == AuditAirScanTypes.Air_Quality
              ) {
                setIsAirQualityOnly(true);
              }
              setSelectedSubCategory(val);
              methods.setValue("subCategory", resp.data.typeOfAuditId);
              filterTypesOfMeasurements(resp.data.typeOfAuditId);
            }
          }
        });
        methods.setValue("countryId", countryId);
        methods.setValue("famCodeId", resp.data.famCodeId);

        methods.setValue(
          "typesOfMeasurements",
          resp.data.typeOfMeasurementIds.map((id: number) => id.toString())
        );
        methods.trigger("typesOfMeasurements");
        methods.setValue(
          "measurementsWithQuantity",
          resp.data.typeOfMeasurementDetails
        );
        methods.trigger("measurementsWithQuantity");
        methods.setValue("projectDescription", resp.data.projectDescription);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setAlert("error", err.response.data.result.Id[0], true);
        } else {
          setAlert("error", "Request Failed", true);
        }
      });
  };

  async function mapFiles(fileData: State) {
    let filecollection: File[] = [];
    methods.setValue("uploadedFiles", []);

    if (fileData.fileList && fileData.fileList?.length > 0) {
      for (let i = 0; i < fileData.fileList.length; i++) {
        const file = fileData.fileList[i];
        filecollection.push(file);
      }

      methods.setValue("uploadedFiles", filecollection);

      methods.trigger("uploadedFiles");
    }
  }

  useEffect(() => {
    if (isEditReportPage) loadAuditDetailsById();
    else {
      methods.reset();
      setSelectedSubCategory([]);
      setSavedFiles([]);
      setDeletedFileIds([]);
      setIsAirQualityOnly(false);
      methods.setValue("projectDescription", "");
    }
  }, [isEditReportPage]);

  function handleCommentChange(e: any) {
    methods.setValue("projectDescription", e.target.value);
  }

  function handleFileDelete(id: number) {
    setDeletedFileIds((oldDeletedFileIds) => [...oldDeletedFileIds, id]);
  }

  function onCloseCancelSave() {
    dispatchCancelPopup(hideLoader());
  }

  function onConfirmCancelSave() {
    goBack();
    onCloseCancelSave();
  }
  const handleCancelClickOpen = (event: any) => {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content:
        "If you proceed, you will lose all your entered data, unsaved changes may be lost.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: onConfirmCancelSave,
    };
    dispatchCancelPopup(showConfirmation(customConfirmationDialogProps));
    dispatchCancelPopup(showLoader());
  };

  return (
    <>
      <Box
        sx={{
          paddingBottom: "0px",
          border: "none",
          marginBottom: "13px",
          background: "#fff !important",
          paddingRight: "12px",
        }}
        className={classes["page-title-container"]}
      >
        <Tabs
          sx={tab_Style}
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{ textTransform: "none" }}
            label={`${isEditReportPage ? "Update" : "Create"} Project`}
            {...a11yProps(0)}
          />
        </Tabs>
        {isEditReportPage && (
          <div className={classes["name-auditId-container"]}>
            <div
              className={classes["customer-name"]}
              style={{ padding: "8px" }}
            >
              <span className={classes["fw-600"]}>Project ID:</span> {auditId}
            </div>
          </div>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          height: "calc(100vh - 190px)",
          border: "unset",
          overflow: "auto",
        }}
      >
        {isEditReportPage &&
          editData &&
          editData?.auditStatus !== OngoingAuditStatus.Project_Data_Added && (
            <Box
              sx={{
                border: "unset",
                boxShadow: "none",
                marginBottom: "1.5rem",
                padding: "1rem 1.5rem 0 1.5rem",
              }}
            >
              <Typography
                fontWeight={600}
                fontSize={"1.125rem"}
                marginBottom={".75rem"}
              >
                Customer Details
              </Typography>
              <Box
                sx={{ border: "unset", boxShadow: "none" }}
                display={"grid"}
                gridTemplateColumns={"12% 24% 14% 29% 21%"}
              >
                {customerFields.map((field, index) => {
                  const value = editData[field.data];
                  return (
                    <Box
                      sx={{
                        border: "unset",
                        boxShadow: "none",
                        borderRight:
                          index === customerFields.length - 1
                            ? ""
                            : "1px solid #9F9F9F",
                      }}
                      display={"flex"}
                      flexDirection={"column"}
                      gap={"1rem"}
                      paddingRight={"1.5rem"}
                      width={"100%"}
                      paddingLeft={index === 0 ? "unset" : "1.5rem"}
                    >
                      <Typography fontWeight={600}>{field.name}</Typography>
                      <Typography>{value ? value : "N/A"}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            style={{
              overflow: "hidden scroll",
              padding: "0.75rem 0.3rem 0 0.75rem",
            }}
          >
            <Grid
              className="grid-container"
              container
              spacing={2}
              rowSpacing={2}
            >
              {!(
                isEditReportPage &&
                editData &&
                editData?.auditStatus !== OngoingAuditStatus.Project_Data_Added
              ) && (
                <>
                  <CustomInput
                    isRequired={false}
                    name={"customerName"}
                    numberOnly={false}
                    label={"Customer Name"}
                    variant="outlined"
                    md={4}
                    fullWidth
                  />
                  <CustomInput
                    isRequired={false}
                    name={"city"}
                    numberOnly={false}
                    label={"City"}
                    variant="outlined"
                    md={4}
                    fullWidth
                  />
                  <CustomSelect
                    name={"countryId"}
                    label={"Country"}
                    setValue={methods.setValue}
                    isRequired={true}
                    options={selectList?.countriesList}
                    disabled={isEditReportPage ? true : false}
                  />
                </>
              )}
              <CustomSelect
                name={"categoryOfProject"}
                label={"Category of Project"}
                setValue={methods.setValue}
                isRequired
                options={mappedData}
                disabled={isEditReportPage ? true : false}
                md={4}
              />
              <CustomMultiSelectNew
                name={"subCategory"}
                label={"Sub Category Of Project"}
                md={4}
                options={subCategories ?? []}
                disabled={isEditReportPage ? true : false}
                icon={VectorIcon}
                multiselectRef={multiselectRef}
                handleChange={handleMultiSelectChange}
                placeholder={"Select Sub Category Of Project"}
                selectedValues={selectedSubCategory ?? []}
              />
              <CustomSelect
                name={"famCodeId"}
                label={"FAM Code"}
                setValue={methods.setValue}
                isRequired={true}
                disabled={isEditReportPage ? true : false}
                options={famCodeOfSelectedCountries}
              />
              {
                <CustomInput
                  isRequired={false}
                  name={"auditName"}
                  numberOnly={false}
                  label={"Project Name"}
                  variant="outlined"
                  md={4}
                  fullWidth
                />
              }
              {customInputSelections?.map(
                (item) =>
                  !item.hide && (
                    <CustomInputSelect
                      label={item.label}
                      options={item.option}
                      md={4}
                      handleInputChange={item.function}
                      name={item.name}
                      numberOnly={item.numberOnly}
                      unitName={item.unitName}
                      isRequired={false}
                    />
                  )
              )}
              {isAirQualityOnly && <Grid md={8} />}
              <Grid
                item
                xs={12}
                md={12}
                xl={4}
                lg={4}
                container
                direction="row"
              >
                <FormControl fullWidth>
                  <FormLabel className="labels">Type of Measurements</FormLabel>
                  <Controller
                    name={"typesOfMeasurements"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        style={{ height: "33px" }}
                        {...methods.register("typesOfMeasurements")}
                        multiple
                        displayEmpty
                        value={field.value}
                        onChange={handleTypeOfMeasurementChange}
                        fullWidth
                        MenuProps={MenuProps}
                        renderValue={(selected) => {
                          if (selected?.length === 0) {
                            return (
                              <p className="grey-text">
                                Select Type of Measurements
                              </p>
                            );
                          }
                          return selected
                            ?.map((p: any) =>
                              getTypeOfMeasurementSelectedText(p)
                            )
                            .join(", ")
                            .substring(0, 50);
                        }}
                      >
                        <MenuItem
                          key="0"
                          value=""
                          disabled={true}
                        >
                          Select Type of Measurements
                        </MenuItem>
                        {typeOfMeasurementsList?.map((option) => (
                          <MenuItem
                            disableRipple
                            key={option.valueInt}
                            value={option.valueInt.toString()}
                          >
                            <Checkbox
                              checked={selectedTypesOfMeasurements?.includes(
                                option.valueInt.toString()
                              )}
                            />
                            <ListItemText primary={option.text} />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Typography
                    sx={{
                      color: "var(--primary-color)",
                      fontSize: "14px",
                      fontStyle: "italic",
                      fontWeight: "400",
                    }}
                  >
                    You can select multiple measurement
                  </Typography>
                  <Typography
                    variant="inherit"
                    align="left"
                    color="red"
                    fontSize={"12px"}
                  >
                    {methods.formState.errors["typesOfMeasurements"]
                      ? (methods.formState.errors["typesOfMeasurements"]
                          ?.message as string)
                      : undefined}
                  </Typography>
                </FormControl>
              </Grid>
              {selectedTypesOfMeasurements?.length > 0 ? (
                <Grid
                  className="containergrid"
                  xs={12}
                  sm={12}
                  md={12}
                  xl={8}
                  lg={8}
                  item
                  container
                >
                  <Box
                    border={"none"}
                    maxHeight={"400px"}
                    width={"100%"}
                    boxShadow={"none"}
                  >
                    <Grid
                      container
                      spacing={2}
                      rowSpacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <>
                        <Grid
                          xs={6}
                          sm={6}
                          md={6}
                          xl={6}
                          lg={6}
                          item
                        >
                          {fields.length > 0 &&
                            fields[0].typeOfMeasurementId && (
                              <FormLabel className="labels">
                                Measurements
                              </FormLabel>
                            )}
                        </Grid>
                        <Grid
                          xs={6}
                          sm={6}
                          md={6}
                          xl={6}
                          lg={6}
                          item
                        >
                          {fields.length > 0 &&
                            fields[0].typeOfMeasurementId && (
                              <FormLabel
                                className="labels"
                                sx={{ position: "relative", left: "-5px" }}
                              >
                                Quantity
                              </FormLabel>
                            )}
                        </Grid>
                        <Box
                          border={"none"}
                          maxHeight={"400px"}
                          height={"100%"}
                          sx={{ overflowY: "auto" }}
                          width={"100%"}
                          boxShadow={"none"}
                        >
                          <Grid
                            container
                            spacing={2}
                            rowSpacing={2}
                            paddingLeft={"24px"}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {fields.map(
                              (item, i) =>
                                item.name !== undefined && (
                                  <>
                                    <Grid
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      xl={6}
                                      lg={6}
                                      item
                                    >
                                      <TextField
                                        variant="outlined"
                                        label=""
                                        {...methods.register(
                                          `measurementsWithQuantity.${i}.name`
                                        )}
                                        name={`measurementsWithQuantity.${i}.name`}
                                        disabled
                                      ></TextField>

                                      <Typography
                                        variant="inherit"
                                        align="left"
                                        color="red"
                                        fontSize={"12px"}
                                      >
                                        {
                                          methods.formState.errors
                                            .measurementsWithQuantity?.[i]?.name
                                            ?.message
                                        }
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      xl={6}
                                      lg={6}
                                      item
                                    >
                                      <TextField
                                        variant="outlined"
                                        label=""
                                        {...methods.register(
                                          `measurementsWithQuantity.${i}.qty`
                                        )}
                                        name={`measurementsWithQuantity.${i}.qty`}
                                        onKeyDown={(
                                          event: KeyboardEvent<HTMLInputElement>
                                        ) => {
                                          const isNumeric = /^[0-9]*$/.test(
                                            event.key
                                          );
                                          if (
                                            (!isNumeric &&
                                              event.key !== "Tab" &&
                                              event.key !== "." &&
                                              event.key !== "Backspace") ||
                                            (event.key === "." &&
                                              (
                                                event.target as HTMLInputElement
                                              ).value.includes("."))
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                      ></TextField>
                                      <Typography
                                        variant="inherit"
                                        align="left"
                                        color="red"
                                        fontSize={"12px"}
                                      >
                                        {
                                          methods.formState.errors
                                            .measurementsWithQuantity?.[i]?.qty
                                            ?.message
                                        }
                                      </Typography>
                                    </Grid>
                                  </>
                                )
                            )}
                          </Grid>
                        </Box>
                      </>
                    </Grid>
                  </Box>
                </Grid>
              ) : (
                <Grid lg={8} />
              )}
              <CustomUpload
                handleFileChange={mapFiles}
                commentHandler={handleCommentChange}
                handleFileDelete={handleFileDelete}
                uploadFilesError={
                  savedFiles?.length === 0
                    ? methods.formState.errors.uploadedFiles?.message
                    : ""
                }
                commentsError={
                  comments?.length > 500
                    ? "Maximum 500 Characters are allowed"
                    : ""
                }
                filesDataVal={savedFiles}
                isFse={true}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              xl={12}
              lg={12}
              direction="row"
              justifyContent="flex-end"
              p={1}
              marginBottom={"1rem"}
            >
              <Button
                sx={{ textTransform: "none" }}
                className="cancel-button custom-buttons"
                disableRipple
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginRight: "15px",
                }}
                variant="outlined"
                onClick={handleCancelClickOpen}
              >
                Cancel
              </Button>
              <Button
                style={{ fontSize: "18px", fontWeight: "400" }}
                type="submit"
                className="custom-buttons"
                startIcon={uploadReportIcon}
                sx={{ textTransform: "none" }}
                variant="contained"
                disableRipple
                size="medium"
              >
                {isEditReportPage ? "Update Project" : "Create Project"}
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </Box>

      <DeleteSuccessDailog
        open={openSuccessDialog}
        onClose={() => {
          setOpenSuccessDialog(false);
          navigate("/" + RoutingConstants.OngoingProject);
        }}
        message={message}
        iconImg={UploadDataIcon}
      />
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={() => setAlertOpen(false)}
      />
    </>
  );
}
