import React from "react";


export default class Footer extends React.Component {
  
  render() 
  {
 
    const footerContainer: React.CSSProperties ={
        height: '36px', 
        width: '100%',  
        backgroundColor: '#5A5D60', 
        textAlign: 'center', 
        position: 'fixed',
        bottom: 0,
        zIndex: 1000,
        display: 'grid',
        placeItems: 'center',
        
    }

    const textStyle: React.CSSProperties = {
      color: '#E6E7E8',         
      fontFamily: 'Segoe UI',  
      fontSize: '14px',                             
    };
    
    return (
      <div style={footerContainer}>
        <p style={textStyle}>&copy; {new Date().getFullYear()} Optimization Platform. Atlas Copco. All Rights Reserved.</p>
      </div>
    );


  }

}

