import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { tab_Style } from '../common/TabStyleConstants';
import { useRequest } from '../services/agent';
import { RequestApi } from '../common/Constant';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const getListItemType = (level: number) => {
  switch (level) {
    case 0:
      return "decimal";
    case 1:
      return "lower-alpha";
    case 2:
      return "lower-roman";
  }
};
export const renderReleaseNotes = (notes: any, level: number) => {
  return (
    <ol
      style={{
        listStyleType: getListItemType(level),
        fontFamily: "Source Sans Pro",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
      }}
    >
      {notes?.map((note: any) => (
        <li key={note.id} style={{ marginLeft: level + 18 + "px" }}>
          {note.content}
          {note.children.length > 0 &&
            renderReleaseNotes(note.children, level + 1)}
        </li>
      ))}
    </ol>
  );
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
    borderRadius: '4px',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    backgroundColor: '#0078A1',
    color: '#fff',
    borderRadius: '4px',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  border: '1px solid rgba(0, 0, 0, .125)',
}));

function Information() {
  const [value, setValue] = useState(0);
  const [data, setData] = useState<any>();
  const request = useRequest();
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
    } else if (newValue === 1) {
    }
  };

  useEffect(() => {
    request({
      url: RequestApi.GET_ALL_Release_Notes,
      method: 'get',
    })
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ borderRadius: 'none' }}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: 0, '&.MuiBox-root': { border: 'none' } }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      event && setExpanded(isExpanded ? panel : false);
    };

  return (
    <Fragment>
      <Box
        sx={{
          borderColor: 'divider',
          border: 'none',
        }}
      >
        <Tabs
          sx={tab_Style}
          value={value}
          aria-label='basic tabs example'
          onChange={handleTabChange}
        >
          <Tab
            sx={{ textTransform: 'none' }}
            label='Release Notes'
            {...a11yProps(0)}
          />
        </Tabs>
      </Box>
      <Grid
        md={12}
        style={{ height: '15px', backgroundColor: '#F3F3F3' }}
      ></Grid>
      <div
        style={{ backgroundColor: '#fff', height: '100%', borderRadius: '8px' }}
      >
        <Grid
          // className={classes["grid-top-padding"]}
          // paddingTop={"0px"}
          item
          xs={12}
          sm={12}
          md={12}
          direction='row'
          style={{
            paddingLeft: '0.8vh',
            paddingTop: '0.8vh',
            paddingRight: '0.8vh',
            height: 'calc(100vh - 195px)',
            overflowY: 'auto',
          }}
        >
          {data?.map((item: any, unique: number) => (
            <Accordion
              key={unique}
              expanded={expanded === unique}
              onChange={handleChange(unique)}
              style={{ marginBottom: '5px', border: 'none' }}
            >
              <AccordionSummary
                aria-controls={`${item.id}-content`}
                id={`${item.id}-header`}
                style={{
                  backgroundColor: '#EFF9FD',
                  border: 'none',
                  borderRadius: '8px',
                }}
                expandIcon={<ExpandMoreIcon style={{ padding: '1px' }} />}
              >
                <Typography
                  style={{
                    color: '#0099CC',
                    fontWeight: '600',
                    fontSize: '18px',
                    lineHeight: '20px',
                    marginLeft: '10px',
                  }}
                >
                  {new Date(item.releaseDate)
                    .toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'long',
                      year: 'numeric',
                    })
                    .replace(/ /g, '-')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ border: 'none' }}>
                <Typography>{renderReleaseNotes(item.points, 0)}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </div>
    </Fragment>
  );
}

export default Information;
