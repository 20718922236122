import { Button, Dialog } from "@mui/material";
import CloseIcon from "../../../assets/icons/close.svg";
import { renderReleaseNotes } from "../../../features/Information";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/hooks";
import { RootState } from "../../store";
import { closeReleaseNote } from "../../store/releaseNoteNotification";
import { useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../RoutingConstants";

const ReleaseNoteNotification = () => {
  const navigation = useNavigate();

  const data = useSelector((state: RootState) => state.releaseNoteReducer.Data);
  const show = useSelector((state: RootState) => state.releaseNoteReducer.show);
  const dispatch = useAppDispatch();

  return (
    <>
      {data?.length > 0 && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
              maxWidth: "700px",
            },
          }}
          onClose={() => dispatch(closeReleaseNote())}
          open={show}
          PaperProps={{
            style: {
              boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <div
            style={{
              padding: "16px 2px",
              backgroundColor: "#EFF9FD",
              position: "relative",
              borderBottom: "2px solid #0078A1",
            }}
          >
            <h1
              style={{
                fontSize: "1.5rem",
                color: "#0078A1",
                textAlign: "center",
                lineHeight: "35px",
                fontFamily: "Source Sans Pro",
                fontWeight: 600,
              }}
            >
              Latest Updates
            </h1>
            <img
              onClick={() => dispatch(closeReleaseNote())}
              src={CloseIcon}
              height="32"
              alt="Back"
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "25%",
                right: "0px",
              }}
              loading="lazy"
              decoding="async"
            />
          </div>
          <div
            style={{
              color: "#000000",
              padding: "16px 32px",
              fontWeight: 400,
              lineHeight: "24px",
              fontFamily: "Source Sans Pro",
              maxHeight: "450px",
              overflowY: "auto",
              fontSize: "16px",
            }}
          >
            {renderReleaseNotes(data, 0)}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#00AEEF",
                color: "white",
                fontWeight: "bold",
                borderRadius: "8px",
                padding: "10px 20px",
                margin: "16px 0px",
              }}
              onClick={() => {
                dispatch(closeReleaseNote());
                navigation(`/${RoutingConstants.Information}`);
              }}
            >
              Ok
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ReleaseNoteNotification;
