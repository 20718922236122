import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomTable from "../../common/components/CustomComponents/CustomTable";
import { IAirAuditData } from "../FinishedReports/FinishedReports";
import { useRequest } from "../../services/agent";
import { RequestApi } from "../../common/Constant";
import {
  DatePickerMode,
  TableColumn,
} from "../../common/interface/TableColumn";
import { dropDownData } from "./GeneratedLeads";
import dayjs from "dayjs";

interface IEnergyRecoveryAuditData extends IAirAuditData {}

export default function EnergyRecoveryLeads({
  energyRecoveryAuditFilters,
  energyRecoverySorting,
}: any) {
  const [energyRecoveryAuditData, setEnergyRecoveryAuditData] = useState<
    Array<IEnergyRecoveryAuditData>
  >([]);

  const [energyRecoveryAuditTotalRecords, setEnergyRecoveryAuditTotalRecords] =
    useState<number>(0);
  const [getEnergyRecoveryData, setGetEnergyRecoveryData] = useState(false);

  const [pageSpecificData, setPageSpecificData] = useState<{
    leadStausUpdatedOn: "string";
  }>();

  const {
    energyRecoveryAuditFilterLeadId,
    setEnergyRecoveryAuditFilterLeadId,
    energyRecoveryAuditFilterAuditId,
    setEnergyRecoveryAuditAuditId,
    energyRecoveryAuditFilterStatus,
    setEnergyRecoveryAuditFilterStatus,
    energyRecoveryAuditFilterCustomerName,
    setEnergyRecoveryAuditFilterCustomerName,
    energyRecoveryAuditFilterUploadReportBy,
    setEnergyRecoveryAuditFilterUploadReportBy,
    energyRecoveryAuditPage,
    setEnergyRecoveryAuditPage,
    energyRecoveryAuditFilterLeadCreateDate,
    setEnergyRecoveryAuditFilterLeadCreateDate,
    energyRecoveryAuditFilterLeadResponsible,
    setEnergyRecoveryAuditFilterLeadResponsible,
    energyRecoveryAuditFilterLocalLeadCreateDate,
    setEnergyRecoveryAuditFilterLocalLeadCreateDate,
  } = energyRecoveryAuditFilters;

  const {
    energyRecoveryAuditSortingColumn,
    setEnergyRecoveryAuditSortingColumn,
    energyRecoveryAuditSortingOrder,
    setEnergyRecoveryAuditSortingOrder,
  } = energyRecoverySorting;

  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    // handleCloseDialog();
  }
  const request = useRequest();

  const energyRecoveryAuditfilterObject: any = useMemo(
    () => ({
      auditNumber: energyRecoveryAuditFilterAuditId,
      leadId: energyRecoveryAuditFilterLeadId
        ? energyRecoveryAuditFilterLeadId
        : "",
      customerName: energyRecoveryAuditFilterCustomerName,
      leadStatus: energyRecoveryAuditFilterStatus,
      uploadReportBy: energyRecoveryAuditFilterUploadReportBy,
      leadCreatedDate: dayjs(
        energyRecoveryAuditFilterLocalLeadCreateDate
      ).isValid()
        ? dayjs(energyRecoveryAuditFilterLocalLeadCreateDate)
        : "",
      leadResponsiblePerson: energyRecoveryAuditFilterLeadResponsible,
    }),

    [
      energyRecoveryAuditFilterAuditId,
      energyRecoveryAuditFilterCustomerName,
      energyRecoveryAuditFilterUploadReportBy,
      energyRecoveryAuditFilterLeadId,
      energyRecoveryAuditFilterStatus,
      energyRecoveryAuditFilterLeadResponsible,
      energyRecoveryAuditFilterLocalLeadCreateDate,
    ]
  );

  const loadEnergyRecoveryAuditData = useCallback((): void => {
    let body = {
      page: energyRecoveryAuditPage,
      pageSize: 20,
      sort: energyRecoveryAuditSortingColumn,
      order: energyRecoveryAuditSortingOrder,
      auditId: energyRecoveryAuditFilterAuditId
        ? energyRecoveryAuditFilterAuditId
        : "",
      leadStatus: energyRecoveryAuditFilterStatus
        ? dropDownData.filter(
            (data) => data.valueInt === energyRecoveryAuditFilterStatus
          )[0].value
        : "",
      leadId: energyRecoveryAuditFilterLeadId
        ? energyRecoveryAuditFilterLeadId
        : 0,
      customerName: energyRecoveryAuditFilterCustomerName
        ? energyRecoveryAuditFilterCustomerName
        : "",
      uploadReportBy: energyRecoveryAuditFilterUploadReportBy
        ? energyRecoveryAuditFilterUploadReportBy
        : "",
      leadCreatedDate: energyRecoveryAuditFilterLeadCreateDate
        ? energyRecoveryAuditFilterLeadCreateDate === "clear"
          ? ""
          : energyRecoveryAuditFilterLeadCreateDate
        : "",
      leadResponsiblePerson: energyRecoveryAuditFilterLeadResponsible
        ? energyRecoveryAuditFilterLeadResponsible
        : "",
    };

    request({
      url: RequestApi.GET_ENERGY_RECOVERY_AUDIT_GENERATED_LEADS,
      method: "post",
      data: body,
    })
      .then((resp) => {
        setEnergyRecoveryAuditData(resp.data.data);

        setPageSpecificData({
          leadStausUpdatedOn: resp.data.leadStausUpdatedOn,
        });
        setEnergyRecoveryAuditTotalRecords(resp.data.totalRecord);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_RESPONSE") {
          console.log(err);
          setAlert("error", "Request Failed", true);
        } else {
          console.log(err);
          setAlert("error", "Request Failed", true);
        }
      });
  }, [
    energyRecoveryAuditPage,
    energyRecoveryAuditSortingColumn,
    energyRecoveryAuditSortingOrder,
    energyRecoveryAuditFilterAuditId,
    energyRecoveryAuditFilterStatus,
    energyRecoveryAuditFilterLeadId,
    energyRecoveryAuditFilterCustomerName,
    energyRecoveryAuditFilterUploadReportBy,
    energyRecoveryAuditFilterLeadCreateDate,
    energyRecoveryAuditFilterLeadResponsible,
    request,
  ]);

  const handleEnergyRecoveryFilterChange = (id: any, event: any) => {
    switch (id) {
      case "auditNumber":
        setEnergyRecoveryAuditAuditId(event.target.value);
        break;
      case "leadId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setEnergyRecoveryAuditFilterLeadId(null);
        } else {
          setEnergyRecoveryAuditFilterLeadId(event.target.value);
        }
        break;
      case "customerName":
        setEnergyRecoveryAuditFilterCustomerName(event.target.value);
        break;
      case "leadStatus":
        setEnergyRecoveryAuditFilterStatus(event.target.value);
        break;
      case "uploadReportBy":
        setEnergyRecoveryAuditFilterUploadReportBy(event.target.value);
        break;
      case "leadCreatedDate":
        if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
          setEnergyRecoveryAuditFilterLeadCreateDate(
            dayjs(event.$d).format("DD/MM/YYYY")
          );
          setEnergyRecoveryAuditFilterLocalLeadCreateDate(event.$d);
        } else {
          setEnergyRecoveryAuditFilterLeadCreateDate("");
          setEnergyRecoveryAuditFilterLocalLeadCreateDate("");
        }
        break;
      case "leadResponsiblePerson":
        setEnergyRecoveryAuditFilterLeadResponsible(event.target.value);
        break;
    }
  };
  const clearAllAirAuditSorting = useCallback(() => {
    setEnergyRecoveryAuditPage(0);
    setEnergyRecoveryAuditSortingColumn("");
    setEnergyRecoveryAuditSortingOrder("");
    setEnergyRecoveryAuditAuditId("");
    setEnergyRecoveryAuditFilterLeadId(null);
    setEnergyRecoveryAuditFilterStatus(0);
    setEnergyRecoveryAuditFilterCustomerName("");
    setEnergyRecoveryAuditFilterUploadReportBy("");
    setEnergyRecoveryAuditFilterLeadCreateDate("");
    setEnergyRecoveryAuditFilterLocalLeadCreateDate("");
    setEnergyRecoveryAuditFilterLeadResponsible("");
    setGetEnergyRecoveryData(true);
  }, []);

  let energyRecoveryAuditColumns: TableColumn[] = [
    {
      id: "auditNumber",
      label: "Project ID",
      align: "left",
      minWidth: 110,
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "leadStatus",
      label: "Status",
      align: "left",
      minWidth: 100,
      filter: true,
      filterType: "dropdown",
      dropdownData: dropDownData,
    },
    {
      id: "leadId",
      label: "Lead ID",
      minWidth: 100,
      align: "left",
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "customerName",
      label: "Customer Name",
      minWidth: 320,
      align: "left",
      filter: true,
      sorting: true,
    },
    {
      id: "uploadReportBy",
      label: "Uploaded Project by",
      minWidth: 140,
      align: "left",
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "leadCreatedDate",
      label: "Lead Created Date",
      minWidth: 120,
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
      filter: true,
      filterType: "datepicker",
      sorting: true,
      datePickerMode: DatePickerMode.MonthYear,
      datePickerLimit: { minDate: dayjs("2014-01-01") },
    },
    {
      id: "leadResponsiblePerson",
      label: "C4C Owner",
      minWidth: 140,
      align: "left",
      format: (value: number) => value.toFixed(2),
      filter: true,
      sorting: true,
    },
  ];

  useEffect(() => {
    loadEnergyRecoveryAuditData();
  }, []);

  useEffect(() => {
    if (getEnergyRecoveryData == true) {
      loadEnergyRecoveryAuditData();
    }
    return () => {
      setGetEnergyRecoveryData(false);
    };
  }, [loadEnergyRecoveryAuditData, getEnergyRecoveryData]);

  return (
    <CustomTable
      isInsideTab={true}
      data={energyRecoveryAuditData}
      actionColumnNotNeeded={true}
      generatedLeadsPage={true}
      filterChange={handleEnergyRecoveryFilterChange}
      columns={energyRecoveryAuditColumns}
      setPage={setEnergyRecoveryAuditPage}
      clearAll={clearAllAirAuditSorting}
      page={energyRecoveryAuditPage}
      loadDataFunction={loadEnergyRecoveryAuditData}
      totalRecords={energyRecoveryAuditTotalRecords}
      filterObject={energyRecoveryAuditfilterObject}
      sortColumn={energyRecoveryAuditSortingColumn}
      setSortColumn={setEnergyRecoveryAuditSortingColumn}
      sortColumnOrder={energyRecoveryAuditSortingOrder}
      setSortColumnOrder={setEnergyRecoveryAuditSortingOrder}
      setGetData={setGetEnergyRecoveryData}
      pageSpecificData={pageSpecificData}
    />
  );
}
