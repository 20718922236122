export enum AuditAirScanTypes {
    Energy_Potential_PreSales = 6,
    Air_Quality = 4,
    DIGIScan = 1,
    Energy = 3,
    Leak_Detection = 2
}

export enum AuditTypes {
    Vibration_Audit = "vibrationAudit",
    Air_Audit = "airAudit",
    Smart_Airnet = "smartAirnet",
    AirNet = "airnet",
    EnergyRecovery = "energyRecovery"
}
export enum ShowAuditTypesOnTabConstants {
    Vibration_Audit = "Vibration",
    Air_Scan = "AIRscan",
    Smart_Airnet = "SMART AIRnet",
    Enery_Recovery = "Energy Recovery",
    Airnet = "AIRnet",
    Central_Controller = "Central Controller"
}


export enum OngoingAuditStatus {
    Project_Data_Added = "Project Data Added",
    Information_Needed = "Information Needed",
    IN_Progress= "In Progress",
  }