import { Button, Grid } from "@mui/material";
import dayjs from "dayjs";
import React, { CSSProperties } from "react";
import classes from "../CustomComponents/ConfirmDelete.module.css";

interface Props {
  CloseDeleteDailog: () => any;
  message: string | JSX.Element;
  CloseRecommendationDailog: () => any;
  textCss: string;
  btnText: string;
  c4C?: boolean;
  btnCss: string;
  navigation?: string;
  hideCancel?: boolean;
  hideRemark?: boolean;
  variantCSS: any;
  style?: any;
}

const ConfirmDelete = ({
  CloseDeleteDailog,
  message,
  textCss,
  c4C,
  CloseRecommendationDailog,
  btnText,
  btnCss,
  style,
  hideCancel,
  hideRemark,
  variantCSS,
}: Props) => {
  hideCancel = hideCancel === undefined ? true : hideCancel;

  const currentDate = dayjs(new Date()).format("DD/MM/YYYY");
  return (
    <>
      {c4C ? (
        <>{message}</>
      ) : (
        <p className={textCss}>
          <pre style={style ? style : { fontFamily: "inherit" }}>{message}</pre>
        </p>
      )}
      {hideRemark && (
        <div className={classes["mesagedv"]}>
          <p>Date: {currentDate}</p>
        </div>
      )}
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        direction="row"
        justifyContent="center"
      >
        {hideCancel && (
          <Button
            style={{
              fontSize: "16px",
              fontWeight: "700",
              marginRight: "15px",
            }}
            sx={{ textTransform: "none" }}
            className="cancel-button custom-buttons"
            disableRipple
            variant="outlined"
            onClick={CloseDeleteDailog}
          >
            Cancel
          </Button>
        )}
        <Button
          style={{ fontSize: "16px", fontWeight: "700" }}
          type="submit"
          className={btnCss}
          sx={{
            textTransform: "none",
            padding: "16px 32px",
            marginBlock: "18px 6px",
          }}
          variant={variantCSS}
          disableRipple
          size="medium"
          onClick={CloseRecommendationDailog}
        >
          {btnText}
        </Button>
      </Grid>
    </>
  );
};

export default ConfirmDelete;
