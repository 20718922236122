import {
  DatePickerMode,
  TableActionButtons,
  TableColumn,
} from "../../src/common/interface/TableColumn";
import DropdownModel from "../../src/common/interface/DropdownModel";
import CustomTable from "../../src/common/components/CustomComponents/CustomTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PageConstants } from "../../src/common/PageConstants";
import { AuditTypes } from "../../src/common/AuditTypeConstants";
import dayjs from "dayjs";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import classes from "../features/FinishedReports/FinishedReports.module.css";
import userEditIcon from "../assets/icons/edit_user.svg";
import ExclamationClipBoard from "../assets/icons/exclamation_clipboard.svg";
import { ShowAuditTypesOnTabConstants } from "../common/AuditTypeConstants";
import { tab_Style } from "../../src/common/TabStyleConstants";
import AddCustomer from "./AddCustomer";
import AssignEnergySpecialist from "./AssignEnergySpecialist";
import { a11yProps } from "./FinishedReports/FinishedReports";
import { RequestApi } from "../common/Constant";
import { useRequest } from "../services/agent";
import { UserRoleConstants } from "../common/UserRoleConstants";
import { useNavigate } from "react-router-dom";
import { RoutingConstants } from "../common/RoutingConstants";
import CustomRibbon from "../common/components/CustomComponents/CustomRibbon";

export type tableColumns =
  | "auditId"
  | "status"
  | "uploadedDate"
  | "initiatedBy"
  | "customerName"
  | "famCode"
  | "responsible"
  | "country"
  | "city";

const anchorStyle = {
  color: "#0078A1",
};

export interface IAirAuditData {
  id: number;
  auditId: number;
  status: string;
  uploadedDate: string;
  initiatedBy: string;
  customerName: string;
  famCode: string;
  responsible: string;
  country: string;
  city: string;
  c4CId: number;
  customerId: number;
  famCodeId?: number;
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  ribbonContent?: React.ReactNode;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ribbonContent, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          <Box sx={{ padding: 0, borderRadius: "8px", background: "#fff" }}>
            {ribbonContent && ribbonContent}
            <Typography component={"span"}>{children}</Typography>
          </Box>
        </>
      )}
    </div>
  );
}

const OngoingProject = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [airAuditData, setAirAuditData] = useState<Array<IAirAuditData>>([]);
  const [airAuditFilterUploadedDate, setAirAuditFilterUploadedDate] =
    useState<string>("");
  const [airFilterLocalDate, setAirFilterLocalDate] = useState<string>("");
  const [airAuditFilterAuditId, setAirAuditFilterAuditId] =
    useState<string>("");
  const [airAuditFilterInitiator, setAirAuditFilterInitiator] =
    useState<string>("");
  const [airAuditFilterResponsible, setAirAuditFilterResponsible] =
    useState<string>("");

  const [airAuditFilterCustomer, setAirAuditFilterCustomer] =
    useState<string>("");
  const [airAuditFilterCity, setAirAuditFilterCity] = useState<string>("");
  const [airAuditFilterCountry, setAirAuditFilterCountry] =
    useState<string>("");
  const [airAuditPage, setAirAuditPage] = useState(0);
  const [airAuditSortingColumn, setAirAuditSortingColumn] =
    useState<string>("");
  const [airAuditFilterStatus, setAirAuditFilterStatus] = useState("");
  const [airAuditFilterFamCode, setAirAuditFilterFamCode] = useState("");
  const [airAuditSortingOrder, setAirAuditSortingOrder] = useState<string>("");
  const [airAuditTotalRecords, setAirAuditTotalRecords] = useState<number>(0);
  const [getAirData, setGetAirData] = useState(false);
  const [ongoingProjectId, setOngoingProjectId] = useState(0);
  const [ribbonContent, setRibbonContent] = useState("");
  const [airColumns, setAirColum] = useState<TableColumn[]>([]);
  const request = useRequest();

  const [open, setOpen] = useState(false);
  const [openAssignEnergySpecialist, setOpenAssignEnergySpecialist] =
    useState(false);

  const handleAirFilterChange = (column: string, event: any) => {
    if (column === "status") {
      setAirAuditFilterStatus(event.target.value);
    } else if (column === "uploadedDate") {
      if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
        setAirAuditFilterUploadedDate(dayjs(event.$d).format("DD/MM/YYYY"));
        setAirFilterLocalDate(event.$d);
      } else {
        setAirAuditFilterUploadedDate("");
        setAirFilterLocalDate("");
      }
    } else if (column === "auditId") {
      setAirAuditFilterAuditId(event.target.value);
    } else if (column === "initiatedBy") {
      setAirAuditFilterInitiator(event.target.value);
    } else if (column === "responsible") {
      setAirAuditFilterResponsible(event.target.value);
    } else if (column === "customerName") {
      setAirAuditFilterCustomer(event.target.value);
    } else if (column === "city") {
      setAirAuditFilterCity(event.target.value);
    } else if (column === "country") {
      setAirAuditFilterCountry(event.target.value);
    } else if (column === "famCode") {
      setAirAuditFilterFamCode(event.target.value);
    }
  };

  const handleEditClick = (...args: any[]) => {
    const row = args[3];
    if (!row) return;

    const { customerId, responsible, id } = row;
    let ribbonMessage = "";
    setOngoingProjectId(id);

    if (
      (customerId && responsible) ||
      getUserRoleId() == UserRoleConstants.FIELD_SERVICE_ENGINEER
    ) {
      navigate(
        `/${RoutingConstants.OngoingProject}/${RoutingConstants.UpdateOngoingProject}/${id}`
      );
    } else if (customerId && !responsible) {
      ribbonMessage =
        "Please add the responsible person. That is required to edit project";
    } else if (!customerId && responsible) {
      ribbonMessage =
        "Please add the customer data. That is required to edit project";
    } else if (!customerId && !responsible) {
      ribbonMessage =
        "Please add the customer data and responsible person. That is required to edit project";
    }
    setRibbonContent(ribbonMessage);
  };

  const getUserRoleId = () => {
    const decodedData = atob(sessionStorage.getItem("ud") || "");
    const data = decodedData ? JSON.parse(decodedData) : "";
    return data.role?.key;
  };

  const renderRibbonMessage = () => {
    return (
      ribbonContent &&
      ongoingProjectId && (
        <div style={{ margin: "4px" }}>
          <CustomRibbon
            messagesInHtmlArray={[
              <p>
                <b>DATA REQUIRED</b>
              </p>,
              <p>
                {ribbonContent} (<b>Project ID - {ongoingProjectId}</b>
                ).
              </p>,
            ]}
            md={12}
            style={{
              height: "auto",
              border: `1px solid #F26522`,
              backgroundColor: "#fffad7",
              borderRadius: "5px",
              marginBottom: "8px",
              position: "relative",
            }}
            icon={ExclamationClipBoard}
            paraColor={"#F26522"}
            onClose={() => setRibbonContent("")}
          />
        </div>
      )
    );
  };

  const actionButtons: TableActionButtons[] = [
    {
      name: "Edit Project",
      icon: userEditIcon,
      handleClick: handleEditClick,
      permission: true,
      textInsideButton: <span style={{ marginLeft: "5px" }}>Edit Project</span>,
    },
  ];

  const airAuditFilterObject: any = useMemo(
    () => ({
      famCode: airAuditFilterFamCode,
      auditId: airAuditFilterAuditId,
      uploadedDate: dayjs(airFilterLocalDate).isValid()
        ? dayjs(airFilterLocalDate)
        : "",
      initiatedBy: airAuditFilterInitiator,
      responsible: airAuditFilterResponsible,
      status: airAuditFilterStatus,
      customerName: airAuditFilterCustomer,
      city: airAuditFilterCity,
      country: airAuditFilterCountry,
    }),
    [
      airAuditFilterAuditId,
      airAuditFilterStatus,
      airAuditFilterInitiator,
      airAuditFilterFamCode,
      airAuditFilterResponsible,
      airFilterLocalDate,
      airAuditFilterCustomer,
      airAuditFilterCity,
      airAuditFilterCountry,
    ]
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
    } else if (newValue === 1) {
    }
  };
  function handleDialogClose() {
    setOpen(false);
    setOpenAssignEnergySpecialist(false);
    loadAirAuditData();
  }

  const clearAllAirAuditSorting = useCallback(() => {
    setAirAuditPage(0);
    setAirAuditFilterAuditId("");
    setAirAuditFilterStatus("");
    setAirAuditFilterInitiator("");
    setAirAuditFilterFamCode("");
    setAirAuditFilterResponsible("");
    setAirAuditFilterStatus("");
    setAirAuditSortingColumn("");
    setAirAuditSortingOrder("");
    setAirFilterLocalDate("");
    setAirAuditFilterUploadedDate("");
    setAirAuditFilterCustomer("");
    setAirAuditFilterCity("");
    setAirAuditFilterCountry("");
    setGetAirData(true);
  }, []);

  const loadAirAuditData = useCallback((): void => {
    let body = {
      page: airAuditPage,
      pageSize: 20,
      sort: airAuditSortingColumn,
      order: airAuditSortingOrder,
      famCode: airAuditFilterFamCode ? airAuditFilterFamCode : "",
      auditId: airAuditFilterAuditId ? airAuditFilterAuditId : "",
      initiatedBy: airAuditFilterInitiator ? airAuditFilterInitiator : "",
      responsible: airAuditFilterResponsible ? airAuditFilterResponsible : "",
      uploadedDate: airAuditFilterUploadedDate
        ? airAuditFilterUploadedDate === "clear"
          ? ""
          : airAuditFilterUploadedDate
        : "",
      status: airAuditFilterStatus ? airAuditFilterStatus : "",
      customerName: airAuditFilterCustomer ? airAuditFilterCustomer : "",
      city: airAuditFilterCity ? airAuditFilterCity : "",
      country: airAuditFilterCountry ? airAuditFilterCountry : "",
    };

    request({
      url: RequestApi.GET_AIR_AUDIT_ONGOING_REPORT_LIST,
      method: "post",
      data: body,
    })
      .then((resp) => {
        setAirAuditData(resp.data.data);
        setAirAuditTotalRecords(resp.data.totalRecord);
      })
      .catch((err) => {
        console.log(err);
        // setAlert("error", "Request Failed", true);
      });
  }, [
    airAuditFilterAuditId,
    airAuditFilterStatus,
    airAuditFilterFamCode,
    airAuditFilterInitiator,
    airAuditFilterCity,
    airAuditFilterCountry,
    airAuditFilterCustomer,
    airAuditFilterResponsible,
    airAuditFilterUploadedDate,
    airAuditPage,
    airAuditSortingColumn,
    airAuditSortingOrder,
    request,
  ]);

  useEffect(() => {
    loadAirAuditData();
  }, []);

  useEffect(() => {
    if (getAirData == true) {
      loadAirAuditData();
    }
    return () => {
      setGetAirData(false);
    };
  }, [loadAirAuditData, getAirData]);

  useEffect(() => {
    let airColumns: TableColumn[];

    if (getUserRoleId() == UserRoleConstants.FIELD_SERVICE_ENGINEER) {
      airColumns = [
        {
          id: "auditId" as tableColumns,
          label: "Project ID",
          align: "left",
          minWidth: 90,
          filter: true,
          filterType: "text",
          sorting: true,
        },
        {
          id: "status" as tableColumns,
          label: "Status",
          minWidth: 180,
          align: "left",
          format: (value: number) => value.toLocaleString("en-US"),
          filter: true,
        },
        {
          id: "uploadedDate" as tableColumns,
          label: "Creation Date",
          minWidth: 120,
          align: "left",
          format: (value: number) => value.toLocaleString("en-US"),
          filter: true,
          filterType: "datepicker",
          sorting: true,
          datePickerMode: DatePickerMode.MonthYear,
        },
        {
          id: "customerName" as tableColumns,
          label: "Customer Name",
          minWidth: 360,
          align: "left",
          filterType: "text",
          filter: true,
          sorting: true,
          htmlElement: (value: any, row: any) => value,
        },
        {
          id: "famCode" as tableColumns,
          label: "FAM Code",
          minWidth: 70,
          align: "left",
          filter: true,
        },
        {
          id: "responsible" as tableColumns,
          label: "Responsible Person",
          minWidth: 240,
          align: "left",
          filter: true,
          sorting: true,
          htmlElement: (value: any, row: any) => value,
        },
        {
          id: "country" as tableColumns,
          label: "Country",
          minWidth: 170,
          align: "left",
          format: (value: number) => value.toFixed(2),
          filter: true,
          sorting: true,
        },
        {
          id: "city" as tableColumns,
          label: "City",
          minWidth: 170,
          align: "left",
          format: (value: number) => value.toFixed(2),
          filter: true,
          sorting: true,
        },
      ];
    } else {
      airColumns = [
        {
          id: "auditId" as tableColumns,
          label: "Project ID",
          align: "left",
          minWidth: 85,
          filter: true,
          filterType: "text",
          sorting: true,
        },
        {
          id: "status" as tableColumns,
          label: "Status",
          minWidth: 150,
          align: "left",
          format: (value: number) => value.toLocaleString("en-US"),
          filter: true,
        },
        {
          id: "uploadedDate" as tableColumns,
          label: "Creation Date",
          minWidth: 120,
          align: "left",
          format: (value: number) => value.toLocaleString("en-US"),
          filter: true,
          filterType: "datepicker",
          sorting: true,
          datePickerMode: DatePickerMode.MonthYear,
        },
        {
          id: "initiatedBy" as tableColumns,
          label: "Initiator",
          minWidth: 200,
          align: "left",
          filter: true,
          sorting: true,
        },
        {
          id: "customerName" as tableColumns,
          label: "Customer Name",
          minWidth: 300,
          align: "left",
          filterType: "text",
          filter: true,
          sorting: false,

          htmlElement: (value: string = "Add Customer", row: any) => (
            <a
              style={anchorStyle}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setOngoingProjectId(row.id);
                setOpen(true);
              }}
            >
              {value}
            </a>
          ),
        },
        {
          id: "famCode" as tableColumns,
          label: "FAM Code",
          minWidth: 70,
          align: "left",
          filter: true,
        },
        {
          id: "responsible" as tableColumns,
          label: "Responsible Person",
          minWidth: 200,
          align: "left",
          filter: true,
          sorting: false,
          htmlElement: (value: string = "Assign", row: any) => (
            <a
              style={anchorStyle}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setOngoingProjectId(row.id);
                setOpenAssignEnergySpecialist(true);
              }}
            >
              {value}
            </a>
          ),
        },
        {
          id: "country" as tableColumns,
          label: "Country",
          minWidth: 150,
          align: "left",
          format: (value: number) => value.toFixed(2),
          filter: true,
          sorting: true,
        },
        {
          id: "city" as tableColumns,
          label: "City",
          minWidth: 170,
          align: "left",
          format: (value: number) => value.toFixed(2),
          filter: true,
          sorting: true,
        },
      ];
    }

    setAirColum(airColumns);
  }, [airAuditData]);

  return (
    <>
      <Tabs
        sx={tab_Style}
        value={value}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab
          sx={{ textTransform: "none" }}
          label={ShowAuditTypesOnTabConstants.Air_Scan}
          {...a11yProps(0)}
        />
      </Tabs>

      <Grid
        md={12}
        style={{ height: "13px", backgroundColor: "#F3F3F3" }}
      ></Grid>
      <CustomTabPanel
        value={value}
        index={0}
        ribbonContent={renderRibbonMessage()}
      >
        <CustomTable
          isInsideTab={true}
          data={airAuditData}
          filterChange={handleAirFilterChange}
          actionButtons={actionButtons}
          columns={airColumns}
          filterObject={airAuditFilterObject}
          setPage={setAirAuditPage}
          sortColumn={airAuditSortingColumn}
          setSortColumn={setAirAuditSortingColumn}
          sortColumnOrder={airAuditSortingOrder}
          setSortColumnOrder={setAirAuditSortingOrder}
          page={airAuditPage}
          clearAll={clearAllAirAuditSorting}
          finishedReportsPage={true}
          stickyActionColumn={true}
          actionColumnWidth={"155px"}
          loadDataFunction={loadAirAuditData}
          totalRecords={airAuditTotalRecords}
          setGetData={setGetAirData}
          typeOfAudit={AuditTypes.Air_Audit}
          pageName={PageConstants.OngoingProject}
          ribbonMessage={ribbonContent?.length > 0}
        />
      </CustomTabPanel>
      <AddCustomer
        open={open}
        ongoingProjectId={ongoingProjectId}
        onClose={handleDialogClose}
        data={airAuditData.filter((data) => data.id === ongoingProjectId)[0]}
      />
      <AssignEnergySpecialist
        open={openAssignEnergySpecialist}
        onClose={handleDialogClose}
        ongoingProjectId={ongoingProjectId}
        data={airAuditData.filter((data) => data.id === ongoingProjectId)[0]}
      />
    </>
  );
};

export default OngoingProject;
