import { PageConstants } from "../common/PageConstants";
import { UserRoleConstants } from "../common/UserRoleConstants";
import { OperationPermissions } from "../common/models/operationPermission";

export const PermissionService = (pageName: string) => {
    const decodedData = atob(sessionStorage.getItem("ud") || "");
    const navigationPermissions = decodedData ? JSON.parse(decodedData) : "";
    var dataValue = navigationPermissions?.navigationDetailList?.filter((data: any) => data.description === pageName);
    if ( dataValue?.length > 0 && dataValue[0] && dataValue[0]?.permissions) {
        let permissions: OperationPermissions = dataValue[0].permissions;
        if (pageName === PageConstants.UserManagement && navigationPermissions.role.key === UserRoleConstants.FIELD_SERVICE_ENGINEER) {
           permissions = { view: false, update: false, delete: false, create: false };
        }
        return permissions;
    }
    else {
        const permissions: OperationPermissions = {view:false,update:false,delete:false,create:false};
        return permissions;
    }
};

export default PermissionService;