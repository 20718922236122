import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import classes from "./Navigation.module.css";
import dashboard_white from "../../../../assets/icons/dashboard_white.svg";
import dashboard_grey from "../../../../assets/icons/dashboard_grey.svg";

import generated_leads_grey from "../../../../assets/icons/generated_leads_grey.svg";
import generated_leads_grey_white from "../../../../assets/icons/generated_leads_grey_white.svg";

import Achieved_sales from "../../../../assets/icons/Achieved_sales.svg";
import Achieved_sales_white from "../../../../assets/icons/Achieved_sales_white.svg";

import finished_report from "../../../../assets/icons/finished_report.svg";
import finished_report_white from "../../../../assets/icons/finished_report_white.svg";

import Customer_grey from "../../../../assets/icons/Customer_grey.svg";
import Customer_white from "../../../../assets/icons/Customer_white.svg";

import user_management_grey from "../../../../assets/icons/user_management_grey.svg";
import user_management_white from "../../../../assets/icons/user_management_white.svg";

import help_question_grey from "../../../../assets/icons/help_question_grey.svg";
import help_question_white from "../../../../assets/icons/help_question_white.svg";

import board_edit_grey from "../../../../assets/icons/board_edit_grey.svg";
import board_edit_white from "../../../../assets/icons/board_edit_white.svg";

import create_project_grey from "../../../../assets/icons/create_project_grey.svg";
import create_project_white from "../../../../assets/icons/create_project_white.svg";

import { PageConstants } from "../../../PageConstants";
import { NavLink, RouteMatch } from "react-router-dom";
import { UserRoleConstants } from "../../../UserRoleConstants";
import { RoutingConstants } from "../../../RoutingConstants";

const drawerWidth = 245;

interface ActiveState {
  active: string;
  userRole: number;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  height: "100%",
  position: "relative",
  zIndex: 1,
    transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
    overflowX: 'hidden',
    position: 'relative',
  height: "100%",
  zIndex: 1,
    [theme.breakpoints.up('sm')]: {
    width: `80px`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
  }),
    }),
);

export default function Navigation() {
  const [open, setOpen] = React.useState(false);

  var decodedData = atob(sessionStorage.getItem("ud") || "");
  var data = decodedData ? JSON.parse(decodedData) : "";
  let state: ActiveState = {

    userRole: data?.role?.key,
    active: "",
  };

  function getIcon(menuItemDescription: string, isActive: boolean) {
    if (menuItemDescription === PageConstants.NewProject) {
      if (isActive) {
        return create_project_white;
            }
            else {
        return create_project_grey;
      }
    }
    if (menuItemDescription === PageConstants.Dashboard) {
      if (isActive) {
        return dashboard_white;
            }
            else {
        return dashboard_grey;
      }
    }
    if (menuItemDescription === PageConstants.FinishedReports) {
      if (isActive) {
        return finished_report_white;
            }
            else {
        return finished_report;
      }
    }
    if (menuItemDescription === PageConstants.GeneratedLeads) {
      if (isActive) {
        return generated_leads_grey_white;
            }
            else {
        return generated_leads_grey;
      }
    }
    if (menuItemDescription === PageConstants.GeneratedOpportunities) {
      if (isActive) {
        return Achieved_sales_white;
            }
            else {
        return Achieved_sales;
      }
    }
    if (menuItemDescription === PageConstants.UserManagement) {
      if (isActive) {
        return user_management_white;
            }
            else {
        return user_management_grey;
      }
    }
    if (menuItemDescription === PageConstants.Information) {
      if (isActive) {
        return help_question_white;
            }
            else {
        return help_question_grey;
      }
    }
    if (menuItemDescription === PageConstants.OngoingProject) {
      if (isActive) {
        return board_edit_white;
            }
            else {
        return board_edit_grey;
      }
    }
  }

  return (
        <Box style={{border:"none"}}>
            <Drawer variant="permanent" open={open} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        <List style={{ paddingTop: "8px" }}>
          {data?.navigationDetailList?.length > 0 &&
            data.navigationDetailList.map((menuItem: any, index: any) => {
              const url =
                state.userRole === UserRoleConstants.FIELD_SERVICE_ENGINEER &&
                menuItem.description === PageConstants.NewProject
                  ? "/" +
                    RoutingConstants.NewProject +
                    "/" +
                    RoutingConstants.UploadOngoingProject
                  : menuItem.url;

              return (
                menuItem.description !== PageConstants.UserManagement &&
                menuItem.description !== PageConstants.Information && (
                  <NavLink
                    to={url}
                    className={({ isActive }) =>
                      isActive
                        ? `${classes.active} ${classes.nav_link}`
                        : `${classes.nav_link}`
                    }
                    style={{
                      display:
                        state.userRole === UserRoleConstants.LOCAL_USER &&
                        menuItem.Description === PageConstants.UserManagement
                          ? "none"
                          : "flex",
                      textDecoration: "none",
                      color: "#5A5D60",
                      marginLeft: open ? "0px" : "8px",
                                width: open ? "" : "64px"
                    }}
                  >
                    {({ isActive }) => (
                                    <ListItem key={menuItem.menuName} disablePadding sx={{ display: 'block'}}>
                        <ListItemButton
                          sx={{
                            minHeight: "6.4vh",
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5
                            //'&:hover': {
                            //    backgroundColor: isActive? "" : "#fff"
                            //}
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                                marginRight: open ? "16px" : ""
                            }}
                          >
                            <img
                              src={getIcon(menuItem.description, isActive)}
                              alt=""
                              height={"32px"}
                              loading="lazy"
                              decoding="async"
                            ></img>
                          </ListItemIcon>
                                            <ListItemText primary={menuItem.menuName} sx={{ opacity: open ? 1 : 0}} />
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>
                )
              );
            })}
        </List>
                <List style={{ bottom: "0", position: "absolute", width: "inherit"}}>
                    {data?.navigationDetailList?.length > 0 && data.navigationDetailList.map((menuItem: any, index: any) => (
                        (menuItem.description === PageConstants.UserManagement || menuItem.description === PageConstants.Information) &&
                  <NavLink
                    to={menuItem.url}
                    className={({ isActive }) =>
                      isActive
                        ? `${classes.active} ${classes.nav_link}`
                        : `${classes.nav_link}`
                    }
                    style={{
                      display:
                        state.userRole === UserRoleConstants.LOCAL_USER &&
                        menuItem.Description === PageConstants.UserManagement
                          ? "none"
                          : "flex",
                      textDecoration: "none",
                      color: "#5A5D60",
                      marginLeft: open ? "0px" : "8px",
                                width: open ? "" : "64px"
                    }}
                  >
                    {({ isActive }) => (
                                <ListItem key={menuItem.menuName} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                          sx={{
                            minHeight: "6.4vh",
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5
                            //'&:hover': {
                            //    backgroundColor: isActive? "" : "#fff"
                            //}
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                                marginRight: open ? "16px" : ""
                            }}
                          >
                            <img
                              src={getIcon(menuItem.description, isActive)}
                              alt=""
                              height="32px"
                              loading="lazy"
                              decoding="async"
                            ></img>
                          </ListItemIcon>
                                        <ListItemText primary={menuItem.menuName} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>
                    ))}
        </List>
      </Drawer>
    </Box>
  );
}
