import { Grid, Tooltip, Typography } from "@mui/material";
import classes from "../../../features/UploadFinishedReports.module.css";
import ExcelFileIcon from "./../../../assets/icons/excel.svg";
import PDFFileIcon from "./../../../assets/icons/pdf_icon.svg";
import WordFileIcon from "./../../../assets/icons/word_doc.svg";
import PPTFileIcon from "./../../../assets/icons/ppt_icon.svg";
import DragDrop from "./DragDrop";
import CloseIcon from "./../../../assets/icons/close.svg";
import { useEffect, useState } from "react";
import React from "react";
import State from "../../interface/DragDrop";
import { Controller, useFormContext } from "react-hook-form";
import { ShowAuditTypesOnTabConstants } from "../../AuditTypeConstants";
import downloadIcon from "../../../assets/icons/download_light_gray.svg";
import { useRequest } from "../../../services/agent";
import { RequestApi } from "../../Constant";

interface UploadProp {
  handleFileChange: (fileData: State) => Promise<void>;
  commentHandler: (e: any) => void;
  handleFileDelete: (id: number) => void;
  uploadFilesError: any;
  commentsError: any;
  selectedProject?: string;
  filesDataVal: any;
  isFse?: boolean;
  onlyUploadFiles?: boolean;
}
export default function CustomUpload({
  handleFileChange,
  commentHandler,
  handleFileDelete,
  uploadFilesError,
  commentsError,
  selectedProject,
  filesDataVal,
  isFse,
  onlyUploadFiles,
}: UploadProp) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const request = useRequest();

  type Action =
    | { type: "SET_DROP_DEPTH"; dropDepth: number }
    | { type: "SET_IN_DROP_ZONE"; inDropZone: boolean }
    | { type: "REMOVED_FILE_TO_LIST"; fileName: string }
    | { type: "ADD_FILE_TO_LIST"; files: File[] };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "SET_DROP_DEPTH":
        return { ...state, dropDepth: action.dropDepth };
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        return { ...state, fileList: state.fileList.concat(action.files) };
      case "REMOVED_FILE_TO_LIST":
        return {
          ...state,
          fileList: state.fileList.filter((f) => f.name !== action.fileName),
        };
      default:
        return state;
    }
  };

  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    handleCloseDialog();
  }

  const [fileData, dispatch] = React.useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: [],
  });

  async function DownloadFile(blobName: string, fileName: string) {
    var body = {
      blobName: blobName,
      fileName: fileName,
    };

    request({
      url: `${RequestApi.POST_DOWNLOAD_FILE}`,
      method: "post",
      data: body,
      responseType: "blob",
    })
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setAlert("success", "Downloaded successfully", true);
      })
      .catch((err) => {
        console.log(err, "errr");
        setAlert("error", "Request Failed", true);
      });
  }

  const removeSavedFiles = (id: number) => {
    let tempSavedFiles = filesDataVal;
    const index = tempSavedFiles.findIndex((item: any) => {
      return item?.id === id;
    });

    if (index > -1) {
      tempSavedFiles.splice(index, 1);
      filesDataVal = tempSavedFiles;
    }
    handleFileDelete(id);
  };

  useEffect(() => {
    handleFileChange(fileData);
  }, [fileData]);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        xl={4}
        lg={4}
        mt={1}
        direction="row"
      >
        <div style={{ display: "flex", width: "100%" }}>
          <Typography
            style={{ fontSize: "15px", fontStyle: "normal", fontWeight: "600" }}
            variant="inherit"
            align="left"
            color="#000"
          >
            {isFse
              ? "Upload Data Files"
              : onlyUploadFiles
              ? "Upload Data Files"
              : "Upload Files"}{" "}
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </Typography>
          <span className="example-spacer"> </span>
          <p className="messageuplodtxt"> Maximum file size 100MB</p>
        </div>

        <DragDrop
          data={fileData}
          availableFileCount={filesDataVal.length}
          dispatch={dispatch}
          fileTypes=".pdf,.xls,.xlsx, .doc, .docx, .ppt, .pptx"
          message="Supported formats DOC, DOCX, PDF, PPT, PPTX, XLS, XLSX"
          maxFileCount={5}
          savedFiles={filesDataVal}
        ></DragDrop>

        <Typography
          variant="inherit"
          align="left"
          color="red"
          fontSize={"12px"}
        >
          {uploadFilesError}
        </Typography>
        {filesDataVal.map((f: any) => {
          let icon = null;

          icon = ExcelFileIcon;

          if (f.attachedFileName.includes(".pdf")) {
            icon = PDFFileIcon;
          }
          if (
            f.attachedFileName.includes(".doc") ||
            f.attachedFileName.includes(".docx")
          ) {
            icon = WordFileIcon;
          }
          if (
            f.attachedFileName.includes(".ppt") ||
            f.attachedFileName.includes(".pptx")
          ) {
            icon = PPTFileIcon;
          }

          return (
            <div className={classes["dropped-files"]}>
              <div className="display-data-left">
                <div className={classes["img-container"]}>
                  <img
                    src={icon}
                    height="30"
                    width="30"
                    alt="Excel"
                    loading="lazy"
                    decoding="async"
                  ></img>
                </div>

                <div style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                  <Tooltip
                    title={f.attachedFileName}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10],
                            },
                          },
                        ],
                      },
                      tooltip: {
                        sx: {
                          fontSize: "13px",
                        },
                      },
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {f.attachedFileName}
                    </div>
                  </Tooltip>
                  <div>{f.fileSize}</div>
                </div>

                {/* <CloseIcon /> */}
              </div>
              <div className="display-data-right">
                {f.id > 0 ? (
                  <img
                    src={downloadIcon}
                    className={classes["remove-file"]}
                    height="30"
                    width="30"
                    alt="Close"
                    loading="lazy"
                    decoding="async"
                    onClick={(e) =>
                      DownloadFile(f.blobName, f.attachedFileName)
                    }
                  ></img>
                ) : (
                  ""
                )}
                <img
                  src={CloseIcon}
                  className={classes["remove-file"]}
                  height="30"
                  width="30"
                  alt="Close"
                  loading="lazy"
                  decoding="async"
                  onClick={(e) => removeSavedFiles(f.id)}
                ></img>
              </div>
            </div>
          );
        })}
      </Grid>
      {!onlyUploadFiles && (
        <Grid
          item
          xs={12}
          sm={12}
          md={6.98}
          xl={6.98}
          lg={6.98}
          mt={1}
        >
          <div className={classes["wrapper-txtarea"]}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "115%",
              }}
            >
              <p className="labels">Additional Description</p>
              <p
                className={"messageuplodtxt"}
                style={{
                  color: "#09c",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                500 character max.
              </p>
            </div>
            <Controller
              name={"projectDescription"}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <textarea
                  {...field}
                  style={{
                    width: "115%",
                    height: "117px",
                    borderRadius: "3px",
                    borderColor: error ? "red" : "#9F9F9F",
                    fontWeight: 400,
                    fontSize: "1rem",
                    lineHeight: "1.4375em",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontFamily: "Source Sans Pro",
                  }}
                ></textarea>
              )}
            />

            {selectedProject?.toLocaleLowerCase().trim() ===
              ShowAuditTypesOnTabConstants.Airnet.toLocaleLowerCase().trim() && (
              <Typography
                variant="inherit"
                align="left"
                color="#818689"
                fontSize={"12px"}
              >
                Note - Please add details on equipment model included:
                Compressors, Dryers, Others...
              </Typography>
            )}
            <Typography
              variant="inherit"
              align="left"
              color="red"
              fontSize={"12px"}
            >
              {commentsError}
            </Typography>
          </div>
        </Grid>
      )}
    </>
  );
}
