import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import VectorIcon from "./../assets/icons/Vector.svg";
import BackArrowIcon from "./../assets/icons/back_arrow-icon.svg";
import closeIcon from "./../assets/icons/close.svg";
import downloadIcon from "./../assets/icons/Download_icon.svg";
import { ReactComponent as UploadReportIcon } from "./../assets/icons/upload_report.svg";
import SavingsSaved from "./../assets/images/savings_saved.svg";
import Exclamation from "../assets/icons/Red_Exclaimation_mark.svg";
import classes from "./UploadFinishedReports.module.css";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import CustomSelect from "../common/components/CustomComponents/CustomSelect";
import CustomMultiSelectNew from "../common/components/CustomComponents/CustomMultiSelectNew";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import {
  AuditAirScanTypes,
  ShowAuditTypesOnTabConstants,
} from "../common/AuditTypeConstants";
import DropdownModel from "../common/interface/DropdownModel";
import CustomInputSelect from "../common/components/CustomComponents/CustomInputSelect";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FilesTable from "../common/components/CustomComponents/FilesTable";
import { TableColumn } from "../common/interface/TableColumn";
import AddRecommendationsdailog from "../common/components/CustomComponents/AddRecommendationsdailog";
import { AddProductRecommendation } from "../common/components/CustomComponents/AddProductRecommendation";
import { useSelector } from "react-redux";
import { RootState } from "../common/store";
import CustomUpload from "../common/components/CustomComponents/CustomUpload";
import State from "../common/interface/DragDrop";
import ConfirmDelete from "../common/components/CustomComponents/ConfirmDelete";
import CustomAlertTest from "../common/components/CustomComponents/CustomAlert";
import { RoutingConstants } from "../common/RoutingConstants";
import { hideLoader, showLoader } from "../common/store/loaderDataSlice";
import { CustomConfirmationDialogProps } from "./LinkProjectUpload";
import { showConfirmation } from "../common/store/confirmationBoxDataSlice";
import { useAppDispatch } from "../common/hooks/hooks";
import { RequestApi } from "../common/Constant";
import { useRequest } from "../services/agent";
import { IFamCode } from "./CreateNewUser";
import { ProjectSourceConstants } from "../common/ProjectSourceConstants";
import {
  addAllRecommendations,
  AuditRecommendations,
  deleteAllRecommendations,
} from "../common/store/addRecommendationsDataSlice";

export interface IAuditType {
  auditGroup: string;
  subGroups: DropdownModel[];
}

export default function UpdateOngoingProject() {
  const params = useParams();
  const selectList = useLoaderData() as any;
  const { auditId } = params;
  const [recomendationIndex, setRecommendationIndex] = useState<null | number>(
    null
  );
  const dispatchCancelPopup = useAppDispatch();
  const isEditReportPage = auditId && !isNaN(+auditId) && +auditId > 0;
  const multiselectRef = useRef<any | undefined>();
  const [savedFiles, setSavedFiles] = useState([]);
  const [savedProjectFiles, setSavedProjectFiles] = useState([]);
  const [deletedFileIds, setDeletedFileIds] = useState<number[]>([]);
  const [isFisnishedReport, setIsFinishedReport] = useState(false);
  const [deletedProjectFileIds, setDeletedProjectFileIds] = useState<number[]>(
    []
  );
  const [selectedProject, setSelectedProject] = useState<string | undefined>();
  const [selectedSubCategory, setSelectedSubCategory] = useState<any[]>([]);
  const [isAirQualityOnly, setIsAirQualityOnly] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [editData, setEditData] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const dispatchRecommendations = useAppDispatch();
  const [message, setMessage] = useState<string>("");
  const [recommendationList, setRecommendationList] = useState<
    AuditRecommendations[]
  >([]);
  let recommendationData = useSelector(
    (state: RootState) => state.auditRecommendationsReducer.recommendations
  );

  const [typeOfMeasurementsList, setTypeOfMeasurements] = useState<
    DropdownModel[]
  >([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const request = useRequest();
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
  }

  const customerFields = [
    {
      name: "Account ID",
      data: "c4CId",
    },
    {
      name: "Customer Name",
      data: "customerName",
    },
    {
      name: "Country",
      data: "country",
    },
    {
      name: "Address",
      data: "address",
    },
    {
      name: "Responsible Person",
      data: "responsible",
    },
  ];

  let auditGroupTypes = useMemo(
    () =>
      selectList?.typeOfAuditList
        .filter(
          (auditType: any) =>
            auditType.auditGroup.toLowerCase() ===
            ShowAuditTypesOnTabConstants.Air_Scan.toLocaleLowerCase()
        )
        .map((auditType: any) => ({
          ...auditType,
          auditGroup: ShowAuditTypesOnTabConstants.Air_Scan,
        })) as IAuditType[],
    [selectList]
  );

  let mappedData: DropdownModel[] = auditGroupTypes?.map(
    (item: IAuditType, index) => {
      return { text: item.auditGroup, valueInt: index + 1, value: "" };
    }
  );

  const uploadReportIcon = (
    <div className={classes["upload-icon"]}>
      <SvgIcon component={UploadReportIcon}></SvgIcon>
    </div>
  );

  const subCategories = auditGroupTypes
    ?.filter(
      (auditType) =>
        auditType.auditGroup.toLowerCase() ===
        ShowAuditTypesOnTabConstants.Air_Scan.toLocaleLowerCase()
    )[0]
    .subGroups.map((subGroup) => ({
      cat: subGroup.valueInt,
      key: subGroup.text,
    }));

  const validationSchema = Yup.object().shape({
    typesOfMeasurements: Yup.array().min(1, "This field is required"),
    auditName: Yup.string()
      .required("This field is required.")
      .max(80, "Maximum 80 Characters are allowed")
      .min(2, "Must include minumum 2 characters")
      .matches(/^[a-z\d\-_\s]+$/i, "Special Characters are not allowed"),
    measurementsWithQuantity: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        id: Yup.number(),
        typeOfMeasurementId: Yup.number(),
        qty: Yup.string()
          .required("Quantity is required")
          .matches(/^\d+$/, "Only numbers are allowed"),
      })
    ),
    categoryOfProject: Yup.number().required("This field is required"),
    subCategory: Yup.array().min(1, "This field is required"),
    co2Factor: isAirQualityOnly
      ? Yup.string().nullable()
      : Yup.string().required("This field is required"),
    uploadedFiles:
      savedFiles?.length === 0
        ? Yup.array().min(1, "This field is required")
        : Yup.array(),
    uploadedProjectFiles:
      savedProjectFiles?.length === 0
        ? Yup.array().min(1, "This field is required")
        : Yup.array(),
    projectDescription: Yup.string()
      .max(500, "Maximum 500 Characters are allowed")
      .nullable(),
    famCodeId: Yup.number()
      .min(1, "This field is required")
      .required("This field is required"),
    co2Unit: isAirQualityOnly
      ? Yup.number().nullable()
      : Yup.number()
          .min(1, "This field is required")
          .required("This field is required"),
    energyUnitId: Yup.number().notRequired(),
    countryId: Yup.number().required("This field is required"),
    electricityCost: isAirQualityOnly
      ? Yup.string().nullable()
      : Yup.number()
          .transform((value, originalValue) => {
            return originalValue === "" ? undefined : value;
          })
          .min(0, "The value must be 0 or more.")
          .required("This field is required."),
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues: {
      categoryOfProject: 1,
      typesOfMeasurements: [] as string[],
    },
  });

  const control = methods.control;

  const { fields, append, remove } = useFieldArray({
    name: "measurementsWithQuantity",
    control,
  });

  let selectedTypesOfMeasurements = methods.watch(
    "typesOfMeasurements"
  ) as string[];
  let comments = methods.watch("projectDescription") as string;
  const selectedCountriesId = methods.getValues("countryId");

  function onCloseCancelSave() {
    dispatchCancelPopup(hideLoader());
  }

  const famCodeOfSelectedCountries = useMemo(
    () =>
      selectList?.famCodesList?.filter(
        (famcode: IFamCode) => selectedCountriesId == famcode.countryId
      ),
    [selectedCountriesId, selectList]
  );

  const currencyCodeOfSelectedCountries = useMemo(
    () =>
      selectList?.countriesList?.find((country: any) => {
        return selectedCountriesId == country.valueInt;
      }),
    [selectedCountriesId, selectList]
  );

  function onConfirmCancelSave() {
    goBack();
    onCloseCancelSave();
  }

  const handleCancelClickOpen = (event: any) => {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content:
        "If you proceed, you will lose all your entered data, unsaved changes may be lost.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: onConfirmCancelSave,
    };
    dispatchCancelPopup(showConfirmation(customConfirmationDialogProps));
    dispatchCancelPopup(showLoader());
  };
  const handleTypeOfMeasurementChange = (
    event: SelectChangeEvent<typeof selectedTypesOfMeasurements>
  ) => {
    const {
      target: { value },
    } = event;
    let a = typeof value === "string" ? value.split(",") : value;
    console.log(a);
    var index = a?.indexOf("0");
    if (index && index >= 0) {
      a?.splice(index, 1);
    }
    methods.setValue("typesOfMeasurements", a);
    methods.trigger("typesOfMeasurements");
  };

  const getTypeOfMeasurementSelectedText = (id: string): string => {
    let selectedText = typeOfMeasurementsList?.find((e) => e.valueInt === +id);
    if (selectedText) {
      return selectedText.text;
    } else {
      return "";
    }
  };

  function handleMultiSelectChange(subCategoryList: any, addSubCategory: any) {
    let result: any[] = [];
    if (addSubCategory.cat === AuditAirScanTypes.Energy_Potential_PreSales) {
      if (
        selectedSubCategory?.length > 0 &&
        selectedSubCategory?.length > subCategoryList?.length
      ) {
        setSelectedSubCategory([]);
      } else {
        result = [addSubCategory.cat];
        setSelectedSubCategory([addSubCategory]);
      }
    } else {
      if (
        subCategoryList[0]?.cat === AuditAirScanTypes.Energy_Potential_PreSales
      ) {
        result = [addSubCategory.cat];
        addSubCategory.cat === AuditAirScanTypes.Air_Quality
          ? setIsAirQualityOnly(true)
          : setIsAirQualityOnly(false);
        setSelectedSubCategory([addSubCategory]);
      } else {
        subCategoryList.length === 1 &&
        subCategoryList[0]?.cat === AuditAirScanTypes.Air_Quality
          ? setIsAirQualityOnly(true)
          : setIsAirQualityOnly(false);
        result = subCategoryList.map((item: any) => item.cat);
        setSelectedSubCategory([...subCategoryList]);
      }
    }
    methods.setValue("subCategory", result);
    filterTypesOfMeasurements(result);
    methods.trigger("subCategory");
  }

  const filterTypesOfMeasurements = (typeOfAudits: any[]) => {
    var typeOfMeasurements = selectList?.typeOfMeasurementsList?.filter(
      (itm: any) => {
        return typeOfAudits.some((e) => {
          return itm.auditTypeId === e;
        });
      }
    );

    let selectMeasurementList: DropdownModel[] = typeOfMeasurements?.map(
      (item: any) => {
        return { text: item.name, valueInt: item.id };
      }
    );
    setTypeOfMeasurements(selectMeasurementList);

    //to retain selected measurements if it corresponds to selected auditIds

    const availableSelectedMeasurements = methods.getValues(
      "typesOfMeasurements"
    );
    const measurementsForSelectedAudits = availableSelectedMeasurements?.filter(
      (value: any) => {
        const isAvailable =
          selectMeasurementList.filter(
            (measurement) =>
              measurement.valueInt.toString() === value.toString()
          ).length === 1;
        if (isAvailable) return value;
      }
    );
    methods.setValue("typesOfMeasurements", measurementsForSelectedAudits);
  };

  async function DownloadFile(blobName: string, fileName: string) {
    var body = {
      blobName: blobName,
      fileName: fileName,
    };

    request({
      url: `${RequestApi.POST_DOWNLOAD_FILE}`,
      method: "post",
      data: body,
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setAlert("success", "Downloaded successfully", true);
      })
      .catch((err) => {
        console.log(err, "errr");
        setAlert("error", "Request Failed", true);
      });
  }

  const onSubmit = (data: any) => {
    if (comments?.length > 500) {
      return;
    }
    if (recommendationData?.length === 0) {
      setAlert("error", "At least one recommendation is required", true);
      return;
    }

    const obj = {
      id: auditId ? auditId : 0,
      typeOfAuditId: data?.subCategory,
      auditName: data.auditName,
      electricityCost: data.electricityCost,
      currencyId: data?.countryId,
      energyUnitId: data.energyUnitId,
      cO2Factor: data.co2Factor,
      cO2UnitId: data.co2Unit,
      typeOfMeasurementIds: data.typesOfMeasurements,
      reports: data.uploadedFiles,
      projectFiles: data.uploadedProjectFiles,
      typeOfMeasurementDetails: data.measurementsWithQuantity,
      deletedReportIds: deletedFileIds,
      deletedProjectFileIds: deletedProjectFileIds,
      famCodeId: data.famCodeId,
      famCode: famCodeOfSelectedCountries
        .filter((item: any) => item.valueInt == data.famCodeId)[0]
        ?.text.split(" ")[0],
      projectSource: ProjectSourceConstants.OngoingProject,
      projectDescription: methods.getValues("projectDescription"),
      recomendations: recommendationData,
    };

    request({
      url: RequestApi.EDIT_ONGOING_PROJECT_BY_NON_FSE,
      method: "post",
      data: { request: obj },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      maxContentLength: 200 * 1024 * 1024,
    })
      .then((resp) => {
        setMessage(
          `Project data updated successfully!  Project ID : ${resp.data.id}`
        );
        setOpenSuccessDialog(true);
        dispatchRecommendations(deleteAllRecommendations());
      })
      .catch((err) => {
        console.log(err);
        setAlert("error", "Request Failed", true);
      });
  };

  const getCO2UnitList = useMemo(
    () => selectList?.cO2UnitList as DropdownModel[],
    [selectList]
  );

  function handleCo2InputChange(data: any) {
    methods.setValue("co2Factor", data.target.value);
  }

  const customInputSelections = [
    {
      function: (data: any) =>
        methods.setValue("electricityCost", data.target.value),
      label: "Electricity Cost",
      option: [currencyCodeOfSelectedCountries?.currencyCode + "/kWh"],
      name: "electricityCost",
      numberOnly: true,
      unitName: "energyUnitId",
      hide: isAirQualityOnly,
    },
    {
      function: handleCo2InputChange,
      label: "CO2 Factor",
      option: getCO2UnitList,
      name: "co2Factor",
      numberOnly: true,
      unitName: "co2Unit",
      hide: isAirQualityOnly,
    },
  ];

  const loadAuditDetailsById = (): void => {
    request({
      url: `${RequestApi.GET_ONGOING_PROJECT_FOR_NON_FSE_BY_ID}?id=` + auditId,
      method: "get",
    })
      .then((resp) => {
        setEditData(resp.data);
        setIsFinishedReport(
          resp.data.projectSource === ProjectSourceConstants.UploadProject
        );
        dispatchRecommendations(
          addAllRecommendations(resp.data.recomendations)
        );
        methods.setValue("projectDescription", resp.data.projectDescription);
        setSavedFiles(resp.data.reports);
        setSavedProjectFiles(resp.data.projectFiles);
        methods.setValue("auditName", resp.data.auditName);
        if (resp.data.cO2Factor)
          methods.setValue("co2Factor", resp.data.cO2Factor);
        methods.setValue(
          "co2Unit",
          resp.data.cO2UnitId ? resp.data.cO2UnitId : 1
        );
        methods.setValue("electricityCost", resp.data.electricityCost);
        methods.setValue("energyUnitId", resp.data.energyUnitId);
        const countryId = selectList?.famCodesList?.filter(
          (famcode: IFamCode) => resp.data.famCodeId === famcode.id
        )[0]?.countryId;

        auditGroupTypes.map((item: IAuditType) => {
          var filtered = item.subGroups?.filter((item) => {
            return item.valueInt === resp.data.typeOfAuditId[0];
          });

          if (filtered?.length > 0) {
            let selectedGroup = mappedData?.find(
              (e) => e.text === item.auditGroup
            );
            var filteredValue = item.subGroups?.filter((item1) =>
              resp.data.typeOfAuditId.some(
                (item2: any) => item1.valueInt === item2
              )
            );
            let val = filteredValue?.map((item: any, index) => {
              return { cat: item.valueInt, key: item.text };
            });

            if (
              selectedGroup?.text.toLowerCase() ==
                ShowAuditTypesOnTabConstants.Air_Scan.toLowerCase() &&
              val !== undefined
            ) {
              if (
                val?.length == 1 &&
                val[0]?.cat == AuditAirScanTypes.Air_Quality
              ) {
                setIsAirQualityOnly(true);
              }
              setSelectedSubCategory(val);
              methods.setValue("subCategory", resp.data.typeOfAuditId);
              filterTypesOfMeasurements(resp.data.typeOfAuditId);
            }
          }
        });
        methods.setValue("countryId", countryId);
        methods.setValue("famCodeId", resp.data.famCodeId);

        methods.setValue(
          "typesOfMeasurements",
          resp.data.typeOfMeasurementIds.map((id: number) => id.toString())
        );
        methods.trigger("typesOfMeasurements");
        methods.setValue(
          "measurementsWithQuantity",
          resp.data.typeOfMeasurementDetails
        );
        methods.trigger("measurementsWithQuantity");
        methods.setValue("projectDescription", resp.data.projectDescription);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setAlert("error", err.response.data.result.Id[0], true);
        } else {
          setAlert("error", "Request Failed", true);
        }
      });
  };

  useEffect(() => {
    const newVal = selectedTypesOfMeasurements.length;

    if (newVal === 0) {
      remove();
    } else {
      let oldMeasurementIds: string[] = [];
      fields.filter((filter, i) => {
        if (filter.name === undefined) {
          remove(i);
        } else if (
          selectedTypesOfMeasurements.includes(
            String(filter.typeOfMeasurementId)
          )
        ) {
          oldMeasurementIds.push(String(filter.typeOfMeasurementId));
        } else {
          remove(i);
          let lst = methods.getValues("measurementsWithQuantity");
          let index = lst?.findIndex((itm) => {
            return itm.typeOfMeasurementId === filter.typeOfMeasurementId;
          });

          if (index !== undefined && index !== -1) {
            methods.setValue("measurementsWithQuantity", lst?.splice(index, 1));
          }
        }
      });

      let newMeasurementIds = selectedTypesOfMeasurements.filter(
        (x) => !oldMeasurementIds.includes(x)
      );

      if (newMeasurementIds.length !== 0) {
        for (let i = 0; i < newMeasurementIds.length; i++) {
          const name = getTypeOfMeasurementSelectedText(
            newMeasurementIds[i].toString()
          );
          if (name.length > 0) {
            append({
              name,
              qty: "1",
              typeOfMeasurementId: +newMeasurementIds[i],
            });
          }
        }
      }
    }
  }, [selectedTypesOfMeasurements]);

  async function mapFiles(fileData: State) {
    let filecollection: File[] = [];
    methods.setValue("uploadedFiles", []);

    if (fileData.fileList && fileData.fileList?.length > 0) {
      for (let i = 0; i < fileData.fileList.length; i++) {
        const file = fileData.fileList[i];
        filecollection.push(file);
      }

      methods.setValue("uploadedFiles", filecollection);

      methods.trigger("uploadedFiles");
    }
  }
  async function mapProjectFiles(fileData: State) {
    let filecollection: File[] = [];
    methods.setValue("uploadedProjectFiles", []);

    if (fileData.fileList && fileData.fileList?.length > 0) {
      for (let i = 0; i < fileData.fileList.length; i++) {
        const file = fileData.fileList[i];
        filecollection.push(file);
      }

      methods.setValue("uploadedProjectFiles", filecollection);

      methods.trigger("uploadedProjectFiles");
    }
  }

  function handleCommentChange(e: any) {
    methods.setValue("projectDescription", e.target.value);
  }

  function handleFileDelete(id: number) {
    setDeletedFileIds((oldDeletedFileIds) => [...oldDeletedFileIds, id]);
  }
  function handleProjectFileDelete(id: number) {
    setDeletedProjectFileIds((oldDeletedFileIds) => [...oldDeletedFileIds, id]);
  }

  const goBack = () => {
    navigate(
      "/" +
        (isFisnishedReport
          ? RoutingConstants.FinishedReports
          : RoutingConstants.OngoingProject)
    );
  };

  useEffect(() => {
    setRecommendationList(recommendationData);
  }, [recommendationData]);

  useEffect(() => {
    loadAuditDetailsById();
  }, [auditId]);

  return (
    <div>
      <Box
        sx={{
          boxShadow: 0,
          padding: "1rem 1rem",
          display: "flex",
          alignItems: "center",
          height: "66px",
          border: "none",
          backgroundColor: "#fff",
          marginBottom: ".675rem",
        }}
      >
        <div
          className={classes["page-title-container"]}
          style={{ width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              src={BackArrowIcon}
              height="20"
              alt="Back"
              style={{ cursor: "pointer" }}
              loading="lazy"
              decoding="async"
              onClick={goBack}
            />
            <div className={classes["page-title"]}>
              Update{isFisnishedReport ? "" : " Ongoing"} Project
            </div>
          </div>
          {isEditReportPage && (
            <div
              className={`${classes["name-auditId-container"]} ${classes["bg-blue"]}`}
              style={{}}
            >
              <div
                className={classes["customer-name"]}
                style={{ padding: "8px" }}
              >
                <span className={classes["fw-600"]}>Project ID:</span>
                {isFisnishedReport ? editData.auditNumber : auditId}
              </div>
            </div>
          )}
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          height: "calc(100vh - 200px)",
          overflowY: "scroll",
          border: "unset",
        }}
      >
        <Box
          sx={{
            border: "unset",
            boxShadow: "none",
            marginBottom: "1.5rem",
            padding: "1rem 1.5rem 0 1.5rem",
          }}
        >
          <Typography
            fontWeight={600}
            fontSize={"1.125rem"}
            marginBottom={".75rem"}
          >
            Customer Details
          </Typography>
          <Box
            sx={{ border: "unset", boxShadow: "none" }}
            display={"grid"}
            gridTemplateColumns={"12% 24% 14% 29% 21%"}
          >
            {customerFields.map((field, index) => {
              const value = editData ? editData[field.data] : "";
              return (
                <Box
                  sx={{
                    border: "unset",
                    boxShadow: "none",
                    borderRight:
                      index === customerFields.length - 1
                        ? ""
                        : "1px solid #9F9F9F",
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"1rem"}
                  paddingRight={"1.5rem"}
                  width={"100%"}
                  paddingLeft={index === 0 ? "unset" : "1.5rem"}
                >
                  <Typography fontWeight={600}>{field.name}</Typography>
                  <Typography>{value ? value : "N/A"}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            style={{
              padding: "1rem 1.5rem 0 1.5rem",
            }}
          >
            <Grid
              className="grid-container"
              container
              spacing={2}
              rowSpacing={2}
            >
              <CustomSelect
                name={"categoryOfProject"}
                label={"Category of Project"}
                setValue={methods.setValue}
                isRequired
                options={mappedData}
                disabled={isEditReportPage ? true : false}
                md={4}
              />
              <CustomMultiSelectNew
                name={"subCategory"}
                label={"Sub Category Of Project"}
                md={4}
                options={subCategories}
                icon={VectorIcon}
                multiselectRef={multiselectRef}
                handleChange={handleMultiSelectChange}
                placeholder={"Select Sub Category Of Project"}
                selectedValues={selectedSubCategory}
                disabled={isFisnishedReport}
              />
              <CustomSelect
                name={"famCodeId"}
                label={"FAM Code"}
                setValue={methods.setValue}
                isRequired={true}
                options={famCodeOfSelectedCountries}
                disabled
              />
              {customInputSelections?.map(
                (item, index) =>
                  !item.hide && (
                    <CustomInputSelect
                      label={item.label}
                      options={item.option}
                      md={4}
                      handleInputChange={item.function}
                      name={item.name}
                      unitName={item.unitName}
                      numberOnly={item.numberOnly}
                      isRequired={true}
                    />
                  )
              )}
              <CustomInput
                isRequired={true}
                name={"auditName"}
                numberOnly={false}
                label={"Project Name"}
                variant="outlined"
                md={4}
                fullWidth
              />
            </Grid>
            <Grid
              className="grid-container"
              container
              spacing={2}
              rowSpacing={2}
            >
              <Grid
                item
                xs={12}
                md={12}
                xl={4}
                lg={4}
                direction="row"
              >
                <FormControl fullWidth>
                  <FormLabel className="labels">
                    Type of Measurements
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </FormLabel>
                  <Controller
                    name={"typesOfMeasurements"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        style={{ height: "33px" }}
                        {...methods.register("typesOfMeasurements")}
                        multiple
                        displayEmpty
                        value={field.value}
                        onChange={handleTypeOfMeasurementChange}
                        fullWidth
                        MenuProps={MenuProps}
                        renderValue={(selected) => {
                          if (selected?.length === 0) {
                            return (
                              <p className="grey-text">
                                Select Type of Measurements
                              </p>
                            );
                          }
                          return selected
                            ?.map((p: any) =>
                              getTypeOfMeasurementSelectedText(p)
                            )
                            .join(", ")
                            .substring(0, 50);
                        }}
                      >
                        <MenuItem
                          key="0"
                          value=""
                          disabled={true}
                        >
                          Select Type of Measurements
                        </MenuItem>
                        {typeOfMeasurementsList?.map((option) => (
                          <MenuItem
                            disableRipple
                            key={option.valueInt}
                            value={option.valueInt.toString()}
                          >
                            <Checkbox
                              checked={selectedTypesOfMeasurements?.includes(
                                option.valueInt.toString()
                              )}
                            />
                            <ListItemText primary={option.text} />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Typography
                    sx={{
                      color: "var(--primary-color)",
                      fontSize: "14px",
                      fontStyle: "italic",
                      fontWeight: "400",
                    }}
                  >
                    You can select multiple measurement
                  </Typography>
                  <Typography
                    variant="inherit"
                    align="left"
                    color="red"
                    fontSize={"12px"}
                  >
                    {methods.formState.errors["typesOfMeasurements"]
                      ? (methods.formState.errors["typesOfMeasurements"]
                          ?.message as string)
                      : undefined}
                  </Typography>
                </FormControl>
              </Grid>
              {selectedTypesOfMeasurements.length > 0 && (
                <Grid
                  className="containergrid"
                  xs={12}
                  sm={12}
                  md={12}
                  xl={8}
                  lg={8}
                  item
                  container
                >
                  <Box
                    border={"none"}
                    maxHeight={"400px"}
                    width={"100%"}
                    boxShadow={"none"}
                    marginBottom={"1rem"}
                  >
                    <Grid
                      container
                      spacing={2}
                      rowSpacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <>
                        <Grid
                          xs={6}
                          sm={6}
                          md={6}
                          xl={6}
                          lg={6}
                          item
                        >
                          {fields.length > 0 &&
                            fields[0].typeOfMeasurementId && (
                              <FormLabel className="labels">
                                Measurements
                              </FormLabel>
                            )}
                        </Grid>
                        <Grid
                          xs={6}
                          sm={6}
                          md={6}
                          xl={6}
                          lg={6}
                          item
                        >
                          {fields.length > 0 &&
                            fields[0].typeOfMeasurementId && (
                              <FormLabel
                                className="labels"
                                sx={{ position: "relative", left: "-5px" }}
                              >
                                Quantity
                              </FormLabel>
                            )}
                        </Grid>
                        <Box
                          border={"none"}
                          maxHeight={"400px"}
                          height={"100%"}
                          sx={{ overflowY: "auto" }}
                          width={"100%"}
                          boxShadow={"none"}
                        >
                          <Grid
                            container
                            spacing={2}
                            rowSpacing={2}
                            paddingLeft={"24px"}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {fields.map(
                              (item, i) =>
                                item.name !== undefined && (
                                  <>
                                    <Grid
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      xl={6}
                                      lg={6}
                                      item
                                    >
                                      <TextField
                                        variant="outlined"
                                        label=""
                                        {...methods.register(
                                          `measurementsWithQuantity.${i}.name`
                                        )}
                                        name={`measurementsWithQuantity.${i}.name`}
                                        disabled
                                      ></TextField>

                                      <Typography
                                        variant="inherit"
                                        align="left"
                                        color="red"
                                        fontSize={"12px"}
                                      >
                                        {
                                          methods.formState.errors
                                            .measurementsWithQuantity?.[i]?.name
                                            ?.message
                                        }
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      xl={6}
                                      lg={6}
                                      item
                                    >
                                      <TextField
                                        variant="outlined"
                                        label=""
                                        {...methods.register(
                                          `measurementsWithQuantity.${i}.qty`
                                        )}
                                        name={`measurementsWithQuantity.${i}.qty`}
                                        onKeyDown={(event: any) => {
                                          const isNumeric = /^[0-9]*$/.test(
                                            event.key
                                          );
                                          if (
                                            (!isNumeric &&
                                              event.key !== "Tab" &&
                                              event.key !== "." &&
                                              event.key !== "Backspace") ||
                                            (event.key === "." &&
                                              (
                                                event.target as HTMLInputElement
                                              ).value.includes("."))
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                      ></TextField>
                                      <Typography
                                        variant="inherit"
                                        align="left"
                                        color="red"
                                        fontSize={"12px"}
                                      >
                                        {
                                          methods.formState.errors
                                            .measurementsWithQuantity?.[i]?.qty
                                            ?.message
                                        }
                                      </Typography>
                                    </Grid>
                                  </>
                                )
                            )}
                          </Grid>
                        </Box>
                      </>
                    </Grid>
                  </Box>
                </Grid>
              )}
              {/* <FilesTable
                columns={columns}
                data={dummyData}
                actionButton={actionButton}
              /> */}
              <AddProductRecommendation
                open={open}
                setOpen={setOpen}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditReportPage={isEditReportPage}
                needMarginTop={false}
                isOngoingEditPage={true}
                setRecommendationIndex={setRecommendationIndex}
                currencyText={currencyCodeOfSelectedCountries?.currencyCode}
              />
              <CustomUpload
                handleFileChange={mapFiles}
                commentHandler={handleCommentChange}
                handleFileDelete={handleFileDelete}
                uploadFilesError={
                  savedFiles?.length === 0
                    ? methods.formState.errors.uploadedFiles?.message
                    : ""
                }
                commentsError={
                  comments?.length > 500
                    ? "Maximum 500 Characters are allowed"
                    : ""
                }
                filesDataVal={savedFiles}
                selectedProject={selectedProject}
              />
              <CustomUpload
                handleFileChange={mapProjectFiles}
                commentHandler={handleCommentChange}
                handleFileDelete={handleProjectFileDelete}
                uploadFilesError={
                  savedFiles?.length === 0
                    ? methods.formState.errors.uploadedProjectFiles?.message
                    : ""
                }
                commentsError={
                  comments?.length > 500
                    ? "Maximum 500 Characters are allowed"
                    : ""
                }
                filesDataVal={savedProjectFiles}
                selectedProject={selectedProject}
                onlyUploadFiles={true}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              xl={12}
              lg={12}
              direction="row"
              justifyContent="flex-end"
              p={1}
              marginBottom={"2rem"}
            >
              <Button
                sx={{ textTransform: "none" }}
                className="cancel-button custom-buttons"
                disableRipple
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginRight: "15px",
                }}
                variant="outlined"
                onClick={handleCancelClickOpen}
              >
                Cancel
              </Button>
              <Button
                style={{ fontSize: "18px", fontWeight: "400" }}
                type="submit"
                className="custom-buttons"
                startIcon={uploadReportIcon}
                sx={{ textTransform: "none" }}
                variant="contained"
                disableRipple
                size="medium"
              >
                {isEditReportPage ? "Update Project" : "Upload Project Data"}
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <AddRecommendationsdailog
        open={open}
        onClose={() => {
          setOpen(false);
          setRecommendationIndex(null);
        }}
        isEdit={isEdit}
        selectedRecommendation={
          recomendationIndex !== null
            ? recommendationData[recomendationIndex]
            : undefined
        }
        fromOpportunity={false}
        auditRecommendationId={0}
        currency={[
          {
            ...currencyCodeOfSelectedCountries,
            text: currencyCodeOfSelectedCountries?.currencyCode,
          },
        ]}
      />
      <DeleteSuccessDailog
        open={openSuccessDialog}
        onClose={() => {
          setOpenSuccessDialog(false);
          navigate("/" + RoutingConstants.FinishedReports);
        }}
        message={message}
      />
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={() => setAlertOpen(false)}
      />
    </div>
  );
}

interface DeleteSuccessDailogProps {
  open: boolean;
  onClose: () => any;
  message: string;
  iconImg?: string;
}
export function DeleteSuccessDailog(props: DeleteSuccessDailogProps) {
  const { onClose, open, message, iconImg } = props;

  const close = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
          },
        }}
        onClose={close}
        open={open}
      >
        <div
          className="iconimg"
          style={{ paddingTop: "1rem" }}
        >
          <img
            onClick={close}
            src={iconImg ? iconImg : SavingsSaved}
            height="35"
            alt="saved-icon"
            loading="lazy"
            decoding="async"
          />
        </div>

        <div style={{ padding: "10px 20px 20px 20px", textAlign: "center" }}>
          <ConfirmDelete
            message={message}
            style={{
              fontFamily: "inherit",
              textWrap: "balance",
              width: "400px",
            }}
            textCss="bold-message-report"
            CloseDeleteDailog={close}
            CloseRecommendationDailog={close}
            btnText="Okay"
            btnCss="cancel-button custom-buttons"
            hideCancel={false}
            variantCSS="outlined"
          />
        </div>
      </Dialog>
    </>
  );
}
