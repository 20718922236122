import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomTable from "../../common/components/CustomComponents/CustomTable";
import dayjs from "dayjs";
import { IAirAuditData } from "../FinishedReports/FinishedReports";
import { useRequest } from "../../services/agent";
import { RequestApi } from "../../common/Constant";
import {
  DatePickerMode,
  TableColumn,
} from "../../common/interface/TableColumn";
import { dropDownData } from "./GeneratedLeads";

interface IAirNetAuditData extends IAirAuditData {}

export default function AirNetLeads({
  airNetAuditFilters,
  airNetSorting,
}: any) {
  const [airNetAuditData, setAirNetAuditData] = useState<
    Array<IAirNetAuditData>
  >([]);

  const [airNetAuditTotalRecords, setAirNetAuditTotalRecords] =
    useState<number>(0);
  const [getAirNetData, setGetAirNetData] = useState(false);

  const [pageSpecificData, setPageSpecificData] = useState<{
    leadStausUpdatedOn: "string";
  }>();

  const {
    airNetAuditFilterLeadId,
    setAirNetAuditFilterLeadId,
    airNetAuditFilterAuditId,
    setAirNetAuditAuditId,
    airNetAuditFilterStatus,
    setAirNetAuditFilterStatus,
    airNetAuditFilterCustomerName,
    setAirNetAuditFilterCustomerName,
    airNetAuditFilterUploadReportBy,
    setAirNetAuditFilterUploadReportBy,
    airNetAuditPage,
    setAirNetAuditPage,
    airNetAuditFilterLeadCreateDate,
    setAirNetAuditFilterLeadCreateDate,
    airNetAuditFilterLeadResponsible,
    setAirNetAuditFilterLeadResponsible,
    airNetAuditFilterLocalLeadCreateDate,
    setAirNetAuditFilterLocalLeadCreateDate,
  } = airNetAuditFilters;

  const {
    airNetAuditSortingColumn,
    setAirNetAuditSortingColumn,
    airNetAuditSortingOrder,
    setAirNetAuditSortingOrder,
  } = airNetSorting;

  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    // handleCloseDialog();
  }
  const request = useRequest();

  const airNetAuditfilterObject: any = useMemo(
    () => ({
      auditNumber: airNetAuditFilterAuditId,
      leadId: airNetAuditFilterLeadId ? airNetAuditFilterLeadId : "",
      customerName: airNetAuditFilterCustomerName,
      leadStatus: airNetAuditFilterStatus,
      uploadReportBy: airNetAuditFilterUploadReportBy,
      leadCreatedDate: dayjs(airNetAuditFilterLocalLeadCreateDate).isValid()
        ? dayjs(airNetAuditFilterLocalLeadCreateDate)
        : "",
      leadResponsiblePerson: airNetAuditFilterLeadResponsible,
    }),

    [
      airNetAuditFilterAuditId,
      airNetAuditFilterCustomerName,
      airNetAuditFilterUploadReportBy,
      airNetAuditFilterLeadId,
      airNetAuditFilterStatus,
      airNetAuditFilterLeadResponsible,
      airNetAuditFilterLocalLeadCreateDate,
    ]
  );

  const loadAirNetAuditData = useCallback((): void => {
    let body = {
      page: airNetAuditPage,
      pageSize: 20,
      sort: airNetAuditSortingColumn,
      order: airNetAuditSortingOrder,
      auditId: airNetAuditFilterAuditId ? airNetAuditFilterAuditId : "",
      leadStatus: airNetAuditFilterStatus
        ? dropDownData.filter(
            (data) => data.valueInt === airNetAuditFilterStatus
          )[0].value
        : "",
      leadId: airNetAuditFilterLeadId ? airNetAuditFilterLeadId : 0,
      customerName: airNetAuditFilterCustomerName
        ? airNetAuditFilterCustomerName
        : "",
      uploadReportBy: airNetAuditFilterUploadReportBy
        ? airNetAuditFilterUploadReportBy
        : "",
      leadCreatedDate: airNetAuditFilterLeadCreateDate
        ? airNetAuditFilterLeadCreateDate === "clear"
          ? ""
          : airNetAuditFilterLeadCreateDate
        : "",
      leadResponsiblePerson: airNetAuditFilterLeadResponsible
        ? airNetAuditFilterLeadResponsible
        : "",
    };

    request({
      url: RequestApi.GET_AIR_NET_AUDIT_GENERATED_LEADS,
      method: "post",
      data: body,
    })
      .then((resp) => {
        setAirNetAuditData(resp.data.data);

        setPageSpecificData({
          leadStausUpdatedOn: resp.data.leadStausUpdatedOn,
        });
        setAirNetAuditTotalRecords(resp.data.totalRecord);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_RESPONSE") {
          console.log(err);
          setAlert("error", "Request Failed", true);
        } else {
          console.log(err);
          setAlert("error", "Request Failed", true);
        }
      });
  }, [
    airNetAuditPage,
    airNetAuditSortingColumn,
    airNetAuditSortingOrder,
    airNetAuditFilterAuditId,
    airNetAuditFilterStatus,
    airNetAuditFilterLeadId,
    airNetAuditFilterCustomerName,
    airNetAuditFilterUploadReportBy,
    airNetAuditFilterLeadCreateDate,
    airNetAuditFilterLeadResponsible,
    request,
  ]);

  const handleAirNetFilterChange = (id: any, event: any) => {
    switch (id) {
      case "auditNumber":
        setAirNetAuditAuditId(event.target.value);
        break;
      case "leadId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setAirNetAuditFilterLeadId(null);
        } else {
          setAirNetAuditFilterLeadId(event.target.value);
        }
        break;
      case "customerName":
        setAirNetAuditFilterCustomerName(event.target.value);
        break;
      case "leadStatus":
        setAirNetAuditFilterStatus(event.target.value);
        break;
      case "uploadReportBy":
        setAirNetAuditFilterUploadReportBy(event.target.value);
        break;
      case "leadCreatedDate":
        if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
          setAirNetAuditFilterLeadCreateDate(
            dayjs(event.$d).format("DD/MM/YYYY")
          );
          setAirNetAuditFilterLocalLeadCreateDate(event.$d);
        } else {
          setAirNetAuditFilterLeadCreateDate("");
          setAirNetAuditFilterLocalLeadCreateDate("");
        }
        break;
      case "leadResponsiblePerson":
        setAirNetAuditFilterLeadResponsible(event.target.value);
        break;
    }
  };
  const clearAllAirAuditSorting = useCallback(() => {
    setAirNetAuditPage(0);
    setAirNetAuditSortingColumn("");
    setAirNetAuditSortingOrder("");
    setAirNetAuditAuditId("");
    setAirNetAuditFilterLeadId(null);
    setAirNetAuditFilterStatus(0);
    setAirNetAuditFilterCustomerName("");
    setAirNetAuditFilterUploadReportBy("");
    setAirNetAuditFilterLeadCreateDate("");
    setAirNetAuditFilterLocalLeadCreateDate("");
    setAirNetAuditFilterLeadResponsible("");
    setGetAirNetData(true);
  }, []);

  let airNetAuditColumns: TableColumn[] = [
    {
      id: "auditNumber",
      label: "Project ID",
      align: "left",
      minWidth: 110,
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "leadStatus",
      label: "Status",
      align: "left",
      minWidth: 100,
      filter: true,
      filterType: "dropdown",
      dropdownData: dropDownData,
    },
    {
      id: "leadId",
      label: "Lead ID",
      minWidth: 100,
      align: "left",
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "customerName",
      label: "Customer Name",
      minWidth: 300,
      align: "left",
      filter: true,
      sorting: true,
    },
    {
      id: "uploadReportBy",
      label: "Uploaded Project by",
      minWidth: 140,
      align: "left",
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "leadCreatedDate",
      label: "Lead Created Date",
      minWidth: 120,
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
      filter: true,
      filterType: "datepicker",
      sorting: true,
      datePickerMode: DatePickerMode.MonthYear,
      datePickerLimit: { minDate: dayjs("2014-01-01") },
    },
    {
      id: "leadResponsiblePerson",
      label: "C4C Owner",
      minWidth: 140,
      align: "left",
      format: (value: number) => value.toFixed(2),
      filter: true,
      sorting: true,
    },
  ];

  useEffect(() => {
    loadAirNetAuditData();
  }, []);

  useEffect(() => {
    if (getAirNetData == true) {
      loadAirNetAuditData();
    }
    return () => {
      setGetAirNetData(false);
    };
  }, [loadAirNetAuditData, getAirNetData]);

  return (
    <CustomTable
      isInsideTab={true}
      data={airNetAuditData}
      actionColumnNotNeeded={true}
      generatedLeadsPage={true}
      filterChange={handleAirNetFilterChange}
      columns={airNetAuditColumns}
      setPage={setAirNetAuditPage}
      clearAll={clearAllAirAuditSorting}
      page={airNetAuditPage}
      loadDataFunction={loadAirNetAuditData}
      totalRecords={airNetAuditTotalRecords}
      filterObject={airNetAuditfilterObject}
      sortColumn={airNetAuditSortingColumn}
      setSortColumn={setAirNetAuditSortingColumn}
      sortColumnOrder={airNetAuditSortingOrder}
      setSortColumnOrder={setAirNetAuditSortingOrder}
      setGetData={setGetAirNetData}
      pageSpecificData={pageSpecificData}
    />
  );
}
