// // import React from "react";
import Header from "./header/Header";
import Navigation from "./navigation/Navigation";
import * as React from "react";
import Footer from "./footer/Footer";
import classes from "./Layout.module.css";
import { Outlet } from "react-router-dom";
import Loader from "../CustomComponents/Loader";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/hooks";
import { hideServerError } from "../../store/fetchingLoaderDataSlice";
import { RootState } from "../../store";
import CustomAlert from "../CustomComponents/CustomAlert";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { RoutingConstants } from "../../RoutingConstants";
import ReleaseNoteNotification from "../CustomComponents/ReleaseNoteNotification";
import { Role } from "../../models/roles";
import { RequestApi } from "../../Constant";
import { useRequest } from "../../../services/agent";
import { setData, openReleaseNote } from "../../store/releaseNoteNotification";

const Layout = () => {
  const location = useLocation();
  const request = useRequest();
    const show = useSelector((state: RootState) => state.fetchingLoaderReducer.showLoader);
    const serverError = useSelector((state: RootState) => state.fetchingLoaderReducer.serverError);
  const dispatch = useAppDispatch();
  const serverErrorMessage = "Server is down. Please try again after sometime.";

  const handleServerError = () => {
    setTimeout(() => dispatch(hideServerError()), 3000);
  };

  useEffect(() => {
    const pathNeedSessionStorage = [
      "/" + RoutingConstants.NewProject + "/" + RoutingConstants.LinkProjectUpdate,
      "/" + RoutingConstants.NewProject + "/" + RoutingConstants.UpdateFinishedReport,
    ];
    const isPathMatching = pathNeedSessionStorage.some((path) =>
      new RegExp(`^${path}/\\d+$`).test(location.pathname)
    );
    if (
      location.pathname !==
        "/" + RoutingConstants.NewProject + "/" + RoutingConstants.UploadFinishedReport &&
      location.pathname !==
        "/" + RoutingConstants.NewProject + "/" + RoutingConstants.LinkProjectUpload &&
      location.pathname !==
        "/" + RoutingConstants.NewProject + "/" + RoutingConstants.AddProjectData &&
      !isPathMatching
    ) {
      window.sessionStorage.removeItem("cn");
      window.sessionStorage.removeItem("ci");
      window.sessionStorage.removeItem("ct");
      window.sessionStorage.removeItem("con");
      window.sessionStorage.removeItem("st");
      window.sessionStorage.removeItem("ad");
      window.sessionStorage.removeItem("lid");
      window.sessionStorage.removeItem("oid");
      window.sessionStorage.removeItem("tqv");
      window.sessionStorage.removeItem("tev");
    }
  }, [location]);

  useEffect(() => {
    const allowedPathApiCall = [
      "",
      RoutingConstants.NewProject,
      RoutingConstants.OngoingProject,
      RoutingConstants.FinishedReports,
      RoutingConstants.GeneratedOpportunities,
      RoutingConstants.GeneratedLeads,
      RoutingConstants.UserManagement,
    ];
    if (
      allowedPathApiCall.includes(location.pathname.replace(/^\/|\/$/g, ""))
    ) {
      const decodedData = atob(sessionStorage.getItem("ud") || "");
      const data = decodedData ? JSON.parse(decodedData) : "";
      if (data?.role?.value !== Role.FieldServiceEngineer) {
        request({
          url: RequestApi.Get_Latest_Release_Note,
          method: "get",
        })
          .then((resp) => {
            if (resp?.data?.points?.length) {
              dispatch(setData(resp?.data?.points));
              dispatch(openReleaseNote());
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [location]);

  return (
    <div className={classes.app}>
      {show && <Loader />}
      {<ReleaseNoteNotification />}
      <CustomAlert
        severity={"error"}
        open={serverError}
        message={serverErrorMessage}
        onClose={handleServerError}
      />
      <Header></Header>
      <div className={classes.container}>
        <Navigation />
        <div className={classes["main-container"]}>
          <main style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Outlet />
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
