import React, { KeyboardEvent } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Grid,
  FormControl,
  FormLabel,
  NativeSelect,
  TextField,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";

import InputBase from "@mui/material/InputBase";
import DropdownModel from "../../interface/DropdownModel";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },

  "& .MuiInputBase-input": {
    borderRadius: 4,

    position: "relative",

    borderTopRightRadius: "unset",
    borderBottomRightRadius: "unset",

    backgroundColor: theme.palette.background.paper,

    border: "1px solid #ced4da",

    fontSize: 14,

    padding: "5px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}));

interface CustomInputSelectProps {
  label: string;
  options: string[] | DropdownModel[];
  md: number;
  name: string;
  numberOnly?: true | false;
  isRequired: true | false;
  handleInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  unitName?: string;
}

const CustomInputSelect: React.FC<CustomInputSelectProps> = ({
  label,
  options,
  md,
  name,
  handleInputChange,
  numberOnly,
  isRequired,
  unitName,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid
      item
      xs={12}
      md={md}
      xl={md}
      direction="row"
    >
      <FormLabel className="labels">
        {label}
        {isRequired && (
          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
        )}
      </FormLabel>
      <FormControl
        variant="standard"
        style={{ width: "64%" }}
      >
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <TextField
              {...props}
              {...field}
              error={!!errors[name]}
              autoComplete="off"
              onChange={handleInputChange}
              onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                if (numberOnly) {
                  const isNumeric = /^[0-9]*$/.test(event.key);
                  //const isNumeric = /^[1-9]\d*(\.\d+)?$/.test(event.key);
                  if (
                    (!isNumeric &&
                      event.key !== "Tab" &&
                      event.key !== "." &&
                      event.key !== "Backspace") ||
                    (event.key === "." &&
                      (event.target as HTMLInputElement).value.includes("."))
                  ) {
                    event.preventDefault();
                  }
                } else {
                  return;
                }
              }}
            />
          )}
        />
      </FormControl>

      <FormControl
        variant="filled"
        style={{ width: "36%" }}
      >
        <Controller
          name={unitName ? unitName : `${name}Select`}
          control={control}
          render={({ field }) => (
            <NativeSelect
              id="demo-customized-select-native"
              value={field.value}
              onChange={(e: any) => {
                field.onChange(e);
                console.log(e.target.value);
                // setValue(e.target.value);
              }}
              input={<BootstrapInput />}
              sx={{
                "& .MuiNativeSelect-select": {
                  borderTopLeftRadius: "unset",
                  borderBottomLeftRadius: "unset",
                  borderBottomRightRadius: "4px",
                  borderTopRightRadius: "4px",
                },
              }}
            >
              {typeof options[0] === "string"
                ? (options as string[])?.map((option: string) => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))
                : (options as DropdownModel[])?.map((option: DropdownModel) => (
                    <option
                      key={option.valueInt}
                      value={option.valueInt}
                    >
                      {option.text}
                    </option>
                  ))}
            </NativeSelect>
          )}
        />
      </FormControl>
      <Typography
        variant="inherit"
        align="left"
        color="red"
        fontSize={"12px"}
      >
        {errors[name] && errors[name]?.type === "optionality"
          ? (errors[name]?.message as string)
          : undefined}
        {errors[name] && errors[name]?.type === "min"
          ? (errors[name]?.message as string)
          : undefined}
        {errors[name] && errors[name]?.type === "max"
          ? (errors[name]?.message as string)
          : undefined}
        {errors[name] && errors[name]?.type === "required"
          ? (errors[name]?.message as string)
          : undefined}
        {errors[name] && errors[name]?.type === "typeError"
          ? "Only numbers are allowed"
          : undefined}
        {errors[name] && errors[name]?.type === "matches"
          ? (errors[name]?.message as string)
          : undefined}
        {errors[name] && errors[name]?.type === "server"
          ? (errors[name]?.message as string)
          : undefined}
        {errors[name] && errors[name]?.type === "length"
          ? (errors[name]?.message as string)
          : undefined}
        {errors[name] && errors[name]?.type === "nullable"
          ? (errors[name]?.message as string)
          : undefined}
      </Typography>
    </Grid>
  );
};

export default CustomInputSelect;
