import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { GlobalVariable, RequestApi } from '../common/Constant';
import { useAppDispatch } from '../common/hooks/hooks';
import {
  hideFetchingLoader,
  showFetchingLoader,
  showServerError,
} from '../common/store/fetchingLoaderDataSlice';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { RoutingConstants } from '../common/RoutingConstants';

const request = axios.create({
  baseURL: GlobalVariable.BASE_API_URL, // Replace with your API base URL
});

export const useRequest = () => {
  const dispatch = useAppDispatch();
  var numberOfAjaxCAllPending = 0;
    const msal = useMsal();
  const tokenADRequest = {
    loginHint: msal.instance?.getAllAccounts()[0]?.username,
    scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/Read_Write`],
  };
  const tokenRequest = {
    loginHint: msal.instance.getAllAccounts()[0]?.username,
    scopes: ['User.Read'],
  };


  const setProfileToken = async () => {
    const token = (await msal.instance.acquireTokenSilent(tokenRequest))
      .accessToken;
    sessionStorage.setItem('accessTokenP', token);
  };

  const getADToken = async () => {
    const token = (await msal.instance.acquireTokenSilent(tokenADRequest))
      .accessToken;
    sessionStorage.setItem('accessTokenAD', token);
  };

  const getToken = () => {
    return sessionStorage.getItem('accessTokenAD');
  };

  const getProfileToken = () => {
    return sessionStorage.getItem('accessTokenP');
  };


  const isProfileTokenExpired = () =>{
    const decoded = jwtDecode(getProfileToken() || '');
    return decoded.exp && decoded.exp < Date.now();
  }




  const isTokenExpired = () => {
    const decoded = jwtDecode(getToken() || '');
    return decoded.exp && decoded.exp > Date.now();
  };

  useEffect(() => {
    const requestInterceptor = request.interceptors.request.use(
      async (request) => {
        if (getToken()) {
          request.headers['Authorization'] = `Bearer ${getToken()}`;
        }
        if (!getProfileToken() || isProfileTokenExpired()) {
          await setProfileToken();
        }
        numberOfAjaxCAllPending++;
        dispatch(showFetchingLoader());
        return request;
      },
      (error) => {
        numberOfAjaxCAllPending--;
        if (numberOfAjaxCAllPending === 0) {
          setTimeout(() => dispatch(hideFetchingLoader()), 1000);
        }
        return Promise.reject(error);
      }
    );
    const responseInterceptor = request.interceptors.response.use(
      function (response) {
        numberOfAjaxCAllPending--;
        if (numberOfAjaxCAllPending === 0) {
          setTimeout(() => dispatch(hideFetchingLoader()), 1000);
            }
        if (response.status === 204) {
          const data = { data: [], totalRecord: 0 };
          response.data = data;
            }
            if (response.status === 401) {
                window.location.href = '/' + RoutingConstants.UnAuthorize;
            }
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        numberOfAjaxCAllPending--;
          setTimeout(() => dispatch(hideFetchingLoader()), 1000);
        if (error.response.status === 503) {
          dispatch(showServerError());
          }
        
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
            if (!getToken() || !isTokenExpired()) {
                await getADToken();
            }
            else {
                window.location.href = '/' + RoutingConstants.UnAuthorize;
            }
          return request(originalRequest);
          }
        else if (error.response.status === 401) {
            window.location.href = '/' + RoutingConstants.UnAuthorize;
        }
        return Promise.reject(error);
      }
    );
    return () => {
      request.interceptors.request.eject(requestInterceptor);
      request.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return request;
};
