import * as Yup from "yup";
import {
  Box,
  Button,
  Dialog,
  Grid,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import ContriesAutoComplete from "../common/components/CustomComponents/CountriesAutoComplete";
import CustomTable from "../common/components/CustomComponents/CustomTable";
import DropdownModel from "../common/interface/DropdownModel";
import {
  DatePickerMode,
  TableActionButtons,
} from "../common/interface/TableColumn";
import { useNavigate } from "react-router";
import search_icon from "../assets/icons/search_icon.svg";
import classes from "./AddCustomer.module.css";
import { useCountries } from "../services/countries";
import { RequestApi } from "../common/Constant";
import { useRequest } from "../services/agent";
import { PageConstants } from "../common/PageConstants";
import CloseIcon from "../assets/icons/close.svg";
import addCustomerIcon from "../assets/icons/add_Customer.svg";
import CustomAlert from "../common/components/CustomComponents/CustomAlert";
import { IAirAuditData } from "./OngoingProject";
export interface CustomerSearch {
  customerName?: string;
  city?: string;
  country: string;
  externalId?: number;
  c4CId?: number;
}
export interface TableColumn {
  id: string | number;
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
  filter: true | false;
  filterType?: "text" | "dropdown" | "datepicker";
  dropdownData?: DropdownModel[];
  sortOrder?: any;
  sorting?: true | false;
  datePickerMode?: DatePickerMode;
}
interface CustomerData {
  c4CId: string;
  customerName: string;
  country: string;
  city: string;
  address: string;
  action: string;
  soldTo: string;
}
interface AddCustomerProps {
  open: boolean;
  ongoingProjectId: number;
  onClose: () => any;
  data: IAirAuditData;
}
export type tableColumns =
  | "opportunityId"
  | "accountId"
  | "customerName"
  | "status"
  | "country"
  | "city"
  | "address"
  | "externalId"
  | "action";
function AddCustomer(props: AddCustomerProps) {
  const { open, ongoingProjectId, onClose, data } = props;
  const [getData, setData] = useState(true);
  const navigation = useNavigate();
  const [page, setPage] = useState(0);
  const [notInitialRender, setNotInitialRender] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRcords, setTotalRcords] = useState(0);
  const countries = useCountries();
  const [filterC4cId, setFilterC4cId] = useState<string>("");
  const request = useRequest();
  const [customerList, setCustomerList] = useState<Array<CustomerData>>([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  // const validationSchema = Yup.object().shape({
  //   country: Yup.string().required("This field is required"),
  // });

  const methods = useForm<CustomerSearch>({
    // resolver: yupResolver(validationSchema),
    mode: "all",
    reValidateMode: "onBlur",
  });

  const formatStringWithSpaces = (input: string): string => {
    return input.replace(/,\s*/g, ", ").replace(/,\s*$/, "");
  };

  const customerSearchfields = [
    {
      name: "customerName",
      label: "Name",
    },
    {
      name: "city",
      label: "City",
    },
    {
      name: "country",
      label: "Country",
    },
    {
      name: "c4CId",
      label: "Account ID",
    },
    {
      name: "externalId",
      label: "External Id (Sold To)",
    },
  ];

  const columns: TableColumn[] = [
    {
      id: "c4CId" as tableColumns,
      label: "Account ID",
      align: "left",
      minWidth: 70,
      filter: false,
      sorting: false,
    },
    {
      id: "customerName" as tableColumns,
      label: " Customer Name",
      align: "left",
      minWidth: 150,
      filter: false,
      sorting: false,
    },
    {
      id: "country" as tableColumns,
      label: " Country",
      align: "left",
      minWidth: 70,
      filter: false,
      sorting: false,
    },
    {
      id: "city" as tableColumns,
      label: " City",
      align: "left",
      minWidth: 150,
      filter: false,
      sorting: false,
    },
    {
      id: "address" as tableColumns,
      label: " Address",
      minWidth: 250,
      align: "left",
      filter: false,
      sorting: false,
    },
    {
      id: "soldTo" as tableColumns,
      label: " External ID (Sold To)",
      align: "left",
      minWidth: 80,
      filter: false,
      sorting: false,
    },
  ];
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
  }
  function handleAddCustomerClick(
    auditIdVal: number,
    auditNumber: string,
    event: any,
    row: any
  ) {
    let payload = {
      c4CId: row.c4CId,
      customerName: row.customerName,
      country: row.country,
      city: row.city,
      address: row.address,
      action: "",
      soldTo: row.soldTo,
      ongoingProjectId: ongoingProjectId,
    };

    request({
      url: RequestApi.ADD_CUSTOMER,
      method: "post",
      data: payload,
    })
      .then((resp) => {
        setAlert("success", "Customer Added Successfully", true);
        methods.reset();
        close();
      })
      .catch((err) => {
        setAlert("error", "Request Failed", true);
      });
  }
  const close = () => {
    onClose();
    methods.reset();
    setCustomerList([]);
    setTotalRcords(0);
    setNotInitialRender(false);
  };

  function loadData() {
    setNotInitialRender(true);

    methods.trigger();
    let body = {
      page: page,
      pageSize: rowsPerPage,
      c4CId: methods.getValues("c4CId") ? methods.getValues("c4CId") : "",
      customerName: methods.getValues("customerName")
        ? methods.getValues("customerName")?.toUpperCase()
        : "",
      country: methods.getValues("country") ? methods.getValues("country") : "",
      city: methods.getValues("city")
        ? methods.getValues("city")?.toUpperCase()
        : "",
      soldTo: methods.getValues("externalId")
        ? methods.getValues("externalId")
        : "",
    };

    request({
      url: RequestApi.GET_UPLOAD_FINISHED_REPORT_LIST,
      method: "post",
      data: body,
    })
      .then((resp) => {
        let customerData = resp.data.data.map((customer: CustomerData) => ({
          ...customer,
          country: countries.getCountryName(customer.country),
        }));
        setTotalRcords(resp.data.totalRecord);
        setCustomerList(customerData);
      })
      .catch((err) => {
        setAlert("error", "Request Failed", true);
      });
  }
  function loadSearchData() {
    // page === 0 ? loadData() : !notInitialRender ? loadData() : setPage(0);
    setPage(0);
    loadData();
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const actionButtons: TableActionButtons[] = [
    {
      name: "",
      handleClick: handleAddCustomerClick,
      permission: true,
      icon: addCustomerIcon,
      text: PageConstants.AddCustomer,
      textInsideButton: <span style={{ marginLeft: "5px" }}>Add Customer</span>,
    },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (open) {
      loadData();
    }
  }, [page]);

  useEffect(() => {
    if (open) {
      methods.setValue(
        "country",
        countries.countriesData.filter(
          (country) => country?.countryName === data?.country
        )[0].countryName
      );
    }
  }, [open, data]);
  return (
    <>
      <CustomAlert
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
      />
      <Dialog
        sx={{
          right: "0",
          "&.MuiModal-root>.MuiDialog-container>.MuiPaper-root": {
            marginTop: "0",
            marginInline: "0",
            width: "1300px",
            maxHeight: "unset",
            maxWidth: "unset",
            height: "calc(98% - 32px)",
            borderRadius: "1.25rem",
            overflow: "auto",
          },
          "&.MuiModal-root>.MuiDialog-container": {
            justifyContent: "center",
          },
        }}
        open={open}
      >
        <Box
          sx={{
            boxShadow: 0,
            borderBlock: 0,
            "&.MuiBox-root": {
              border: "none",
              backgroundColor: "#fff",
              paddingLeft: "21px",
              paddingRight: "29.27px",
              borderRadius: "8px",
              paddingTop: "1rem",
            },
          }}
        >
          <Grid
            item
            md={12}
            sx={{ marginBottom: "13px", position: "relative" }}
            className={classes["page-title"]}
          >
            Customer Information
            <div className={classes["title-container-right"]}>
              <img
                style={{ cursor: "pointer", float: "right" }}
                onClick={close}
                src={CloseIcon}
                height="35px"
                alt="Back"
                loading="lazy"
                decoding="async"
              />
            </div>
          </Grid>
          <Grid
            container
            style={{
              marginBottom: "13px",
            }}
          >
            <Grid
              md={2}
              style={{ borderRight: "1px solid #9F9F9F" }}
            >
              <p style={{ fontWeight: 600, color: "#000000" }}>Customer Name</p>
              <Tooltip title={data?.customerName ? data.customerName : "N/A"}>
                <p
                  style={{ marginTop: "16px" }}
                  className={classes["wrap-data"]}
                >
                  {data?.customerName ? data.customerName : "N/A"}
                </p>
              </Tooltip>
            </Grid>
            <Grid md={0.28}></Grid>
            <Grid
              md={1.2}
              style={{ borderRight: "1px solid #9F9F9F" }}
            >
              <p style={{ fontWeight: 600, color: "#000000" }}>City</p>
              <Tooltip
                title={data?.city ? formatStringWithSpaces(data.city) : "N/A"}
              >
                <p
                  style={{ marginTop: "16px" }}
                  className={classes["wrap-data"]}
                >
                  {data?.city ? formatStringWithSpaces(data.city) : "N/A"}
                </p>
              </Tooltip>
            </Grid>
            <Grid md={0.28}></Grid>
            <Grid
              md={1.2}
              style={{ borderRight: "1px solid #9F9F9F" }}
            >
              <p style={{ fontWeight: 600, color: "#000000" }}>Country</p>
              <Tooltip title={data?.country ? data.country : "N/A"}>
                <p
                  style={{ marginTop: "16px" }}
                  className={classes["wrap-data"]}
                >
                  {data?.country ? data.country : "N/A"}
                </p>
              </Tooltip>
            </Grid>
            <Grid md={0.28}></Grid>
            <Grid md={1.2}>
              <p style={{ fontWeight: 600, color: "#000000" }}>FAM Code</p>
              <Tooltip title={data?.famCode ? data.famCode : "N/A"}>
                <p
                  style={{ marginTop: "16px" }}
                  className={classes["wrap-data"]}
                >
                  {data?.famCode ? data.famCode : "N/A"}
                </p>
              </Tooltip>
            </Grid>
            <Grid
              item
              md={5}
            >
              <Typography
                fontSize={".875rem"}
                color={"#BC2026"}
                lineHeight={"26px"}
                fontStyle={"italic"}
              >
                Note: The customer information provided by the FSE is for
                reference only. Please make sure to search for and select the
                correct customer to add to the project.
              </Typography>
            </Grid>
          </Grid>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(loadSearchData)}>
              <Grid
                container
                spacing={"10px"}
              >
                {customerSearchfields.map(({ name, label }, index) => {
                  // if (name === "country") {
                  //   return (
                  //     <ContriesAutoComplete
                  //       setValue={methods.setValue}
                  //       data={countries.countriesData}
                  //     />
                  //   );
                  // } else {
                  return (
                    <CustomInput
                      isRequired={false}
                      name={name}
                      label={label}
                      variant="outlined"
                      fullWidth
                      disabled={name === "country"}
                    />
                  );
                })}

                <Grid
                  item
                  alignSelf="flex-end"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={
                      <img
                        src={search_icon}
                        alt="search-icon"
                        style={{
                          width: "16px",
                          height: "16px",
                        }}
                        loading="lazy"
                        decoding="async"
                      />
                    }
                    sx={{
                      padding: "10px 12px !important",
                      color: "#FFF",
                      fontSize: 18,
                      fontWeight: 400,
                      height: "35px !important",
                      lineHeight: "20px",
                      textTransform: "none",
                      boxShadow: "none !important",
                      gap: "6px !important",
                    }}
                    disableRipple
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
        <Box
          sx={{
            boxShadow: 0,
            borderTop: 0,
            background: "#F9F9F9",
            border: "none",
            marginTop: "15px",
            marginInline: "1.4rem",
          }}
        >
          <TablePagination
            sx={{
              "& .MuiIconButton-root": {
                backgroundColor: "#f0f0f0",
                borderRadius: "5px  !important",
                padding: "2px  !important",
                margin: "2px  !important",
                height: "24px",
                width: "24px",
              },
              "& .MuiIconButton-root .Mui-disabled": {
                backgroundColor: "transparent",
              },
              "& .MuiTablePagination-toolbar": {
                minHeight: "30px",
                paddingRight: "10px",
              },
            }}
            component="div"
            count={totalRcords}
            rowsPerPage={rowsPerPage}
            showFirstButton
            showLastButton
            onRowsPerPageChange={() => {}}
            rowsPerPageOptions={[]}
            page={page}
            onPageChange={handleChangePage}
          />
        </Box>
        <Box
          sx={{ border: "none", paddingInline: "1.4rem", boxShadow: "unset" }}
        >
          <CustomTable
            data={customerList}
            isInsideTab={true}
            columns={columns}
            actionColumnWidth={"300px"}
            setGetData={setData}
            showPagination={false}
            stickyActionColumn={false}
            showFilters={false}
            actionButtons={actionButtons}
            pageName={PageConstants.AddCustomer}
            fromAddCustomerModal
            notInitialRender={notInitialRender}
          />
        </Box>
      </Dialog>
    </>
  );
}

export default AddCustomer;
