import {
  Box,
  Tabs,
  Tab,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
  SvgIcon,
} from "@mui/material";
import VectorIcon from "./../assets/icons/Vector.svg";
import Exclamation from "../assets/icons/Red_Exclaimation_mark.svg";
import UploadDataIcon from "./../assets/images/Upload_icon.svg";
import classes from "./UploadFinishedReports.module.css";
import { ReactComponent as UploadReportIcon } from "./../assets/icons/upload_report.svg";
import { useEffect, useMemo, useRef, useState, KeyboardEvent } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useLoaderData, useNavigate } from "react-router-dom";
import DropdownModel from "../common/interface/DropdownModel";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import CustomInputSelect from "../common/components/CustomComponents/CustomInputSelect";
import CustomMultiSelectNew from "../common/components/CustomComponents/CustomMultiSelectNew";
import CustomSelect from "../common/components/CustomComponents/CustomSelect";
import CustomUpload from "../common/components/CustomComponents/CustomUpload";
import { DeleteSuccessDailog, IAuditType } from "./UpdateOngoingProject";
import {
  AuditAirScanTypes,
  ShowAuditTypesOnTabConstants,
} from "../common/AuditTypeConstants";
import State from "../common/interface/DragDrop";
import { CustomConfirmationDialogProps } from "./LinkProjectUpload";
import { showConfirmation } from "../common/store/confirmationBoxDataSlice";
import { useAppDispatch } from "../common/hooks/hooks";
import { RoutingConstants } from "../common/RoutingConstants";
import { hideLoader, showLoader } from "../common/store/loaderDataSlice";
import CustomAlertTest from "../common/components/CustomComponents/CustomAlert";
import { ProjectSourceConstants } from "../common/ProjectSourceConstants";
import { RequestApi } from "../common/Constant";
import { useRequest } from "../services/agent";
import BackArrowIcon from "./../assets/icons/back_arrow-icon.svg";
import { useMsal } from "@azure/msal-react";

export default function AddProjectData() {
  const selectList = useLoaderData() as any;
  const request = useRequest();
  const dispatchCancelPopup = useAppDispatch();
  const msal = useMsal();
  const initiator =
    msal.accounts[0].name === undefined ? "" : msal.accounts[0].name;
  const multiselectRef = useRef<any | undefined>();
  const [value, setValue] = useState(0);
  const [savedFiles, setSavedFiles] = useState([]);
  const [isAirQualityOnly, setIsAirQualityOnly] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState<any[]>([]);
  const [typeOfMeasurementsList, setTypeOfMeasurements] = useState<
    DropdownModel[]
  >([]);
  const navigate = useNavigate();
  const [deletedFileIds, setDeletedFileIds] = useState<number[]>([]);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const customerData = {
    customerName: sessionStorage.getItem("cn") || "",
    c4CId: sessionStorage.getItem("ci") || "",
    country: sessionStorage.getItem("con") || "",
    address: sessionStorage.getItem("ad") || "",
    soldTo: sessionStorage.getItem("st") || "",
    city: sessionStorage.getItem("ct") || "",
  };

  type CustomerDataKeys = keyof typeof customerData;

  const validationSchema = Yup.object().shape({
    typesOfMeasurements: Yup.array().min(1, "This field is required"),
    auditName: Yup.string()
      .required("This field is required.")
      .max(80, "Maximum 80 Characters are allowed")
      .min(2, "Must include minumum 2 characters")
      .matches(/^[a-z\d\-_\s]+$/i, "Special Characters are not allowed"),
    measurementsWithQuantity: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        id: Yup.number(),
        typeOfMeasurementId: Yup.number(),
        qty: Yup.string()
          .required("Quantity is required")
          .matches(/^\d+$/, "Only numbers are allowed"),
      })
    ),
    electricityCost: isAirQualityOnly
      ? Yup.string().nullable()
      : Yup.number()
          .transform((value, originalValue) => {
            return originalValue === "" ? undefined : value;
          })
          .min(0, "The value must be 0 or more.")
          .required("This field is required."),
    categoryOfProject: Yup.number().required("This field is required"),
    subCategory: Yup.array().min(1, "This field is required"),
    co2Factor: isAirQualityOnly
      ? Yup.string().nullable()
      : Yup.string().required("This field is required"),
    uploadedFiles: Yup.array().test(
      "file-required",
      "This field is required",
      function (value) {
        return savedFiles.length > 0 || (value && value.length > 0);
      }
    ),
    projectDescription: Yup.string()
      .max(500, "Maximum 500 Characters are allowed")
      .nullable(),
    famCodeId: Yup.number()
      .min(1, "This field is required")
      .required("This field is required"),
    co2Unit: isAirQualityOnly
      ? Yup.number().nullable()
      : Yup.number()
          .min(1, "This field is required")
          .required("This field is required"),
    energyUnitId: Yup.number().notRequired(),
    customerName: Yup.string().nullable(),
    city: Yup.string().nullable(),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      categoryOfProject: 1,
      typesOfMeasurements: [] as string[],
      subCategory: [] as string[],
      co2Unit: 1,
      energyUnitId: 1,
    },
  });

  const control = methods.control;

  const { fields, append, remove } = useFieldArray({
    name: "measurementsWithQuantity",
    control,
  });

  const uploadReportIcon = (
    <div className={classes["upload-icon"]}>
      <SvgIcon component={UploadReportIcon}></SvgIcon>
    </div>
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
      methods.reset();
    } else if (newValue === 1) {
    }
  };

  const getCO2UnitList = useMemo(
    () => selectList?.cO2UnitList as DropdownModel[],
    [selectList]
  );

  let auditGroupTypes = useMemo(
    () =>
      selectList?.typeOfAuditList
        .filter(
          (auditType: any) =>
            auditType.auditGroup.toLowerCase() ===
            ShowAuditTypesOnTabConstants.Air_Scan.toLocaleLowerCase()
        )
        .map((auditType: any) => ({
          ...auditType,
          auditGroup: ShowAuditTypesOnTabConstants.Air_Scan,
        })) as IAuditType[],
    [selectList]
  );

  let mappedData: DropdownModel[] = auditGroupTypes?.map(
    (item: IAuditType, index) => {
      return { text: item.auditGroup, valueInt: index + 1, value: "" };
    }
  );

  let selectedTypesOfMeasurements = methods.watch(
    "typesOfMeasurements"
  ) as string[];
  let comments = methods.watch("projectDescription") as string;

  const subCategories = auditGroupTypes
    ?.filter(
      (auditType) =>
        auditType.auditGroup.toLowerCase() ===
        ShowAuditTypesOnTabConstants.Air_Scan.toLocaleLowerCase()
    )[0]
    .subGroups.map((subGroup) => ({
      cat: subGroup.valueInt,
      key: subGroup.text,
    }));

  const famCodeOfSelectedCountries = useMemo(() => {
    const famcodes = selectList?.famCodeList;

    if (famcodes?.length === 1) {
      methods.setValue("famCodeId", famcodes[0]?.valueInt);
    }
    return famcodes;
  }, [selectList]);

  const currencyCode = useMemo(() => selectList?.currencyList[0], [selectList]);

  const customInputSelections = [
    {
      function: (data: any) =>
        methods.setValue("electricityCost", data.target.value),
      label: "Electricity Cost",
      option: [currencyCode?.text + "/kWh"],
      name: "electricityCost",
      numberOnly: true,
      unitName: "energyUnitId",
      hide: isAirQualityOnly,
    },
    {
      function: (data: any) => methods.setValue("co2Factor", data.target.value),
      label: "CO2 Factor",
      option: getCO2UnitList,
      name: "co2Factor",
      numberOnly: true,
      unitName: "co2Unit",
      hide: isAirQualityOnly,
    },
  ];
  const customerFields = [
    {
      name: "Account ID",
      data: "c4CId",
    },
    {
      name: "Customer Name",
      data: "customerName",
    },
    {
      name: "Country",
      data: "country",
    },
    {
      name: "Address",
      data: "address",
    },
  ];

  useEffect(() => {
    const newVal = selectedTypesOfMeasurements.length;
    if (newVal === 0) {
      remove();
    } else {
      let oldMeasurementIds: string[] = [];
      fields.filter((filter, i) => {
        if (filter.name === undefined) {
          remove(i);
        } else if (
          selectedTypesOfMeasurements.includes(
            String(filter.typeOfMeasurementId)
          )
        ) {
          oldMeasurementIds.push(String(filter.typeOfMeasurementId));
        } else {
          remove(i);
          let lst = methods.getValues("measurementsWithQuantity");
          let index = lst?.findIndex((itm) => {
            return itm.typeOfMeasurementId === filter.typeOfMeasurementId;
          });

          if (index !== undefined && index !== -1) {
            methods.setValue("measurementsWithQuantity", lst?.splice(index, 1));
          }
        }
      });

      let newMeasurementIds = selectedTypesOfMeasurements.filter(
        (x) => !oldMeasurementIds.includes(x)
      );

      if (newMeasurementIds.length !== 0) {
        for (let i = 0; i < newMeasurementIds.length; i++) {
          const name = getTypeOfMeasurementSelectedText(
            newMeasurementIds[i].toString()
          );
          if (name.length > 0) {
            append({
              name,
              qty: "1",
              typeOfMeasurementId: +newMeasurementIds[i],
            });
          }
        }
      }
    }
  }, [selectedTypesOfMeasurements]);

  const filterTypesOfMeasurements = (typeOfAudits: any[]) => {
    var typeOfMeasurements = selectList?.typeOfMeasurementsList?.filter(
      (itm: any) => {
        return typeOfAudits.some((e) => {
          return itm.auditTypeId === e;
        });
      }
    );

    let selectMeasurementList: DropdownModel[] = typeOfMeasurements?.map(
      (item: any) => {
        return { text: item.name, valueInt: item.id };
      }
    );
    setTypeOfMeasurements(selectMeasurementList);

    const availableSelectedMeasurements = methods.getValues(
      "typesOfMeasurements"
    );
    const measurementsForSelectedAudits = availableSelectedMeasurements?.filter(
      (value: any) => {
        const isAvailable =
          selectMeasurementList.filter(
            (measurement) =>
              measurement.valueInt.toString() === value.toString()
          ).length === 1;
        if (isAvailable) return value;
      }
    );
    methods.setValue("typesOfMeasurements", measurementsForSelectedAudits);
  };

  function handleMultiSelectChange(subCategoryList: any, addSubCategory: any) {
    let result: any[] = [];
    if (addSubCategory.cat === AuditAirScanTypes.Energy_Potential_PreSales) {
      if (
        selectedSubCategory?.length > 0 &&
        selectedSubCategory?.length > subCategoryList?.length
      ) {
        setSelectedSubCategory([]);
      } else {
        result = [addSubCategory.cat];
        setSelectedSubCategory([addSubCategory]);
        setIsAirQualityOnly(false);
      }
    } else {
      if (
        subCategoryList[0]?.cat === AuditAirScanTypes.Energy_Potential_PreSales
      ) {
        result = [addSubCategory.cat];
        addSubCategory.cat === AuditAirScanTypes.Air_Quality
          ? setIsAirQualityOnly(true)
          : setIsAirQualityOnly(false);
        setSelectedSubCategory([addSubCategory]);
      } else {
        subCategoryList.length === 1 &&
        subCategoryList[0]?.cat === AuditAirScanTypes.Air_Quality
          ? setIsAirQualityOnly(true)
          : setIsAirQualityOnly(false);
        result = subCategoryList.map((item: any) => item.cat);
        setSelectedSubCategory([...subCategoryList]);
      }
    }
    methods.setValue("subCategory", result);
    filterTypesOfMeasurements(result);
    methods.trigger("subCategory");
  }

  const getTypeOfMeasurementSelectedText = (id: string): string => {
    let selectedText = typeOfMeasurementsList?.find((e) => e.valueInt === +id);
    if (selectedText) {
      return selectedText.text;
    } else {
      return "";
    }
  };

  const handleTypeOfMeasurementChange = (
    event: SelectChangeEvent<typeof selectedTypesOfMeasurements>
  ) => {
    const {
      target: { value },
    } = event;
    let a = typeof value === "string" ? value.split(",") : value;
    var index = a?.indexOf("0");
    if (index && index >= 0) {
      a?.splice(index, 1);
    }
    methods.setValue("typesOfMeasurements", a);
    methods.trigger("typesOfMeasurements");
  };

  const onSubmit = (data: any) => {
    const obj = {
      id: 0,
      typeOfAuditId: data?.subCategory,
      auditName: data.auditName,
      electricityCost: data.electricityCost,
      currencyId: currencyCode?.valueInt,
      energyUnitId: data.energyUnitId,
      cO2Factor: data.co2Factor,
      cO2UnitId: data.co2Unit,
      typeOfMeasurementIds: data.typesOfMeasurements,
      reports: data.uploadedFiles,
      typeOfMeasurementDetails: data.measurementsWithQuantity,
      DeletedReportIds: deletedFileIds,
      Customer: customerData,
      c4CId: customerData.c4CId,
      famCodeId: data.famCodeId,
      famCode: famCodeOfSelectedCountries
        .filter((item: any) => item.valueInt == data.famCodeId)[0]
        ?.text.split(" ")[0],
      projectSource: ProjectSourceConstants.OngoingProject,
      projectDescription: methods.getValues("projectDescription"),
    };

    const formData = new FormData();
    for (let i = 0; i < data.uploadedFiles.length; i++) {
      formData.append("reports", data.uploadedFiles[i]);
    }

    request({
      url: RequestApi.ADD_NON_FSE_AUDIT_DATA,
      method: "post",
      data: { request: obj, Data: formData },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      maxContentLength: 200 * 1024 * 1024,
    })
      .then((resp) => {
        setMessage("Project data uploaded successfully!");
        setOpenSuccessDialog(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goBack = () => {
    navigate("/" + RoutingConstants.NewProject);
  };

  async function mapFiles(fileData: State) {
    let filecollection: File[] = [];
    methods.setValue("uploadedFiles", []);

    if (fileData.fileList && fileData.fileList?.length > 0) {
      for (let i = 0; i < fileData.fileList.length; i++) {
        const file = fileData.fileList[i];
        filecollection.push(file);
      }

      methods.setValue("uploadedFiles", filecollection);

      methods.trigger("uploadedFiles");
    }
  }

  useEffect(() => {
    methods.reset();
    setSelectedSubCategory([]);
    setSavedFiles([]);
    setDeletedFileIds([]);
    setIsAirQualityOnly(false);
    methods.setValue("projectDescription", "");
  }, []);

  function handleCommentChange(e: any) {
    methods.setValue("projectDescription", e.target.value);
  }

  function handleFileDelete(id: number) {
    setDeletedFileIds((oldDeletedFileIds) => [...oldDeletedFileIds, id]);
  }

  function onCloseCancelSave() {
    dispatchCancelPopup(hideLoader());
  }

  function onConfirmCancelSave() {
    goBack();
    onCloseCancelSave();
  }
  const handleCancelClickOpen = (event: any) => {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content:
        "If you proceed, you will lose all your entered data, unsaved changes may be lost.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: onConfirmCancelSave,
    };
    dispatchCancelPopup(showConfirmation(customConfirmationDialogProps));
    dispatchCancelPopup(showLoader());
  };

  return (
    <>
      <Box
        sx={{
          boxShadow: 0,
          padding: "1rem 1rem",
          display: "flex",
          alignItems: "center",
          height: "66px",
          border: "none",
          backgroundColor: "#fff",
          marginBottom: ".675rem",
        }}
      >
        <div
          className={classes["page-title-container"]}
          style={{ width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              src={BackArrowIcon}
              height="20"
              alt="Back"
              style={{ cursor: "pointer" }}
              loading="lazy"
              decoding="async"
              onClick={goBack}
            />
            <div className={classes["page-title"]}>Add Project Data</div>
          </div>
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          height: "calc(100vh - 190px)",
          border: "unset",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            border: "unset",
            boxShadow: "none",
            marginBottom: "1.5rem",
            padding: "1rem 1.5rem 0 1.5rem",
          }}
        >
          <Typography
            fontWeight={600}
            fontSize={"1.125rem"}
            marginBottom={".75rem"}
          >
            Customer Details
          </Typography>
          <Box
            sx={{ border: "unset", boxShadow: "none" }}
            display={"grid"}
            gridTemplateColumns={"10% 26% 14% 28% 22%"}
          >
            {customerFields.map((field, index) => {
              const value = customerData[field.data as CustomerDataKeys];
              return (
                <Box
                  sx={{
                    border: "unset",
                    boxShadow: "none",
                    borderRight: "1px solid #9F9F9F",
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"1rem"}
                  paddingRight={"1.5rem"}
                  width={"100%"}
                  paddingLeft={index === 0 ? "unset" : "1.5rem"}
                >
                  <Typography fontWeight={600}>{field.name}</Typography>
                  <Typography>{value}</Typography>
                </Box>
              );
            })}
            {initiator && (
              <Box
                sx={{ border: "unset", boxShadow: "none" }}
                display={"flex"}
                flexDirection={"column"}
                gap={"1rem"}
                width={"100%"}
                paddingLeft={"1.5rem"}
              >
                <Typography fontWeight={600}>Initiator</Typography>
                <Typography>{initiator}</Typography>
              </Box>
            )}
          </Box>
        </Box>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            style={{
              overflow: "hidden scroll",
              padding: "0.75rem 0.3rem 0 0.75rem",
            }}
          >
            <Grid
              className="grid-container"
              container
              spacing={2}
              rowSpacing={2}
            >
              <CustomSelect
                name={"categoryOfProject"}
                label={"Category of Project"}
                setValue={methods.setValue}
                isRequired
                options={mappedData}
                md={4}
              />
              <CustomMultiSelectNew
                name={"subCategory"}
                label={"Sub Category Of Project"}
                md={4}
                options={subCategories ?? []}
                icon={VectorIcon}
                multiselectRef={multiselectRef}
                handleChange={handleMultiSelectChange}
                placeholder={"Select Sub Category Of Project"}
                selectedValues={selectedSubCategory ?? []}
              />
              <CustomSelect
                name={"famCodeId"}
                label={"FAM Code"}
                setValue={methods.setValue}
                isRequired={true}
                options={famCodeOfSelectedCountries}
              />
              {
                <CustomInput
                  isRequired={true}
                  name={"auditName"}
                  numberOnly={false}
                  label={"Project Name"}
                  variant="outlined"
                  md={4}
                  fullWidth
                />
              }
              {customInputSelections?.map(
                (item) =>
                  !item.hide && (
                    <CustomInputSelect
                      label={item.label}
                      options={item.option}
                      md={4}
                      handleInputChange={item.function}
                      name={item.name}
                      unitName={item.unitName}
                      numberOnly={item.numberOnly}
                      isRequired={true}
                    />
                  )
              )}
              {isAirQualityOnly && <Grid md={8} />}
              <Grid
                item
                xs={12}
                md={12}
                xl={4}
                lg={4}
                direction="row"
              >
                <FormControl fullWidth>
                  <FormLabel className="labels">
                    Type of Measurements
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </FormLabel>
                  <Controller
                    name={"typesOfMeasurements"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        style={{ height: "33px" }}
                        {...methods.register("typesOfMeasurements")}
                        multiple
                        displayEmpty
                        value={field.value}
                        onChange={handleTypeOfMeasurementChange}
                        fullWidth
                        MenuProps={MenuProps}
                        renderValue={(selected) => {
                          if (selected?.length === 0) {
                            return (
                              <p className="grey-text">
                                Select Type of Measurements
                              </p>
                            );
                          }
                          return selected
                            ?.map((p: any) =>
                              getTypeOfMeasurementSelectedText(p)
                            )
                            .join(", ")
                            .substring(0, 50);
                        }}
                      >
                        <MenuItem
                          key="0"
                          value=""
                          disabled={true}
                        >
                          Select Type of Measurements
                        </MenuItem>
                        {typeOfMeasurementsList?.map((option) => (
                          <MenuItem
                            disableRipple
                            key={option.valueInt}
                            value={option.valueInt.toString()}
                          >
                            <Checkbox
                              checked={selectedTypesOfMeasurements?.includes(
                                option.valueInt.toString()
                              )}
                            />
                            <ListItemText primary={option.text} />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Typography
                    sx={{
                      color: "var(--primary-color)",
                      fontSize: "14px",
                      fontStyle: "italic",
                      fontWeight: "400",
                    }}
                  >
                    You can select multiple measurement
                  </Typography>
                  <Typography
                    variant="inherit"
                    align="left"
                    color="red"
                    fontSize={"12px"}
                  >
                    {methods.formState.errors["typesOfMeasurements"]
                      ? (methods.formState.errors["typesOfMeasurements"]
                          ?.message as string)
                      : undefined}
                  </Typography>
                </FormControl>
              </Grid>
              {selectedTypesOfMeasurements?.length > 0 ? (
                <Grid
                  className="containergrid"
                  xs={12}
                  sm={12}
                  md={12}
                  xl={8}
                  lg={8}
                  item
                  container
                >
                  <Box
                    border={"none"}
                    maxHeight={"400px"}
                    width={"100%"}
                    boxShadow={"none"}
                  >
                    <Grid
                      container
                      spacing={2}
                      rowSpacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <>
                        <Grid
                          xs={6}
                          sm={6}
                          md={6}
                          xl={6}
                          lg={6}
                          item
                        >
                          {fields.length > 0 &&
                            fields[0].typeOfMeasurementId && (
                              <FormLabel className="labels">
                                Measurements
                              </FormLabel>
                            )}
                        </Grid>
                        <Grid
                          xs={6}
                          sm={6}
                          md={6}
                          xl={6}
                          lg={6}
                          item
                        >
                          {fields.length > 0 &&
                            fields[0].typeOfMeasurementId && (
                              <FormLabel
                                className="labels"
                                sx={{ position: "relative", left: "-5px" }}
                              >
                                Quantity
                              </FormLabel>
                            )}
                        </Grid>
                        <Box
                          border={"none"}
                          maxHeight={"400px"}
                          height={"100%"}
                          sx={{ overflowY: "auto" }}
                          width={"100%"}
                          boxShadow={"none"}
                        >
                          <Grid
                            container
                            spacing={2}
                            rowSpacing={2}
                            paddingLeft={"24px"}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {fields.map(
                              (item, i) =>
                                item.name !== undefined && (
                                  <>
                                    <Grid
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      xl={6}
                                      lg={6}
                                      item
                                    >
                                      <TextField
                                        variant="outlined"
                                        label=""
                                        {...methods.register(
                                          `measurementsWithQuantity.${i}.name`
                                        )}
                                        name={`measurementsWithQuantity.${i}.name`}
                                        disabled
                                      ></TextField>

                                      <Typography
                                        variant="inherit"
                                        align="left"
                                        color="red"
                                        fontSize={"12px"}
                                      >
                                        {
                                          methods.formState.errors
                                            .measurementsWithQuantity?.[i]?.name
                                            ?.message
                                        }
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      xl={6}
                                      lg={6}
                                      item
                                    >
                                      <TextField
                                        variant="outlined"
                                        label=""
                                        {...methods.register(
                                          `measurementsWithQuantity.${i}.qty`
                                        )}
                                        name={`measurementsWithQuantity.${i}.qty`}
                                        onKeyDown={(
                                          event: KeyboardEvent<HTMLInputElement>
                                        ) => {
                                          const isNumeric = /^[0-9]*$/.test(
                                            event.key
                                          );
                                          if (
                                            (!isNumeric &&
                                              event.key !== "Tab" &&
                                              event.key !== "." &&
                                              event.key !== "Backspace") ||
                                            (event.key === "." &&
                                              (
                                                event.target as HTMLInputElement
                                              ).value.includes("."))
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                      ></TextField>
                                      <Typography
                                        variant="inherit"
                                        align="left"
                                        color="red"
                                        fontSize={"12px"}
                                      >
                                        {
                                          methods.formState.errors
                                            .measurementsWithQuantity?.[i]?.qty
                                            ?.message
                                        }
                                      </Typography>
                                    </Grid>
                                  </>
                                )
                            )}
                          </Grid>
                        </Box>
                      </>
                    </Grid>
                  </Box>
                </Grid>
              ) : (
                <Grid lg={8} />
              )}
              <CustomUpload
                handleFileChange={mapFiles}
                commentHandler={handleCommentChange}
                handleFileDelete={handleFileDelete}
                uploadFilesError={
                  savedFiles?.length === 0
                    ? methods.formState.errors.uploadedFiles?.message
                    : ""
                }
                commentsError={
                  comments?.length > 500
                    ? "Maximum 500 Characters are allowed"
                    : ""
                }
                filesDataVal={savedFiles}
                isFse={true}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              xl={12}
              lg={12}
              direction="row"
              justifyContent="flex-end"
              p={1}
              marginBottom={"1rem"}
            >
              <Button
                sx={{ textTransform: "none" }}
                className="cancel-button custom-buttons"
                disableRipple
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginRight: "15px",
                }}
                variant="outlined"
                onClick={handleCancelClickOpen}
              >
                Cancel
              </Button>
              <Button
                style={{ fontSize: "18px", fontWeight: "400" }}
                type="submit"
                className="custom-buttons"
                startIcon={uploadReportIcon}
                sx={{ textTransform: "none" }}
                variant="contained"
                disableRipple
                size="medium"
              >
                Add Project Data
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </Box>

      <DeleteSuccessDailog
        open={openSuccessDialog}
        onClose={() => {
          setOpenSuccessDialog(false);
          navigate("/" + RoutingConstants.OngoingProject);
        }}
        message={message}
        iconImg={UploadDataIcon}
      />
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={() => setAlertOpen(false)}
      />
    </>
  );
}
